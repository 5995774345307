/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, ChangeEvent, useRef } from "react";
import { tableOption, tagsTableHeader } from "../../seeds";
import AdminWrapper from "../../components/AdminWrapper";
import CustomTable from "../../components/CustomTable";
import { DataObject, ModalState } from "../../interface";
import Modal from "../../components/Modal";
import alertTost from "../../components/AlertToast";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Papa from "papaparse";

import {
  useAdminTagsStatus,
  useDeleteTags,
  useGetTags,
  useGetTagsType,
  usePostBulkTagsSupply,
  usePostBulkTagsUpload,
  usePostTags,
  useTagsEdit,
} from "../../hooks/api/tags";
import { AddTagsModal } from "./AddTagsModal";
import { DeleteModalChild } from "../manageUsers/deleteModalChild";
import { ViewTagsModalChild } from "./viewTags";
import { convertArrayToObjects } from "../../utils/helpers";
import WootagTableSkeleton from "../../skeletons/wooftagsListingSkelton";
import { useFormik } from "formik";
import {
  addSupplyInitialValues,
  addSupplyValidationSchema,
  addTagsInitialValues,
  addTagsValidationSchema,
} from "../../validations";
import { SupplierModal } from "./supplierModal";

interface DataItem {
  POS: string;
  "Basic Url": string;
  "Serial Number": string;
  "NFC UID": string;
}

interface ExportDataItem {
  ID: string;
  UID: string;
  Type: string;
  ACTIVE: string;
}

interface BulkTagDataItem {
  uid: string;
  active: boolean;
  type: string;
  serialNumber: string;
}

const ManageTags = () => {
  const importRef = useRef<HTMLInputElement | null>(null);
  const [countData, setCountData] = useState<any>(10);
  const [searchInput, setSearchInput] = useState<any>();
  const [ActivePage, setActivePage] = useState<number>(1);
  const [checkedRow, setcheckedRow] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loadingRow, setLoadingRow] = useState<string | null>(null);
  const [selectedId, setSelectedId] = useState<string>("");
  const [excelSheetData, setExcelSheetData] = useState<DataItem[]>([]);
  const [excelExportData, setExportSheetData] = useState<ExportDataItem[]>([]);
  const [flag, setFlag] = useState<boolean>(false);
  const [fileType, setFileType] = useState<boolean>(false);

  const { unparse } = Papa;

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: addTagsInitialValues,
    validationSchema: addTagsValidationSchema,
    onSubmit: () => {
      handleAddTags();
    },
  });

  const { data: tagsTypeListing } = useGetTagsType({});

  // post api for  add bulk tags
  const {
    mutateAsync: mutateAsyncPostBulkTags,
    isSuccess: isSuccessPostBulkTags,
    isLoading: isLoadingPostBulkTags,
    isError: isErrorPostBulkTags,
    data: bulkTagsData,
  } = usePostBulkTagsSupply();
  useEffect(() => {
    if (isSuccessPostBulkTags) {
      setModalState({
        ...modalState,
        open: false,
        isloading: isLoadingPostBulkTags,
      });
      resetForm();
    }
    if (isErrorPostBulkTags) {
      alertTost("Something went wrong", "error");
      setModalState({
        ...modalState,
        open: false,
        isloading: isLoadingPostBulkTags,
      });
    }
    if (isLoadingPostBulkTags)
      setModalState({ ...modalState, isloading: isLoadingPostBulkTags });
  }, [isLoadingPostBulkTags, isErrorPostBulkTags, isSuccessPostBulkTags]);

  const {
    mutateAsync: mutateAsyncPostBulkUpload,
    isSuccess: isSuccessPostBulkUpload,
    isLoading: isLoadingPostBulkUpload,
  } = usePostBulkTagsUpload();

  useEffect(() => {
    if (isSuccessPostBulkUpload) {
      alertTost("Successfully Uploaded", "success");
    }
  }, [isSuccessPostBulkUpload, isLoadingPostBulkUpload]);

  const {
    values: supplyValue,
    errors: supplyError,
    touched: supplyTouched,
    handleBlur: supplyHandleBlur,
    handleChange: supplyHandleChange,
    handleSubmit: supplyHandleSubmit,
    setValues: setSupplyValues,
    resetForm: resetSupplyForm,
  } = useFormik({
    initialValues: addSupplyInitialValues,
    validationSchema: addSupplyValidationSchema,
    onSubmit: () => {
      mutateAsyncPostBulkTags({
        tagType: supplyValue.type,
        count: supplyValue.count,
      });
    },
  });

  useEffect(() => {
    let data: any = bulkTagsData;
    let selectedType: string = "";
    function returnTypeOfTag(value: any) {
      return value?._id === supplyValue?.type;
    }
    selectedType = tagsTypeListing?.find(returnTypeOfTag)?._id;
    if (bulkTagsData) {
      const arr = data.map((item: any, index: any) => ({
        POS: index + 1,
        "Color": selectedType,
        "Basic Url": `https://www.heywooftag.com/?uid=${item.serialNumber}`,
        "Serial Number": item.serialNumber,
        "NFC UID": item.uid,
      }));
      setExcelSheetData(arr);
      setFlag(true);
    }
  }, [bulkTagsData]);

  useEffect(() => {
    if (flag) {
      fileType ? exportToExcel() : exportToCSV();
    }
  }, [flag]);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(excelSheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(excelData, "suppliersList.xlsx");
    setFlag(false);
    alertTost("Successfully Downloaded", "success");
    handleClosenModal();
  };

  const exportToCSV = () => {
    const csvData = unparse(excelSheetData);
    const csvDataBlob = new Blob([csvData], {
      type: "text/csv;charset=utf-8;",
    });

    saveAs(csvDataBlob, "suppliersList.csv");
    setFlag(false);
    alertTost("Successfully Downloaded", "success");
    handleClosenModal();
  };

  // Define state for modal and its initial values
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    modalType: "",
    actionButtonFn: () => null,
    modalTitle: "",
    closeButtonTitle: "Close",
    actionButtonTitle: "",
    actionClass: "btn btn-primary",
    isActionButtonNotRequire: false,
    data: {},
    modalSizeClass: "",
    isloading: false,
  });

  //API for get tags listing
  const {
    data: tagsListing,
    isLoading: isLaodingtagsListing,
    isError: isErrortagsListing,
    refetch: refetchTagsListing,
    isSuccess: isSuccesstagsListing,
  } = useGetTags({
    page: ActivePage.toString(),
    limit: countData,
    search: searchInput?.trim(),
  });
  useEffect(() => {
    let data: any = tagsListing?.tags;
    if (data) {
      const arr = data.map((item: any, index: any) => ({
        Id: item?._id,
        Uid: item?.uid,
        SerialNumber: item?.serialNumber,
        Color: item?.tagType?.type,
        Active: item?.active ? "Active" : "Deactive",
      }));
      setExportSheetData(arr);
    }
  }, [tagsListing]);

  const downloadToCSV = () => {
    const csvData = unparse(excelExportData);
    const csvDataBlob = new Blob([csvData], {
      type: "text/csv;charset=utf-8;",
    });

    saveAs(csvDataBlob, "Tags-List.csv");
  };

  useEffect(() => {
    if (isErrortagsListing) alertTost("Something went wrong", "error");
    if (tagsListing)
      setPageNumber(Math.ceil(tagsListing?.totalCount / countData));
  }, [
    isLaodingtagsListing,
    isErrortagsListing,
    isSuccesstagsListing,
    tagsListing,
  ]);

  // Delete uses APi call
  const {
    mutateAsync: deleteTag,
    isLoading: deletetagLaoding,
    isError: isErrorTagDelete,
    isSuccess: isSuccessTags,
  } = useDeleteTags();

  useEffect(() => {
    if (isSuccessTags) {
      alertTost("Successfully deleted", "success");
      setModalState({
        ...modalState,
        open: false,
        isloading: deletetagLaoding,
      });
    }
    if (isErrorTagDelete) alertTost("Something went wrong", "error");
    if (deletetagLaoding)
      setModalState({ ...modalState, isloading: deletetagLaoding });
  }, [deletetagLaoding, isErrorTagDelete, isSuccessTags]);

  // post api for  add tags
  const {
    mutateAsync: mutateAsyncPostTags,
    isSuccess: isSuccessPostTags,
    isLoading: isLoadingPostTags,
    isError: isErrorPostTags,
  } = usePostTags();

  useEffect(() => {
    if (isSuccessPostTags) {
      alertTost("Sucessfully tag added", "success");
      setModalState({
        ...modalState,
        actionButtonFn: handleAddTags,
        open: false,
        isloading: isLoadingPostTags,
      });
      resetForm();
    }
    if (isErrorPostTags) alertTost("Something went wrong", "error");
    if (isLoadingPostTags)
      setModalState({ ...modalState, isloading: isLoadingPostTags });
  }, [isLoadingPostTags, isSuccessPostTags, isErrorPostTags]);

  // API for Edit banner
  const {
    isError: isErrorTagsEdit,
    isLoading: isLoadingTagsEdit,
    isSuccess: isSuccessTagsEdit,
    mutateAsync: mutateTagsEdit,
  } = useTagsEdit();

  useEffect(() => {
    if (isSuccessTagsEdit) {
      alertTost("Successfully Edited", "success");
      setModalState({
        ...modalState,
        actionButtonFn: handleAddTags,
        open: false,
        isloading: isLoadingTagsEdit,
      });
      setValues({
        type: "",
        uid: "",
        serialId: "",
      });
    }
    if (isErrorTagsEdit) alertTost("Something went wrong", "error");
    if (isLoadingTagsEdit)
      setModalState({ ...modalState, isloading: isLoadingTagsEdit });
  }, [isLoadingTagsEdit, isErrorTagsEdit, isSuccessTagsEdit]);

  // Update user status
  const {
    mutateAsync: mutateAsyncTagsUpdate,
    isLoading: tagsStatusLoading,
    data: tagsStatusData,
  } = useAdminTagsStatus();

  // Update active page to 1 when count changes
  useEffect(() => {
    handlePageNumber(1);
  }, [countData]);

  // Refresh user data when count or active page changes
  useEffect(() => {
    refetchTagsListing();
  }, [countData, ActivePage]);

  // Handle search input change
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  // Handle count input change
  const countChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCountData(e.target.value);
  };

  // Create an array of page numbers for pagination
  function createPageArray(pages: number) {
    const result = [];
    for (let i = 1; i <= pages; i++) {
      result.push(i);
    }
    return result;
  }

  const myPageArray = createPageArray(pageNumber);

  // pagination
  const handlePageNumber = (value: number) => {
    setActivePage(value);
  };

  // Handle add tags
  const handleAddTags = async () => {
    if (modalState.modalType === "Add tags") {
      const typeId: any = await tagsTypeListing?.find(
        (element: any) => element._id === values?.type
      );

      let params: any = await {
        uid: values.uid,
        type: typeId.type,
        serialNumber: values.serialId,
        tagType: values?.type,
      };

      await mutateAsyncPostTags({ data: [params] });
    } else if (modalState.modalType === "Edit") {
      await mutateTagsEdit({
        id: selectedId,
        uid: values.uid,
        type: values.type,
      });
    }
  };

  // Handle deleting a user
  const handleDelete = (data: any) => {
    deleteTag({ id: data?._id, uid: data?.uid });
  };

  // Return the appropriate modal child component based on 'modalType'
  const returnModalChild = () => {
    if (modalState.modalType === "Add tags") {
      return (
        <AddTagsModal
          data={values}
          handleTagsOnChange={handleChange}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
        />
      );
    } else if (modalState.modalType === "View") {
      return <ViewTagsModalChild data={modalState.data} />;
    } else if (modalState.modalType === "Delete") {
      return <DeleteModalChild data={modalState.data} />;
    } else if (modalState.modalType === "Edit") {
      return (
        <AddTagsModal
          data={values}
          handleTagsOnChange={handleChange}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
        />
      );
    } else if (modalState.modalType === "supplier list") {
      return (
        <SupplierModal
          data={supplyValue}
          setValues={setSupplyValues}
          handleTagsOnChange={supplyHandleChange}
          errors={supplyError}
          touched={supplyTouched}
          handleBlur={supplyHandleBlur}
          fileType={fileType}
          setFileType={setFileType}
        />
      );
    } else {
      return null;
    }
  };

  // Handle modal open event and set modal state
  const handleOpenModal = (e: any, data?: any) => {
    if (e.target.title === "Delete") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        actionButtonFn: () => {
          handleDelete(data);
          return null;
        },
        modalTitle: "Delete Pet Detail",
        actionButtonTitle: "Delete",
        actionClass: "btn btn-danger",
        data: data,
        modalSizeClass: "",
      });
    } else if (e.target.title === "View") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        actionButtonFn: () => null,
        modalTitle: "Tags Detail",
        isActionButtonNotRequire: true,
        actionClass: "btn btn-warning",
        data: data,
        modalSizeClass: "",
      });
    } else if (e.target.title === "Edit") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        actionButtonFn: () => {
          handleSubmit();
        },
        modalTitle: "Edit tags Detail",
        actionButtonTitle: "Edit",
        actionClass: "btn btn-success",
        data: data,
        modalSizeClass: "modal-lg",
      });
    } else if (e.target.title === "Add tags") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        modalTitle: "Add tags",
        actionButtonFn: () => {
          handleSubmit();
        },
        actionButtonTitle: "Create",
        actionClass: "btn btn-success",
        data: {},
        modalSizeClass: "modal-lg",
      });
    } else if (e.target.title === "supplier list") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        modalTitle: "Create supplier list",
        actionButtonFn: () => {
          supplyHandleSubmit();
        },
        actionButtonTitle: "Create & Download",
        actionClass: "btn btn-success",
        data: {},
        modalSizeClass: "modal-lg",
      });
    } else {
      return null;
    }
  };

  // Close the modal
  const handleClosenModal = () => {
    setModalState({
      ...modalState,
      open: false,
      isActionButtonNotRequire: false,
    });
    resetForm();
    resetSupplyForm();
  };

  // excel sheet get
  const handleUpload = async (e: any) => {
    e.preventDefault()
    const files = e.target.files;
    const file = files[0];
    try {
      if (file) {
        const formData = new FormData();
        formData.append("excelFile", file);
        await mutateAsyncPostBulkUpload(formData);
      } else {
        return null;
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        alertTost(error?.response?.data?.error, "error");
      }
    }
  };

  // Trigger the file input by focusing on it
  const openUpload = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.xlsx,.xls,.csv';
    fileInput.addEventListener('change', handleUpload);
    fileInput.click();
  };

  // Hanlde activation button
  const handleActivation = async (id: string, action: boolean) => {
    try {
      setLoadingRow(id);
      await mutateAsyncTagsUpdate({ id: id, active: !action });
      if (tagsStatusData) {
        const updatedUserList = tagsListing?.usersList?.map((user: any) => {
          if (user._id === id) {
            user.active = !action;
          }
          return user;
        });
      }
    } catch (error) {
      alertTost("Something went wrong", "error");
    }
  };

  const handleEdit = (e: any, data: any) => {
    handleOpenModal(e, data);
    setValues({
      uid: data?.uid,
      type: data?.type,
      serialId: data?.serialNumber,
    });
    setSelectedId(data?._id);
  };

  return (
    <AdminWrapper header="Wooftags Listing">
      <CustomTable
        buttonName="Add tags"
        modalTitle="Add tags"
        opneModal={handleOpenModal}
        buttonNotRequire={true}
        countChangeHandler={(e) => countChangeHandler(e)}
        tableOption={tableOption}
        handleSearchInput={(e) => handleInputChange(e)}
        searchInput={searchInput}
        pageNumber={myPageArray}
        handlePageChange={(e) => handlePageNumber(e)}
        ActivePage={ActivePage}
        countData={countData}
        tableHeader={tagsTableHeader}
        isListLoading={isLaodingtagsListing || isLoadingPostBulkUpload}
        filteredData={tagsListing?.tags}
        userListData={tagsListing}
        handleUpload={handleUpload}
        importRef={importRef}
        openUpload={openUpload}
        supplierButtonRequire={true}
        exportClick={downloadToCSV}
        IdName={"Serial Number"}
      >
        {(isLaodingtagsListing || isLoadingPostBulkUpload) ? (
          <WootagTableSkeleton tableHeader={tagsTableHeader} />
        ) : (
          tagsListing?.tags?.map((data: any, i: number) => (
            <tr key={data?._id}>
              <td>
                <div className="td-flex">
                  <span
                    className="check-box"
                    onClick={() => setcheckedRow(i)}
                  ></span>
                  <span>{data?.serialNumber}</span>
                </div>
              </td>
              <td>{data?.uid}</td>
              <td>{data?.type ?? data?.tagType?.type}</td>
              <td>
                <a
                  href={`https://www.heywooftag.com/?uid=${data?.serialNumber}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {`https://www.heywooftag.com/?uid=${data?.serialNumber}`}
                </a>
              </td>
              <td className="table-data">
                <div
                  onClick={() => handleActivation(data._id, data.active)}
                  className={` td-div
                                    ${data.active
                      ? "td-status success-state"
                      : "td-status failed-state"
                    }
            ${tagsStatusLoading && "disable"}`}
                >
                  {tagsStatusLoading && loadingRow === data._id ? (
                    <img
                      width={25}
                      src="/assets/images/icon/woofAnimtedLoading.gif"
                      alt=""
                    />
                  ) : data.active ? (
                    "Active"
                  ) : (
                    "Deactive"
                  )}
                </div>
              </td>
              <td className="table-data">
                <img
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Edit"
                  className="table_icon_image "
                  width={20}
                  src="assets/images/icon/edit_icon.png"
                  onClick={(e) => {
                    handleEdit(e, data);
                  }}
                  alt="edit_icon"
                />
                <img
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="View"
                  className="table_icon_image"
                  width={20}
                  onClick={(e) => handleOpenModal(e, data)}
                  src="assets/images/icon/view_info_icon.png"
                  alt="view_icon"
                />
                <img
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Delete"
                  className="table_icon_image"
                  width={18}
                  onClick={(e) => handleOpenModal(e, data)}
                  src="assets/images/icon/delete_icon.png"
                  alt="view_icon"
                />
              </td>
            </tr>
          ))
        )}
      </CustomTable>

      <Modal
        open={modalState.open}
        closeButtonFn={handleClosenModal}
        modalTitle={modalState.modalTitle}
        closeButtonTitle={modalState.closeButtonTitle}
        actionButtonTitle={modalState.actionButtonTitle}
        actionClass={modalState.actionClass}
        actionButtonFn={modalState.actionButtonFn}
        isActionButtonNotRequire={modalState.isActionButtonNotRequire}
        modalSizeClass={modalState.modalSizeClass}
        loading={modalState.isloading}
      >
        {returnModalChild()}
      </Modal>
    </AdminWrapper>
  );
};

export default ManageTags;
