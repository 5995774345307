import { useMutation, useQuery, useQueryClient } from "react-query"
import { QueryReturnType, mutateDeleteFn, mutatePostFn, mutatePutFn } from "../../services/networking.service"
import ENDPOINTS from "../../services/endPoints"
import { Auth } from "../../interface"

interface IPostPayload {
    termsContent: string
}

interface IDeletePayload {
    id: string
}

interface IEditPayload {
    termsContent: string
    id: string
}

interface IActivePayload {
    active: boolean,
    id: string
}

export const usePostTermsAndConditions = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IPostPayload>(data => mutatePostFn(ENDPOINTS.TERMS_AND_CONDITIONS, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useGetTermsAndConditions = (): QueryReturnType<any> => {
    return useQuery<never, never, any>(
        [ENDPOINTS.TERMS_AND_CONDITIONS],
        {
            notifyOnChangeProps: ['data', 'error', 'refetch', 'isRefetching', 'isSuccess', 'isLoading'],
        }
    )
}

export const useDeleteTermsAndConditions = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IDeletePayload>(data => mutateDeleteFn(ENDPOINTS.TERMS_AND_CONDITIONS, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useEditTermsAndConditions = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IEditPayload>(data => mutatePutFn(ENDPOINTS.TERMS_AND_CONDITIONS, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useActiveTermsAndConditions = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IActivePayload>(data => mutatePutFn(ENDPOINTS.TERMS_AND_CONDITIONS_STATUS, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}