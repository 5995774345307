import { useMutation, useQuery, useQueryClient } from "react-query"
import { QueryReturnType, mutateDeleteFn, mutatePostFn, mutatePutFn, } from "../../services/networking.service"
import ENDPOINTS from "../../services/endPoints"
import { Auth } from "../../interface"

interface IPostBanner {
    data: FormData
}

interface IDeleteBanner {
    id: string
}

interface IActiveBanner {
    id: string,
    active: boolean
}

interface IEditBanner {
    data: FormData
}

export const usePostBanner = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IPostBanner>(data => mutatePostFn(ENDPOINTS.BANNERS_API, data.data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useGetBanner = (): QueryReturnType<any> => {
    return useQuery<never, never, any>(
        [ENDPOINTS.BANNERS_API],
        {
            notifyOnChangeProps: ['data', 'error', 'refetch', 'isRefetching', 'isSuccess', 'isLoading'],
        }
    )
}

export const useDeleteBanner = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IDeleteBanner>(data => mutateDeleteFn(ENDPOINTS.BANNERS_API, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useActiveBanner = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IActiveBanner>(data => mutatePutFn(ENDPOINTS.ACTIVE_BANNER_API, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useEditBanner = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IEditBanner>(data => mutatePutFn(ENDPOINTS.BANNERS_API, data.data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}