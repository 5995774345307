import { useMutation, useQuery, useQueryClient } from "react-query"
import { QueryReturnType, mutateDeleteFn, mutatePutFn } from "../../services/networking.service"
import ENDPOINTS from "../../services/endPoints"
import { Auth, UserList } from "../../interface"
import React from "react"

interface Payload {
    userId: String,
    active: boolean
}

interface IProps {
    page: string,
    count: string,
    search:string
}

interface IPayload {
    userId: String,
}


export const useGetUsers = (props: IProps): QueryReturnType<UserList> => {
    return useQuery<never, never, UserList>(
        [ENDPOINTS.GET_USERS, { page: props.page, count: props.count,search:props.search }],
        {
            notifyOnChangeProps: ['data', 'error', 'isRefetching', 'isLoading'],
            //@ts-ignore
            select: React.useCallback(data => data, []),
        }
    )
}

export const useGetUsersStats = (): QueryReturnType<any> => {
    return useQuery<never, never, any>(
        [ENDPOINTS.GET_STATS],
        {
            notifyOnChangeProps: ['data', 'error', 'isRefetching', 'isLoading'],
            //@ts-ignore
            select: React.useCallback(data => data, []),
        }
    )
}

export const useAdminUsersStatus = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, Payload>(data => mutatePutFn(ENDPOINTS.UPDATE_STATUS, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useAdminUserDelete = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IPayload>(data => mutateDeleteFn(ENDPOINTS.DELETE_USER, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}