import React from 'react'
import { Editor } from 'react-draft-wysiwyg';
import Modal from '../../components/Modal';

interface Props {
    editorState: any,
    setEditorState: any,
    preview: boolean,
    convertedContent: string,
    handlePreview: () => void,
    handleTermsAndConditons: () => void,
    handleClose: () => void,
    isLoading: boolean,
}

const EditTermsAndConditions = ({
    editorState,
    setEditorState,
    preview,
    convertedContent,
    handlePreview,
    handleTermsAndConditons,
    handleClose,
    isLoading,
}: Props) => {

    return (
        <div>
            <div className='d-flex justify-content-end mb-2 w-100 '>
                <button className="btn btn-danger mx-1" onClick={handleClose} >Close</button>
            </div>
            <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                wrapperClassName="wrapper-class"
                editorClassName={"editor-class"}
                toolbarClassName="toolbar-class"
                toolbar={{
                    options: ["inline", "list", "textAlign", "remove", "history"],
                }}
            />
            <div className="d-flex justify-content-end mt-4 gap-2">
                <button className="btn btn-primary" onClick={handlePreview}>Preview</button>
                <button
                    className="btn btn-success"
                    onClick={handleTermsAndConditons}
                    disabled={isLoading}>
                    {
                        isLoading
                            ? <img width={25} src='/assets/images/icon/woofAnimtedLoading.gif' alt='' />
                            : "Confirm"
                    }
                </button>
            </div>
            <Modal
                open={preview}
                closeButtonFn={handlePreview}
                isActionButtonNotRequire={true}
                closeButtonTitle={"Close"}
                modalTitle={"Terms and conditions preview"}
                modalSizeClass="modal-xl"
            >
                <div style={{ height: "65vh", overflowY: "auto", padding: "0px 10px 0px 0px " }}>
                    <div dangerouslySetInnerHTML={{ __html: convertedContent }} />
                </div>
            </Modal>
        </div>
    )
}

export default EditTermsAndConditions;