/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, ChangeEvent, useRef } from "react";
import { tableOption, userTableHeader } from "../../seeds";
import { ViewModalChild } from "./viewModalChild";
import { DeleteModalChild } from "./deleteModalChild";
import { CreateUserModal } from "./createUser";
import {
  DataObject,
  ModalState,
  UserDetailsResponse,
  UserList,
} from "../../interface";
import AdminWrapper from "../../components/AdminWrapper";
import CustomTable from "../../components/CustomTable";
import TableSkeleton from "../../skeletons/tableSkeleton";
import Modal from "../../components/Modal";
import alertTost from "../../components/AlertToast";
import {
  useAdminUserDelete,
  useAdminUsersStatus,
  useGetUsers,
} from "../../hooks/api/usersListing";
import * as XLSX from "xlsx";
import { convertArrayToObjects } from "../../utils/helpers";
import { useFormik } from "formik";
import {
  createUsersValidationSchema,
  createUsersinitialValues,
  editUsersValidationSchema,
  editUsersinitialValues,
} from "../../validations";

import { saveAs } from "file-saver";
import Papa from "papaparse";
import { EditUserModal } from "./editUser";

interface DataItem {
  ID: string;
  "USER NAME": string;
  EMAIL: string;
  ACTIVE: string;
}

const MangeUsers = () => {
  const [countData, setCountData] = useState<any>(10);
  const [searchInput, setSearchInput] = useState<any>("");
  const [ActivePage, setActivePage] = useState<number>(1);
  const [checkedRow, setcheckedRow] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [filteredData, setFilteredData] = useState<UserList[] | null>(null);
  const [loadingRow, setLoadingRow] = useState<string | null>(null);
  const importRef = useRef<HTMLInputElement | null>(null);
  const [fileUploaded, setFileUploaded] = useState<DataObject[] | null>(null);
  const [excelSheetData, setExcelSheetData] = useState<DataItem[]>([]);

  const { unparse } = Papa;

  // Define state for modal and its initial values
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    modalType: "",
    actionButtonFn: () => null,
    modalTitle: "",
    closeButtonTitle: "Close",
    actionButtonTitle: "",
    actionClass: "btn btn-primary",
    isActionButtonNotRequire: false,
    data: {},
    modalSizeClass: "",
    isloading: false,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    resetForm,
    setValues,
  } = useFormik({
    initialValues:
      modalState.modalType === "Create User"
        ? createUsersinitialValues
        : editUsersinitialValues,
    validationSchema:
      modalState.modalType === "Create User"
        ? createUsersValidationSchema
        : editUsersValidationSchema,
    onSubmit: () => {
      const handleCreateUser = () => {
        if (modalState.modalType === "Create User") {
          resetForm();
          setModalState({ ...modalState, open: false });
        } else if (modalState.modalType === "Edit") {
          setModalState({ ...modalState, open: false });
        } else return null;
      };
      handleCreateUser();
    },
  });

  // Fetch user data when the component mounts or when page/limit changes
  const {
    data: userListData,
    isLoading: usersListLoading,
    isError: userListError,
  }: UserDetailsResponse = useGetUsers({
    page: ActivePage.toString(),
    count: countData,
    search: searchInput,
  });

  useEffect(() => {
    if (userListError) {
      alertTost("Something went wrong", "error");
    }
  }, [userListError]);

  // Update user status
  const {
    mutateAsync,
    isLoading: userStatusLoading,
    data: userStatusData,
  } = useAdminUsersStatus();

  // Delete uses APi call
  const {
    mutateAsync: deleteUser,
    isLoading: deleteUserLaoding,
    isError: isErrorUserDelete,
    isSuccess: isSuccessUser,
  } = useAdminUserDelete();

  useEffect(() => {
    if (isSuccessUser) {
      alertTost("Successfully deleted", "success");
      setModalState({
        ...modalState,
        open: false,
        isloading: deleteUserLaoding,
      });
    }
    if (isErrorUserDelete) alertTost("Something went wrong", "error");
    if (deleteUserLaoding)
      setModalState({ ...modalState, isloading: deleteUserLaoding });
  }, [deleteUserLaoding, isErrorUserDelete, isSuccessUser]);

  // Filter user data based on search input and update 'filteredData'
  useEffect(() => {
    const filtered = userListData?.usersList?.filter((item: any) => {
      if (searchInput === "") {
        return item;
      } else {
        return (
          item?.email.toLowerCase().includes(searchInput?.trim()) ||
          item?.username.toLowerCase().includes(searchInput?.trim())
        );
      }
    });
    setFilteredData(filtered);
    if (userListData)
      setPageNumber(Math.ceil(userListData?.totalCount / countData));
  }, [searchInput, userListData]);

  //export user data

  useEffect(() => {
    let data: any = userListData?.usersList;
    if (data) {
      const arr = data.map((item: any, index: any) => ({
        ID: item?._id,
        "USER NAME": item?.username,
        EMAIL: item?.email,
        ACTIVE: item?.active ? "Yes" : "No",
      }));

      setExcelSheetData(arr);
    }
  }, [userListData]);

  const exportToCSV = () => {
    const csvData = unparse(excelSheetData);
    const csvDataBlob = new Blob([csvData], {
      type: "text/csv;charset=utf-8;",
    });

    saveAs(csvDataBlob, "Users-List.csv");
  };

  // Update active page to 1 when count changes
  useEffect(() => {
    handlePageNumber(1);
  }, [countData]);

  // Handle search input change
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchInput(e.target.value.toLowerCase());
  };

  // Handle count input change
  const countChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCountData(e.target.value);
  };

  // Create an array of page numbers for pagination
  function createPageArray(pages: number) {
    const result = [];
    for (let i = 1; i <= pages; i++) {
      result.push(i);
    }
    return result;
  }

  const myPageArray = createPageArray(pageNumber);

  // function for handle pagination
  const handlePageNumber = (value: number) => {
    setActivePage(value);
  };

  // Return the appropriate modal child component based on 'modalType'
  const returnModalChild = () => {
    if (modalState.modalType === "Delete") {
      return <DeleteModalChild data={modalState.data} />;
    } else if (modalState.modalType === "View") {
      return <ViewModalChild data={modalState.data} />;
    } else if (modalState.modalType === "Edit") {
      return (
        <EditUserModal
          data={values}
          handleUserOnChange={handleChange}
          errors={errors}
          touched={touched}
        />
      );
    } else if (modalState.modalType === "Create User") {
      return (
        <CreateUserModal
          data={values}
          handleUserOnChange={handleChange}
          errors={errors}
          touched={touched}
        />
      );
    } else {
      return null;
    }
  };

  // Handle modal open event and set modal state
  const handleOpenModal = (e: any, data?: any) => {
    if (e.target.title === "Delete") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        actionButtonFn: () => {
          handleDelete(data._id);
          return null;
        },
        modalTitle: "Delete User",
        actionButtonTitle: "Delete",
        actionClass: "btn btn-danger",
        data: values,
        modalSizeClass: "",
      });
    } else if (e.target.title === "View") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        actionButtonFn: () => null,
        modalTitle: "User Details",
        isActionButtonNotRequire: true,
        actionClass: "btn btn-warning",
        data: data,
        modalSizeClass: "",
      });
    } else if (e.target.title === "Edit") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        actionButtonFn: handleSubmit,
        modalTitle: "Edit Details",
        actionButtonTitle: "Edit",
        actionClass: "btn btn-success",
        data: data,
        modalSizeClass: "modal-lg",
      });
      setValues({
        username: data?.username,
        email: data?.email,
        active: data?.active ? "true" : "false",
      });
    } else if (e.target.title === "Create User") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        modalTitle: "Create User",
        actionButtonFn: handleSubmit,
        actionButtonTitle: "Create",
        actionClass: "btn btn-success",
        data: {},
        modalSizeClass: "modal-lg",
      });
    } else {
      return null;
    }
  };

  // Handle deleting a user
  const handleDelete = (id: string) => {
    deleteUser({ userId: id });
  };

  // Close the modal
  const handleClosenModal = () => {
    setModalState({
      ...modalState,
      open: false,
      isActionButtonNotRequire: false,
      data: {},
    });
    setValues({
      username: "",
      email: "",
      active: "true",
    });
    resetForm();
  };

  // Hanlde activation button
  const handleActivation = async (id: string, action: boolean) => {
    try {
      setLoadingRow(id);
      await mutateAsync({ userId: id, active: !action });
      if (userStatusData) {
        const updatedUserList = userListData?.usersList?.map((user: any) => {
          if (user._id === id) {
            user.active = !action;
          }
          return user;
        });
      }
    } catch (error) {
      alertTost("Something went wrong", "error");
    }
  };

  // excel sheet get
  const handleUpload = async (e: any) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = async function(e: any) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse: string[][] = await XLSX.utils.sheet_to_json(ws, {
        header: 1,
      });
      const convertedData: DataObject[] = await convertArrayToObjects(
        dataParse
      );
      setFileUploaded(convertedData);
    };
    reader.readAsBinaryString(f);
  };

  const openUpload = () => {
    // Trigger the file input by focusing on it
    if (importRef.current) {
      importRef.current.click();
    }
  };

  return (
    <AdminWrapper header="Users Listing">
      <CustomTable
        buttonName="Create user"
        modalTitle="Create User"
        buttonNotRequire={true}
        opneModal={handleOpenModal}
        countChangeHandler={(e) => countChangeHandler(e)}
        tableOption={tableOption}
        handleSearchInput={(e) => handleInputChange(e)}
        searchInput={searchInput}
        pageNumber={myPageArray}
        handlePageChange={(e) => handlePageNumber(e)}
        ActivePage={ActivePage}
        countData={countData}
        tableHeader={userTableHeader}
        isListLoading={usersListLoading}
        filteredData={filteredData}
        userListData={userListData}
        handleUpload={handleUpload}
        importRef={importRef}
        openUpload={openUpload}
        exportClick={exportToCSV}
      >
        {usersListLoading ? (
          <TableSkeleton tableHeader={userTableHeader} />
        ) : (
          filteredData?.map((data: UserList, i: number) => (
            <tr key={data?._id}>
              <td>
                <div className="td-flex">
                  <span className="check-box" onClick={() => setcheckedRow(i)}>
                    {/* <input type="checkbox" checked={i == checkedRow ? true : false}
                                        />
                                        <label htmlFor="check1" /> */}
                  </span>
                  <span>{data._id}</span>
                </div>
              </td>
              <td>{data.username}</td>
              <td>{data.email}</td>
              <td>
                <div
                  onClick={() => handleActivation(data._id, data.active)}
                  className={` td-div
                                    ${
                                      data.active
                                        ? "td-status success-state"
                                        : "td-status failed-state"
                                    }
                                     ${userStatusLoading && "disable"}`}
                >
                  {userStatusLoading && loadingRow === data._id ? (
                    <img
                      width={25}
                      src="/assets/images/icon/woofAnimtedLoading.gif"
                      alt=""
                    />
                  ) : data.active ? (
                    "Active"
                  ) : (
                    "Deactive"
                  )}
                </div>
              </td>
              <td className="table-data">
                <img
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Edit"
                  className="table_icon_image "
                  width={20}
                  src="/assets/images/icon/edit_icon.png"
                  onClick={(e) => handleOpenModal(e, data)}
                  color="red"
                  alt="edit_icon"
                />
                <img
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="View"
                  className="table_icon_image"
                  width={20}
                  onClick={(e) => handleOpenModal(e, data)}
                  src="/assets/images/icon/view_info_icon.png"
                  alt="view_icon"
                />
                <img
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Delete"
                  className="table_icon_image"
                  width={18}
                  onClick={(e) => handleOpenModal(e, data)}
                  src="/assets/images/icon/delete_icon.png"
                  alt="view_icon"
                />
              </td>
            </tr>
          ))
        )}
      </CustomTable>

      <Modal
        open={modalState.open}
        closeButtonFn={handleClosenModal}
        modalTitle={modalState.modalTitle}
        closeButtonTitle={modalState.closeButtonTitle}
        actionButtonTitle={modalState.actionButtonTitle}
        actionClass={modalState.actionClass}
        actionButtonFn={modalState.actionButtonFn}
        isActionButtonNotRequire={modalState.isActionButtonNotRequire}
        modalSizeClass={modalState.modalSizeClass}
        loading={modalState.isloading}
      >
        {returnModalChild()}
      </Modal>
    </AdminWrapper>
  );
};

export default MangeUsers;
