import React, { createContext, useContext, useEffect, useState } from 'react';

const SideBarContext = createContext<any>(null);

interface IProps {
    children: React.ReactNode
}

export const AppProvider = ({ children }: IProps) => {
    const [topBar, setTopBar] = useState(false);

    const toggleSidebar = () => {
        setTopBar(!topBar);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 992) {
                setTopBar(true);
            }
            else {
                setTopBar(false)
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <SideBarContext.Provider value={{ topBar, toggleSidebar }}>{children}</SideBarContext.Provider>

};

export const useSideBarContext = () => useContext(SideBarContext);