import { ChangeEvent } from 'react'

interface Props {
    data: any,
    handleUserOnChange: (e: ChangeEvent<HTMLInputElement>) => void
    errors: any,
    touched: any
}

export const AddPetsModal = ({
    handleUserOnChange,
    data,
    errors,
    touched
}: Props) => {

    return (
        <div >
            <form className='d-flex flex-column w-100 gap-2' >
                <div className="form-group">
                    <label htmlFor="recipient-name" className="col-form-label">Name:</label>
                    <input
                        style={errors.name && touched.name && { border: "1px solid red" }}
                        type="text"
                        className="form-control"
                        name="name"
                        onChange={(e) => handleUserOnChange(e)}
                        value={data?.name}
                        placeholder='Enter name'
                    />
                    {errors?.name && touched?.name && <p className='validationError'>{errors?.name}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="message-text" className="col-form-label">Breed:</label>
                    <input
                        style={errors.breed && touched.breed && { border: "1px solid red" }}
                        className="form-control"
                        id="message-text"
                        name="breed"
                        value={data?.breed}
                        onChange={(e) => handleUserOnChange(e)}
                        placeholder='Enter breed'
                    />
                    {errors?.breed && touched?.breed && <p className='validationError'>{errors?.breed}</p>}
                </div>

            </form>
        </div>
    )
}