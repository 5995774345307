import { IBannerList } from "../../interface";
import Modal from "../../components/Modal";
import { useState } from "react";
import BannersSkelton from "../../skeletons/bannersekelton";

interface Props {
  bannerListing: IBannerList[];
  activeBannerIndex: number | null;
  selectedBannerIndex: number | null;
  handleDeleteBanners: (index: number | null, id: string) => void;
  handleBannerActivation: (data: IBannerList, index: number) => void;
  isDeleteLoading: boolean;
  isLoadingBanner: boolean;
  handleEditBanner: (data: IBannerList, index: number) => void;
  isActiveLoading: boolean;
  modalState: any;
  setModalState: any;
  bannerDelete: boolean;
  setBannerDelete: any;
}

const BannerView = ({
  bannerListing,
  handleDeleteBanners,
  handleBannerActivation,
  activeBannerIndex,
  isDeleteLoading,
  selectedBannerIndex,
  isLoadingBanner,
  handleEditBanner,
  isActiveLoading,
  modalState,
  setModalState,
  bannerDelete,
  setBannerDelete,
}: Props) => {
  const baseUrl = process.env.REACT_APP_BANNER_URL;

  const [bannerPreview, setBannerPreview] = useState<boolean>(false);
  const [bannerPreviewData, setBannerPreviewData] = useState<IBannerList>();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [selectedId, setSelectedId] = useState<string>("");

  const handleDeleteModal = (index: number, id: string) => {
    setBannerDelete(true);
    setSelectedIndex(index);
    setSelectedId(id);
  };

  return (
    <div>
      <div className="row mt-0 gy-4 ">
        {isLoadingBanner ? (
          <BannersSkelton />
        ) : (
          bannerListing?.map((data: IBannerList, index: number) => (
            <div key={index} className="col-md-6 col-lg-4 card-wrapping">
              <div
                className="bg-white shadow-2 py-4 px-2"
                style={{
                  minHeight: "100px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    maxHeight: "400px",
                    marginTop: "18px",
                  }}
                  className="d-flex"
                >
                  <img
                    width={"100%"}
                    height={"200px"}
                    style={{ borderRadius: "8px" }}
                    src={baseUrl + data?.image}
                    alt="img"
                  />
                </div>
                <div
                  className="d-flex flex-column justify-content-start mt-3 ms-2 gap-2"
                  style={{ height: "110px" }}
                >
                  <p
                    style={{ fontSize: "14px", fontWeight: "400", margin: "0" }}
                    className="text"
                  >
                    {data.info}
                  </p>
                  <p
                    style={{ fontSize: "14px", fontWeight: "500", margin: "0" }}
                  >
                    {data.type}
                  </p>
                  <p
                    className="text"
                    style={{ fontSize: "14px", fontWeight: "400", margin: "0" }}
                  >
                    {data.typeContent}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-end m-2 mb-2 gap-2 icons-position p-0 bg-transparent">
                <div className="d-flex align-items-center gap-1 ">
                  <div className="form-check form-switch ">
                    <input
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Action"
                      className="form-check-input active-toggle"
                      type="checkbox"
                      disabled={isActiveLoading && index === activeBannerIndex}
                      onClick={() => handleBannerActivation(data, index)}
                      checked={data.active}
                    />
                  </div>
                  <img
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Edit"
                    className="table_icon_image"
                    width={22}
                    height={22}
                    onClick={(e: any) => {
                      handleEditBanner(data, index);
                      if (e.target.title === "Edit") {
                        setModalState({
                          ...modalState,
                          modalTitle: "Edit Banner",
                          modalType: "Edit",
                        });
                      }
                    }}
                    src="assets/images/icon/edit_icon.png"
                    color="red"
                    alt="edit_icon"
                  />

                  <img
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="View"
                    className="table_icon_image"
                    width={20}
                    height={20}
                    onClick={() => {
                      setBannerPreviewData(data);
                      setBannerPreview(true);
                    }}
                    src="assets/images/icon/view_info_icon.png"
                    color="red"
                    alt="view_icon"
                  />
                  {isDeleteLoading && index === selectedBannerIndex ? (
                    <img
                      className="table_icon_image"
                      width={22}
                      src="/assets/images/icon/woofAnimtedLoading.gif"
                      alt=""
                    />
                  ) : (
                    <img
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Delete"
                      className={`table_icon_image ${isDeleteLoading &&
                        index === selectedBannerIndex &&
                        "disable"}`}
                      onClick={() => handleDeleteModal(index, data._id)}
                      width={22}
                      height={22}
                      src="assets/images/icon/delete_icon.png"
                      alt="view_icon"
                    />
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <Modal
        open={bannerPreview}
        closeButtonFn={() => setBannerPreview(false)}
        isActionButtonNotRequire={true}
        closeButtonTitle="Close"
        modalTitle={"Banner preview"}
        modalSizeClass="modal-lg"
      >
        <div
          className="d-flex flex-column gap-3"
          style={{
            height: "68vh",
            overflow: "auto",
            paddingRight: "10px",
          }}
        >
          <img
            src={`${baseUrl}${bannerPreviewData?.image}`}
            style={{ height: "300px", borderRadius: "8px" }}
            alt="error"
          />
          <div className="banner-info-view">
            <h3> Banner Id </h3>
            <p>{bannerPreviewData?._id}</p>
          </div>
          <div className="banner-info-view">
            <h3> Banner Info </h3>
            <p style={{ margin: "0", textAlign: "justify" }}>
              {bannerPreviewData?.info}
            </p>
          </div>

          <div className="banner-info-view">
            <h3> Banner Type </h3>
            <p style={{ margin: "0" }}>{bannerPreviewData?.type}</p>
          </div>

          <div className="banner-info-view">
            <h3> Banner Content </h3>
            <p style={{ margin: "0", wordBreak: "break-all" }}>
              {bannerPreviewData?.typeContent}
            </p>
          </div>

          <div className="banner-info-view">
            <h3> Banner Status</h3>
            <p style={{ margin: "0" }}>
              {bannerPreviewData?.active ? "Active" : "De-active"}
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        open={bannerDelete}
        closeButtonFn={() => setBannerDelete(false)}
        closeButtonTitle="Close"
        modalTitle="Banner delete"
        actionButtonFn={() => handleDeleteBanners(selectedIndex, selectedId)}
        actionButtonTitle="Delete"
        actionClass="btn btn-danger"
        loading={isDeleteLoading}
      >
        <h1 style={{ fontSize: "15px" }}>Are you sure want to delete?</h1>
      </Modal>
    </div>
  );
};
export default BannerView;
