/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import AdminWrapper from "../../components/AdminWrapper";
import Modal from "../../components/Modal";
import ImageUpload from "./imageUploads";
import BannerView from "./bannerView";
import {
  useActiveBanner,
  useDeleteBanner,
  useEditBanner,
  useGetBanner,
  usePostBanner,
} from "../../hooks/api/bannerList";
import alertTost from "../../components/AlertToast";
import { IBannerList } from "../../interface";
import { numberOnly } from "../../utils/helpers";
import { useFormik } from "formik";
import {
  uploadBannerInitialValues,
  uploasBannerValidationSchema,
} from "../../validations";

interface Props {}

const UploadBanners = (props: Props) => {
  const [openUplaod, setOpenUplaod] = useState(false);
  const [filePreviews, setFilePreviews] = useState<File | null>(null);
  const [bannerList, setBannerList] = useState<File[]>();
  const [activeBannerIndex, setActiveBannerIndex] = useState<number | null>(
    null
  );
  const [selectedBannerIndex, setSelectedBannerIndex] = useState<number | null>(
    null
  );
  const [selectedBannerId, setSelectedBannerId] = useState<string | null>(null);
  const [bannerImagePreview, setBannerImagePreview] = useState<string | null>(
    null
  );
  const [bannerContentType, setBannerContentType] = useState<string | null>(
    null
  );
  const [modalState, setModalState] = useState<any>({
    modalType: "",
    modalTitle: "",
  });
  const [bannerDelete, setBannerDelete] = useState<boolean>(false);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  // API for uplaod banner
  const {
    mutateAsync: uploadBanner,
    isLoading: isLoadingBanner,
    isError: isErrorBanner,
    isSuccess: isSuccessBanner,
  } = usePostBanner();

  useEffect(() => {
    if (isSuccessBanner) {
      alertTost("Successfully Created Banner", "success");
      setOpenUplaod(false);
      resetForm();
      setFilePreviews(null);
      setBannerImagePreview(null);
      setBannerContentType(null);
    }
    if (isErrorBanner) alertTost("Something went wrong", "error");
  }, [isLoadingBanner, isErrorBanner, isSuccessBanner]);

  //API for get banner listing
  const {
    data: bannerListing,
    isLoading: isLaodingBannerListing,
    isError: isErrorBannerListing,
    isSuccess: isSuccessBannerListing,
  } = useGetBanner();

  useEffect(() => {
    if (isErrorBannerListing) alertTost("Something went wrong", "error");
  }, [isLaodingBannerListing, isErrorBannerListing, isSuccessBannerListing]);

  //API for delete banner
  const {
    isError: isErrorBannerDelete,
    isLoading: isLoadingBannerDelete,
    isSuccess: isSuccessBannerDelete,
    mutateAsync: mutateDeleteBanners,
  } = useDeleteBanner();

  useEffect(() => {
    if (isSuccessBannerDelete) {
      const newFileArray = [...bannerListing];
      newFileArray.splice(Number(selectedBannerIndex), 1);
      setBannerList(newFileArray);
      setBannerDelete(false);
      alertTost("Successfully Deleted", "success");
    }

    if (isErrorBannerDelete) alertTost("Something went wrong", "error");
  }, [isLoadingBannerDelete, isErrorBannerDelete, isSuccessBannerDelete]);

  // API for activating/deactivating a banner
  const {
    isError: isErrorBannerActive,
    isLoading: isLoadingBannerActive,
    isSuccess: isSuccessBannerActive,
    mutateAsync: mutateActiveBanners,
  } = useActiveBanner();

  useEffect(() => {
    if (isErrorBannerActive) alertTost("Something went wrong", "error");
  }, [isLoadingBannerActive, isErrorBannerActive, isSuccessBannerActive]);

  // API for Edit banner
  const {
    isError: isErrorBannerEdit,
    isLoading: isLoadingBannerEdit,
    isSuccess: isSuccessBannerEdit,
    mutateAsync: mutateEditBanners,
  } = useEditBanner();

  useEffect(() => {
    if (isSuccessBannerEdit) {
      alertTost("Successfully Edited", "success");
      setOpenUplaod(false);
      resetForm();
      setFilePreviews(null);
      setBannerImagePreview("");
      setSelectedBannerId(null);
      setBannerContentType(null);
    }
    if (isErrorBannerEdit) alertTost("Something went wrong", "error");
  }, [isLoadingBannerEdit, isErrorBannerEdit, isSuccessBannerEdit]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  }: any = useFormik({
    initialValues: uploadBannerInitialValues,
    validationSchema: uploasBannerValidationSchema,
    onSubmit: () => {
      handleUploadBanner();
    },
  });

  // Function to handle banner upload or edit
  const handleUploadBanner = () => {
    let formData = new FormData();
    formData.append("typeContent", values.bannerName);
    formData.append("info", values.bannerInfo);
    formData.append("type", values.bannerType);
    formData.append("image", bannerList as never);
    if (selectedBannerId) {
      formData.append("id", selectedBannerId);
      mutateEditBanners({ data: formData });
    } else uploadBanner({ data: formData });
  };

  // Function to close the modal and reset form data
  const handleClose = () => {
    setOpenUplaod(false);
    resetForm();
    setFilePreviews(null);
    setBannerImagePreview("");
    setSelectedBannerId(null);
    setBannerContentType(null);
  };

  // Function to handle image upload
  const handleImageUpload = (files: File) => {
    setBannerList(files as never);
  };

  // Function to handle banner deletion
  const handleDeleteBanners = (index: number | null, id: string) => {
    setSelectedBannerIndex(index);
    mutateDeleteBanners({ id: id });
  };

  // Function to handle banner activation/deactivation
  const handleBannerActivation = (data: IBannerList, index: number) => {
    mutateActiveBanners({ id: data._id, active: !data.active });
    setActiveBannerIndex(index);
  };

  // Function to handle input field changes
  const handleChangeBanner = (e: any) => {
    const { name, value } = e.target;
    if (name === "bannerType") {
      setFieldValue("bannerType", value);
      setBannerContentType(value);
    }
    if (value === "PHONE") {
      setFieldValue("bannerName", "");
    } else {
      handleChange(e);
    }
  };

  // Function to handle editing a banner
  const handleEditBanner = (data: IBannerList, index: number) => {
    setOpenUplaod(true);
    setFieldValue("bannerName", data.typeContent);
    setFieldValue("bannerType", data.type);
    setFieldValue("bannerInfo", data.info);
    setBannerImagePreview(baseUrl + data?.image);
    setSelectedBannerId(data?._id);
    setBannerContentType(data?.type);
    setBannerList(data?.image as never);
  };

  return (
    <AdminWrapper header="Upload Banners">
      <div className="col mt-26">
        <div className="col-12">
          <div className="selection-div p-relative">
            <button
              className="primary-btn"
              type="button"
              title="Upload"
              onClick={(e: any) => {
                setOpenUplaod(true);
                if (e.target.title === "Upload") {
                  setModalState({
                    ...modalState,
                    modalTitle: "Add Banner",
                    modalType: "Upload",
                  });
                }
              }}
            >
              <svg
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                style={{ marginRight: "5px", marginBottom: "4px" }}
                viewBox="0 0 448 512"
              >
                <path d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z" />
              </svg>
              Upload
            </button>
          </div>
          <BannerView
            bannerDelete={bannerDelete}
            setBannerDelete={setBannerDelete}
            modalState={modalState}
            setModalState={setModalState}
            isActiveLoading={isLoadingBannerActive}
            handleEditBanner={handleEditBanner}
            isLoadingBanner={isLaodingBannerListing}
            selectedBannerIndex={selectedBannerIndex}
            isDeleteLoading={isLoadingBannerDelete}
            bannerListing={bannerListing}
            activeBannerIndex={activeBannerIndex}
            handleDeleteBanners={handleDeleteBanners}
            handleBannerActivation={handleBannerActivation}
          />
        </div>
        <Modal
          open={openUplaod}
          modalTitle={modalState.modalTitle}
          actionButtonTitle={modalState.modalType}
          actionClass="btn btn-success"
          loading={isLoadingBanner || isLoadingBannerEdit}
          closeButtonTitle="Close"
          closeButtonFn={handleClose}
          actionButtonFn={handleSubmit}
          modalSizeClass="modal-lg"
        >
          <div className="w-100 d-flex flex-column gap-2">
            <div className="form-group">
              <label htmlFor="recipient-name" className="col-form-label">
                Banner Information :
              </label>
              <textarea
                rows={3}
                onBlur={handleBlur}
                name="bannerInfo"
                onChange={handleChangeBanner}
                value={values.bannerInfo}
                className={
                  errors.bannerInfo && touched.bannerInfo
                    ? "form-control red-border"
                    : "form-control"
                }
                placeholder="Enter banner info..."
              />
              {errors.bannerInfo && touched.bannerInfo && (
                <p className="validationError">{errors.bannerInfo}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="message-text" className="col-form-label">
                Banner Type :
              </label>
              <select
                onBlur={handleBlur}
                name="bannerType"
                className={
                  errors.bannerType && touched.bannerType
                    ? "form-select red-border"
                    : "form-select"
                }
                onChange={handleChangeBanner}
                value={values.bannerType}
              >
                <option disabled value="">
                  Select Banner Type
                </option>
                <option value="WEB">Web</option>
                <option value="PHONE">Phone</option>
                <option value="WHATSAPP">Whatsapp</option>
              </select>
              {errors.bannerType && touched.bannerType && (
                <p className="validationError">{errors.bannerType}</p>
              )}
            </div>

            {(bannerContentType === "WEB" ||
              bannerContentType === "WHATSAPP" ||
              bannerContentType === "PHONE") && (
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label">
                  Banner Content :
                </label>
                {(bannerContentType === "WEB" ||
                  bannerContentType === "WHATSAPP") && (
                  <>
                    <textarea
                      rows={bannerContentType === "WHATSAPP" ? 3 : 1}
                      className={
                        errors.bannerName && touched.bannerName
                          ? "form-control red-border"
                          : "form-control"
                      }
                      name="bannerName"
                      onChange={handleChangeBanner}
                      onBlur={handleBlur}
                      value={values.bannerName}
                      placeholder={
                        bannerContentType === "WHATSAPP"
                          ? "Enter banner content here..."
                          : "Enter web url here..."
                      }
                    />
                    {errors.bannerName && touched.bannerName && (
                      <p className="validationError">{errors.bannerName}</p>
                    )}
                  </>
                )}

                {bannerContentType === "PHONE" && (
                  <>
                    <input
                      onKeyPress={numberOnly}
                      maxLength={12}
                      name="bannerName"
                      onBlur={handleBlur}
                      className={
                        errors.bannerName && touched.bannerName
                          ? "form-control red-border"
                          : "form-control"
                      }
                      onChange={handleChangeBanner}
                      value={values.bannerName}
                      placeholder="Enter Phone Number"
                    />
                    {errors.bannerName && touched.bannerName && (
                      <p className="validationError">
                        Phone number is required*
                      </p>
                    )}
                  </>
                )}
              </div>
            )}
            <div>
              <label htmlFor="recipient-name" className="col-form-label">
                Attach Files:
              </label>
              <ImageUpload
                filePreviews={filePreviews}
                setFilePreviews={setFilePreviews}
                onUpload={handleImageUpload}
                setBannerPreview={setBannerImagePreview}
                bannerImagePreview={bannerImagePreview}
              />
            </div>
          </div>
        </Modal>
      </div>
    </AdminWrapper>
  );
};

export default UploadBanners;
