/* eslint-disable jsx-a11y/anchor-is-valid */

interface AdminFooterProps {}

const AdminFooter = ({}: AdminFooterProps): JSX.Element => {
  return (
    <footer className="mt-4">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-link-blk">
              <ul className="footer-link-list list-unstyled ps-0 mb-0">
                <li>
                  <a className="nav-link">Privacy Notice</a>
                </li>
                <li>
                  <a className="nav-link">Terms and Conditions</a>
                </li>
                <li>
                  <a className="nav-link">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default AdminFooter;
