const adminEndURL = {
  ADMIN_LOGIN: "auth/login",
  GET_USERS: "admin/users",
  GET_STATS: "admin/users/stats",
  UPDATE_STATUS: "admin/user/active",
  DELETE_USER: "admin/user/delete",
  TERMS_AND_CONDITIONS: "admin/terms",
  TERMS_AND_CONDITIONS_STATUS: "admin/terms/status",
  PRIVACY_POLICY: "admin/privacy",
  PRIVACY_POLICY_STATUS: "admin/privacy/status",
  BANNERS_API: "admin/banner",
  ACTIVE_BANNER_API: "admin/banner/status",
  NOTIFICATIONS: "admin/sendNotificationToAll",
  TAGS: "admin/tags",
  BULK_TAGS_SUPPLY: "admin/bulktags",
  BULK_TAGS_UPLOAD: "admin/bulktags/upload",
  DELETE_TAG: "admin/tag",
  EDIT_TAG: "admin/tag",
  UPDATE_TAG_STATUS: "admin/tags/active",
  ASSIGN_TAGS: "admin/petTags",
  ACTIVE_ASSIGN_TAGS: "admin/petTag/active",

  GET_ALL_TAGS_TYPE: "tagTypes/fetch/all",
  SAVE_TAG_TYPE: "tagTypes/save",
  UPDATE_TAG_TYPE: "tagTypes/update",
  DELETE_TAG_TYPE: "tagTypes/delete",

  GET_ALL_GDPR: "gdpr/fetchAll",
  UPDATE_STATUS_GDPR: "gdpr/markAsResponded",

  PET_LIST: "admin/pets",
  PET: "admin/pet",
};

const ENDPOINTS = {
  ...adminEndURL,
};

export default ENDPOINTS;
