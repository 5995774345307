/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import AdminWrapper from '../../components/AdminWrapper';
import {
    EditorState,
    ContentState,
    convertFromHTML
} from "draft-js";
import { convertToHTML } from 'draft-convert';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CardsListing from "./cardsListing";
import alertTost from "../../components/AlertToast";
import EditPrivacyPolicy from "./editPrivacyPolicy";
import {
    useActivePrivacyPolicy,
    useDeletePrivacyPolicy,
    useEditPrivacyPolicy,
    useGetPrivacyPolicy,
    usePostPrivacyPolicy
} from "../../hooks/api/privacyPolicy";
import { IPrivayPolicy } from "../../interface";

const ManagePrivacyPolicy: React.FC = () => {

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState("");
    const [preview, setPreview] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState<IPrivayPolicy[]>([]);
    const [editPrivacyPolicy, setEditPrivacyPolicy] = useState<boolean>(false)
    const [editingId, setEditingId] = useState<any>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    const [modalData, setModalData] = useState({
        open: false,
        modalTitle: "",
        modalSizeClass: "",
        modalType: "",
        actionButtonTitle: "",
        actionButtonFn: () => null,
        isActionButtonNotRequire: false,
        actionClass: "",
    });

    const handleOpenModal = (e: any, data: any, index: number) => {
        if (e.target.title === "View") {
            setModalData({
                ...modalData,
                open: true,
                modalTitle: "Privacy Policy preview",
                modalSizeClass: "modal-xl",
                modalType: e.target.title,
                isActionButtonNotRequire: true
            })
        }
        else if (e.target.title === "Delete") {
            setModalData({
                ...modalData,
                open: true,
                modalTitle: "Delete Privacy Policy",
                modalSizeClass: "modal-l",
                modalType: e.target.title,
                actionButtonTitle: "Delete",
                isActionButtonNotRequire: false,
                actionButtonFn: () => {
                    deletePrivayPolicy(index, data._id)
                    return null
                },
                actionClass: "btn btn-danger"
            })
        }
        else {
            return null
        }
    };


    const handleModalClose = () => {
        setModalData({
            ...modalData,
            open: false,
            modalSizeClass: "",
        })
    }


    //API for create PRIVACY_POLICY
    const {
        isError: isErrorPrivayPolicyUplod,
        isLoading: isLoadingPrivayPolicyUpload,
        isSuccess: isSuccessPrivayPolicy,
        mutateAsync: mutateCreatePrivayPolicy
    } = usePostPrivacyPolicy();

    useEffect(() => {
        if (isSuccessPrivayPolicy) {
            setPrivacyPolicy([...privacyPolicy, convertToHTML(editorState.getCurrentContent())]);
            alertTost("Successfully created", "success")
            isRefetchPrivayPolicy()
            setEditPrivacyPolicy(false);
            setEditorState(EditorState.createEmpty());
            setEditingId(null);
        }
        if (isErrorPrivayPolicyUplod) alertTost("Something went wrong", "error")
    }, [isLoadingPrivayPolicyUpload,
        isErrorPrivayPolicyUplod,
        isSuccessPrivayPolicy]);

    //API for get list of PRIVACY_POLICY
    const {
        data: dataGetPrivayPolicy,
        error: isErrorGetPrivayPolicy,
        isLoading: isLoadingGetPrivayPolicy,
        isSuccess: isSuccessGetPrivayPolicy,
        refetch: isRefetchPrivayPolicy
    } = useGetPrivacyPolicy()

    useEffect(() => {
        if (isSuccessGetPrivayPolicy && dataGetPrivayPolicy) {
            setPrivacyPolicy(dataGetPrivayPolicy);
        }
        if (isErrorGetPrivayPolicy) alertTost("Something went wrong", "error")
    }, [isLoadingGetPrivayPolicy,
        isErrorGetPrivayPolicy,
        isSuccessGetPrivayPolicy,
        dataGetPrivayPolicy]);

    //API for delete PRIVACY_POLICY
    const {
        isError: isErrorPrivacyPolicyDelete,
        isLoading: isLoadingPrivacyPolicyDelete,
        isSuccess: isSuccessPrivacyPolicyDelete,
        mutateAsync: mutateDeletePrivayPolicy
    } = useDeletePrivacyPolicy();

    useEffect(() => {
        if (isSuccessPrivacyPolicyDelete) {
            alertTost("Successfully Deleted", "success")
            const newFileArray = [...privacyPolicy];
            newFileArray.splice(Number(selectedIndex), 1);
            setPrivacyPolicy(newFileArray);
            setModalData({ ...modalData, open: false })
        }
        if (isErrorPrivacyPolicyDelete) alertTost("Something went wrong", "error")
    }, [isLoadingPrivacyPolicyDelete,
        isErrorPrivacyPolicyDelete,
        isSuccessPrivacyPolicyDelete]);

    //API for Edit PRIVACY_POLICY
    const {
        data: dataPrivacyPolicyEdit,
        isError: isErrorPrivacyPolicyEdit,
        isLoading: isLoadingPrivacyPolicyEdit,
        isSuccess: isSuccessPrivacyPolicyEdit,
        mutateAsync: mutateEditPrivayPolicy
    } = useEditPrivacyPolicy();

    useEffect(() => {
        if (isSuccessPrivacyPolicyEdit && dataPrivacyPolicyEdit) {
            let updatedPrivayPolicy = [...privacyPolicy];
            updatedPrivayPolicy[Number(selectedIndex)].privacyContent = convertToHTML(editorState.getCurrentContent());
            setPrivacyPolicy(updatedPrivayPolicy);
            alertTost("Successfully Edited", "success")
            setEditPrivacyPolicy(false);
            setEditorState(EditorState.createEmpty());
            setEditingId(null);
        }
        if (isErrorPrivacyPolicyEdit) alertTost("Something went wrong", "error")
    }, [isLoadingPrivacyPolicyEdit,
        isErrorPrivacyPolicyEdit,
        isSuccessPrivacyPolicyEdit,
        dataPrivacyPolicyEdit]);

    //API for activate PRIVACY_POLICY

    const {
        data: dataPrivacyPolicyActive,
        isError: isErrorPrivacyPolicyActive,
        isLoading: isLoadingPrivacyPolicyActive,
        isSuccess: isSuccessPrivacyPolicyActive,
        mutateAsync: mutateActivePrivayPolicy
    } = useActivePrivacyPolicy();

    useEffect(() => {
        if (isSuccessPrivacyPolicyActive && dataPrivacyPolicyActive) {
            let updatedPrivayPolicy = [...privacyPolicy];
            updatedPrivayPolicy[Number(selectedIndex)].active = !updatedPrivayPolicy[Number(selectedIndex)].active;
            setPrivacyPolicy(updatedPrivayPolicy);
        }
        if (isErrorPrivacyPolicyActive) alertTost("Something went wrong", "error")
    }, [isErrorPrivacyPolicyActive,
        isSuccessPrivacyPolicyActive,
        dataPrivacyPolicyActive]);

    //  data converted into HTML 
    useEffect(() => {
        const html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
    }, [editorState]);

    // for dsiplay the edited t&c data
    const handlePreview = () => {
        setPreview(!preview)
    };

    // fuctionality on confirm click for edit or create PRIVACY_POLICY data
    const handlePrivacyPolicy = () => {
        if (editingId !== null) {
            // Update existing data at the specified index
            mutateEditPrivayPolicy({ id: editingId, privacyContent: convertToHTML(editorState.getCurrentContent()) })
        } else {
            // Create new terms and conditions
            mutateCreatePrivayPolicy({ privacyContent: convertToHTML(editorState.getCurrentContent()) })
        }
    };

    const handleClose = () => {
        setEditPrivacyPolicy(false)
        setEditorState(EditorState.createEmpty())
    };

    // for handling edited t&c data from specific id 
    const editExistingPrivayPolicy = (data: IPrivayPolicy, index: number) => {
        // Convert HTML to ContentState
        const blocksFromHTML = convertFromHTML(data.privacyContent)
        const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );

        // Create EditorState from ContentState
        const updatedEditorState = EditorState.createWithContent(contentState)
        setEditorState(updatedEditorState)
        setEditPrivacyPolicy(true)
        setEditingId(data._id)
        setSelectedIndex(index)
    };

    const handleCardActivation = (data: IPrivayPolicy, index: number) => {
        mutateActivePrivayPolicy({ id: data._id, active: !data.active })
        setSelectedIndex(index)
    }

    const deletePrivayPolicy = (index: number, id: string) => {
        setSelectedIndex(index)
        mutateDeletePrivayPolicy({ id: id })
    };

    return (
        <AdminWrapper header='Manage Privacy Policy'>
            <div className="row mt-26">
                <div className="col-12">
                    <div className="selection-div p-relative" onClick={() => setEditPrivacyPolicy(true)}>
                        <button className="primary-btn" type='button' title='Create User' >
                            <img src='assets/images/icon/Icons_Light_Add Icon.svg'
                                style={{ marginRight: '0px', marginBottom: "3px" }}
                                alt='add_icon' />
                            Add Policy
                        </button>
                    </div>
                    {!editPrivacyPolicy
                        && <CardsListing
                            isDeleteLoading={isLoadingPrivacyPolicyDelete}
                            isActiveLoading={isLoadingPrivacyPolicyActive}
                            activeCardIndex={selectedIndex}
                            handleCardActivation={handleCardActivation}
                            editExistingPrivayPolicy={editExistingPrivayPolicy}
                            PrivayPolicy={privacyPolicy}
                            handleModalClose={handleModalClose}
                            handleOpenModal={handleOpenModal}
                            modalData={modalData}
                        />
                    }
                    {editPrivacyPolicy
                        && <EditPrivacyPolicy
                            isLoading={isLoadingPrivayPolicyUpload || isLoadingPrivacyPolicyEdit}
                            editorState={editorState}
                            setEditorState={setEditorState}
                            handlePreview={handlePreview}
                            handlePrivacyPolicy={handlePrivacyPolicy}
                            preview={preview}
                            convertedContent={convertedContent}
                            handleClose={handleClose}
                        />
                    }
                </div>
            </div>
        </AdminWrapper>
    );
};

export default ManagePrivacyPolicy;