import { useContext, useState } from 'react'
import { useFormik } from "formik"
import { AuthContext } from '../../../context/authContext';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import alertTost from '../../../components/AlertToast';
import "./login.css";
import userGlobalConfig from '../../../constants/globalConfig';
import { ClipLoader } from 'react-spinners';
import { LogininitialValues, validationSchema } from '../../../validations';
import { useNavigate } from 'react-router-dom';
import { useAdminLogin } from '../../../hooks/api/authentication';

interface Props { }

const Login = (props: Props) => {

    const { setisAuthenticated } = useContext(AuthContext);
    const { mutateAsync, isLoading } = useAdminLogin();
    const [show, setShow] = useState(false);

    const navigate = useNavigate()
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
        initialValues: LogininitialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const response: any = await mutateAsync(values)
                if (response.token) {
                    alertTost("Login successfull", "success")
                    localStorage.setItem(userGlobalConfig.TOKEN, response?.token);
                    resetForm()
                    setisAuthenticated(true)
                }
                else {
                    alertTost("Invalid cradiential", "error")
                }
            }
            catch (error) {
                alertTost("Something went wrong", "error")
            }
        }
    })

    const outlineUsername = (): string | undefined => (touched.email && errors.email ? 'error' : undefined);
    const outlinePassword = (): string | undefined => (touched.password && errors.password ? 'error' : undefined);

    const handleClick = () => setShow(!show);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className='login-main'>
            <div
                className='login-form-main'
                data-aos="fade-right"
                data-aos-duration="1500">
                <div className='inner-content'>
                    <h1>
                        Sign In
                    </h1>
                    <p>
                        Enter your user name and password to sign in!
                    </p>
                    <div className='form-main'>
                        <div className='form-inner-div'>
                            <label>Admin*</label>
                            <input
                                className={outlineUsername()}
                                placeholder='Admin'
                                name='email'
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                            {touched.email && errors.email && (<p>{errors.email}</p>)}
                        </div>
                        <div className='form-inner-div'>
                            <label>Password*</label>
                            <input
                                className={outlinePassword()}
                                type={show ? "text" : "password"}
                                placeholder='Paswword'
                                name='password'
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                            />
                            {touched.password && errors.password && (<p>{errors.password}</p>)}

                            <div onClick={handleClick} className='password-eye'>
                                {show ? <MdOutlineRemoveRedEye /> : <RiEyeCloseLine />}
                            </div>
                        </div>
                        <div className='forgot-main'>
                            <p onClick={() => navigate('/forgot-password')}>Forgot Password ?</p>
                        </div>
                    </div>
                    <button
                        type='button'
                        disabled={isLoading}
                        onClick={() => handleSubmit()}>
                        {isLoading ? <ClipLoader size={17} color="#fff" /> : "Sign In"}
                    </button>
                </div>
            </div>

            <div className='login-logo-main'>
                <img src='assets/images/logo/woofBg.svg' alt='' />
            </div>
        </div>
    )
}

export default Login;