import { useMutation, useQuery, useQueryClient } from "react-query";
import { Auth, DataObject } from "../../interface";
import {
  QueryReturnType,
  mutateDeleteFn,
  mutatePostFn,
  mutatePutFn,
} from "../../services/networking.service";
import ENDPOINTS from "../../services/endPoints";
import React from "react";

interface IProps {
  page?: string;
  limit?: string;
  search?: string;
}

interface ITags {
  data: DataObject[] | null;
}

interface IPayload {
  id: string;
  uid: string;
}

interface IEdit {
  id: string;
  uid: string;
  type: String;
}

interface IEditTagsType {
  _id: string;
  type: string;
  image: String;
  description: String;
}

interface IAddSupply {
  tagType: string;
  count: string;
}

interface IStatus {
  id: String;
  active: boolean;
}

export const useGetTags = ({
  page,
  limit,
  search,
}: IProps): QueryReturnType<any> => {
  return useQuery<never, never, any>(
    [ENDPOINTS.TAGS, { page: page, limit: limit, search: search }],
    {
      notifyOnChangeProps: [
        "data",
        "error",
        "isRefetching",
        "isLoading",
        "refetch",
      ],
      //@ts-ignore
      select: React.useCallback((data) => data, []),
    }
  );
};

export const usePostTags = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutatePostFn(ENDPOINTS.TAGS, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

export const usePostBulkTagsSupply = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, IAddSupply>(
    (data) => mutatePostFn(ENDPOINTS.BULK_TAGS_SUPPLY, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

export const usePostBulkTagsUpload = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, FormData>(
    (data) => mutatePostFn(ENDPOINTS.BULK_TAGS_UPLOAD, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

export const useDeleteTags = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, IPayload>(
    (data) => mutateDeleteFn(ENDPOINTS.DELETE_TAG, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

export const useTagsEdit = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, IEdit>(
    (data) => mutatePutFn(ENDPOINTS.EDIT_TAG, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

export const useAdminTagsStatus = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, IStatus>(
    (data) => mutatePostFn(ENDPOINTS.UPDATE_TAG_STATUS, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

export const useGetTagsType = ({
  page,
  limit,
  search,
}: IProps): QueryReturnType<any> => {
  return useQuery<never, never, any>(
    [ENDPOINTS.GET_ALL_TAGS_TYPE, { page: page, limit: limit, search: search }],
    {
      notifyOnChangeProps: [
        "data",
        "error",
        "isRefetching",
        "isLoading",
        "refetch",
      ],
      //@ts-ignore
      select: React.useCallback((data) => data, []),
    }
  );
};

export const useTagsTypeEdit = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutatePutFn(ENDPOINTS.UPDATE_TAG_TYPE, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

export const useDeleteTagsType = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutateDeleteFn(ENDPOINTS.DELETE_TAG_TYPE, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

export const usePostTagsType = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutatePostFn(ENDPOINTS.SAVE_TAG_TYPE, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};
