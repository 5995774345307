export const petDetails = {
    totalCount: 10,
    data: [
        {
            "_id": "65111f2382a01193a3f38eb8",
            "age": "4",
            "name": "Bruno",
            "breed": "German",
            "owerInfo": "Delhi"
        },
        {
            "_id": "6511583082a01193a3f390d0",
            "age": "5",
            "name": "Tiger",
            "breed": "Lebra",
            "owerInfo": "Delhi"

        },
        {
            "_id": "65115dca82a01193a3f3916f",
            "age": "4",
            "name": "Simba",
            "breed": "Pitbull",
            "owerInfo": "Delhi"

        },
        {
            "_id": "65125f8082a01193a3f394c5",
            "age": "1",
            "name": "Rocky",
            "breed": "Chinese",
            "owerInfo": "Delhi"

        },
        {
            "_id": "651260b382a01193a3f394cc",
            "age": "2",
            "name": "Tommy",
            "breed": "Husky",
            "owerInfo": "Delhi"

        },
        {
            "_id": "65126a7582a01193a3f395d1",
            "age": "2",
            "name": "Jack",
            "breed": "Bulldog",
            "owerInfo": "Delhi"

        },
        {
            "_id": "65128407406cd33c05ad1e33",
            "age": "1",
            "name": "Bob",
            "breed": "RotWieler",
            "owerInfo": "Delhi"

        },
        {
            "_id": "6512a68c406cd33c05ad23a6",
            "age": "2",
            "name": "Desi",
            "breed": "Chow Chow",
            "owerInfo": "Delhi"

        },
        {
            "_id": "6512aaf2406cd33c05ad2418",
            "age": "1",
            "name": "seiser",
            "breed": "Toy Pom",
            "owerInfo": "Delhi"

        },
        {
            "_id": "65132b90406cd33c05ad2d4b",
            "age": "3",
            "name": "Spallek",
            "breed": "Bob Marley",
            "owerInfo": "Delhi"

        },
    ]
}