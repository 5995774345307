/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
"use strict";
import { useEffect, useState, ChangeEvent, useRef } from "react";
import { tableOption, tagsTypeTableHeader } from "../../seeds";
import AdminWrapper from "../../components/AdminWrapper";
import CustomTable from "../../components/CustomTable";
import { DataObject, ModalState } from "../../interface";
import Modal from "../../components/Modal";
import alertTost from "../../components/AlertToast";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Papa from "papaparse";

import {
  useAdminTagsStatus,
  useDeleteTagsType,
  useGetTagsType,
  usePostTagsType,
  useTagsTypeEdit,
} from "../../hooks/api/tags";
import { DeleteModalChild } from "../manageUsers/deleteModalChild";
import { convertArrayToObjects } from "../../utils/helpers";
import { useFormik } from "formik";
import {
  addTagsTypeInitialValues,
  addTagsTypeValidationSchema,
} from "../../validations";
import { AddTagsTypeModal } from "./AddTagsTypeModal";
import { ViewTagsTypeModalChild } from "./viewTags";
import WootagTableSkeleton from "../../skeletons/wooftagsListingSkelton";

interface DataItem {
  POS: string;
  Color: string;
  "Basic Url": string;
  "Serial Number": string;
  "NFC UID": string;
}

interface ExportDataItem {
  ID: string;
  UID: string;
  Type: string;
  ACTIVE: string;
}

interface BulkTagDataItem {
  uid: string;
  active: boolean;
  type: string;
  serialNumber: string;
}

const ManageTagsType = () => {
  const importRef = useRef<HTMLInputElement | null>(null);
  const [countData, setCountData] = useState<any>(10);
  const [searchInput, setSearchInput] = useState<any>();
  const [ActivePage, setActivePage] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [fileUploaded, setFileUploaded] = useState<DataObject[] | null>(null);
  const [selectedId, setSelectedId] = useState<any>(null);
  const [excelSheetData, setExcelSheetData] = useState<DataItem[]>([]);
  const [excelExportData, setExportSheetData] = useState<ExportDataItem[]>([]);
  const [flag, setFlag] = useState<boolean>(false);
  const [fileType, setFileType] = useState<boolean>(false);
  const [bannerImagePreview, setBannerImagePreview] = useState<string | null>(
    null
  );
  const [bannerList, setBannerList] = useState<File[]>();
  const [filePreviews, setFilePreviews] = useState<File | null>(null);

  const baseURL = process.env.REACT_APP_TAG_IMAGES_URL;

  useEffect(() => {
    setFieldValue("image", filePreviews);
  }, [filePreviews]);

  // Function to handle image upload
  const handleImageUpload = (files: File) => {
    setBannerList(files as never);
  };

  const { unparse } = Papa;

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: addTagsTypeInitialValues,
    validationSchema: addTagsTypeValidationSchema,
    onSubmit: () => {
      handleAddTagsType();
    },
  });

  useEffect(() => {
    if (flag) {
      fileType ? exportToExcel() : exportToCSV();
    }
  }, [flag]);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(excelSheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const excelData = new Blob([excelBuffer], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(excelData, "suppliersList.xlsx");
    setFlag(false);
    alertTost("Successfully Downloaded", "success");
    handleClosenModal();
  };

  const exportToCSV = () => {
    const csvData = unparse(excelSheetData);
    const csvDataBlob = new Blob([csvData], {
      type: "text/csv;charset=utf-8;",
    });

    saveAs(csvDataBlob, "suppliersList.csv");
    setFlag(false);
    alertTost("Successfully Downloaded", "success");
    handleClosenModal();
  };

  // Define state for modal and its initial values
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    modalType: "",
    actionButtonFn: () => null,
    modalTitle: "",
    closeButtonTitle: "Close",
    actionButtonTitle: "",
    actionClass: "btn btn-primary",
    isActionButtonNotRequire: false,
    data: {},
    modalSizeClass: "",
    isloading: false,
  });

  //API for get tags listing
  const {
    data: tagsTypeListing,
    isLoading: isLaodingtagsTypeListing,
    isError: isErrortagsTypeListing,
    refetch: refetchTagsListing,
    isSuccess: isSuccesstagsTypeListing,
  } = useGetTagsType({
    page: ActivePage.toString(),
    limit: countData,
    search: searchInput?.trim(),
  });

  useEffect(() => {
    let data: any = tagsTypeListing;
    if (data) {
      const arr = data.map((item: any, index: any) => ({
        ID: item?._id,
        Type: item?.type,
        description: item?.description,
      }));

      setExportSheetData(arr);
    }
  }, [tagsTypeListing]);

  const downloadToCSV = () => {
    const csvData = unparse(excelExportData);
    const csvDataBlob = new Blob([csvData], {
      type: "text/csv;charset=utf-8;",
    });

    saveAs(csvDataBlob, "Tags-List.csv");
  };

  useEffect(() => {
    if (isErrortagsTypeListing) alertTost("Something went wrong", "error");
    if (tagsTypeListing)
      setPageNumber(Math.ceil(tagsTypeListing?.totalCount / countData));
  }, [
    isLaodingtagsTypeListing,
    isErrortagsTypeListing,
    isSuccesstagsTypeListing,
    tagsTypeListing,
  ]);

  // Delete uses APi call
  const {
    mutateAsync: deleteTagsType,
    isLoading: deletetagLaoding,
    isError: isErrorTagDelete,
    isSuccess: isSuccessTags,
  } = useDeleteTagsType();

  useEffect(() => {
    if (isSuccessTags) {
      alertTost("Successfully deleted", "success");
      setModalState({
        ...modalState,
        open: false,
        isloading: deletetagLaoding,
      });
    }
    if (isErrorTagDelete) alertTost("Something went wrong", "error");
    if (deletetagLaoding)
      setModalState({ ...modalState, isloading: deletetagLaoding });
  }, [deletetagLaoding, isErrorTagDelete, isSuccessTags]);

  // post api for  add tag type
  const {
    mutateAsync: mutateAsyncPostTagsType,
    isSuccess: isSuccessPostTagsType,
    data: PostTagsTypeData,
    isLoading: isLoadingPostTagsType,
    isError: isErrorPostTagsType,
    error: errorPostTagsType,
  } = usePostTagsType();

  useEffect(() => {
    if (isSuccessPostTagsType) {
      alertTost("Successfully Tag Type Added", "success");
      setModalState({
        ...modalState,
        actionButtonFn: handleAddTagsType,
        open: false,
        isloading: isLoadingPostTagsType,
      });
      setBannerImagePreview(null);
      setFilePreviews(null);
      setFileUploaded(null);
      setFilePreviews(null);
      setSelectedId(null);
      resetForm();
    }

    if (isErrorPostTagsType) {
      let errorFromAPI: any = errorPostTagsType;
      if (
        errorFromAPI &&
        errorFromAPI?.response &&
        errorFromAPI?.response.status === 400
      ) {
        alertTost(errorFromAPI?.response?.data?.message, "error");
      } else {
        alertTost("Something went wrong", "error");
      }
      setFilePreviews(null);
      setModalState({
        ...modalState,
        actionButtonFn: handleAddTagsType,
        isloading: false,
      });
    }

    if (isLoadingPostTagsType)
      setModalState({ ...modalState, isloading: isLoadingPostTagsType });
  }, [
    isLoadingPostTagsType,
    isSuccessPostTagsType,
    isErrorPostTagsType,
    errorPostTagsType,
  ]);
  // API for Edit banner
  const {
    isError: isErrorTagsTypeEdit,
    isLoading: isLoadingTagsTypeEdit,
    isSuccess: isSuccessTagsTypeEdit,
    mutateAsync: mutateTagsTypeEdit,
  } = useTagsTypeEdit();

  useEffect(() => {
    if (isSuccessTagsTypeEdit) {
      alertTost("Successfully Edited", "success");
      setModalState({
        ...modalState,
        actionButtonFn: handleAddTagsType,
        open: false,
        isloading: isLoadingTagsTypeEdit,
      });
      setFilePreviews(null);
      setFileUploaded(null);
      setValues({
        type: "",
        image: "",
        description: "",
      });
    }
    if (isErrorTagsTypeEdit) alertTost("Something went wrong", "error");
    if (isLoadingTagsTypeEdit)
      setModalState({ ...modalState, isloading: isLoadingTagsTypeEdit });
  }, [isLoadingTagsTypeEdit, isErrorTagsTypeEdit, isSuccessTagsTypeEdit]);

  // Update user status
  const {
    mutateAsync: mutateAsyncTagsUpdate,
    isLoading: tagsStatusLoading,
    data: tagsStatusData,
  } = useAdminTagsStatus();

  // Update active page to 1 when count changes
  useEffect(() => {
    handlePageNumber(1);
  }, [countData]);

  // Refresh user data when count or active page changes
  useEffect(() => {
    refetchTagsListing();
  }, [countData, ActivePage]);

  // Handle search input change
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  // Handle count input change
  const countChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCountData(e.target.value);
  };

  // Create an array of page numbers for pagination
  function createPageArray(pages: number) {
    const result = [];
    for (let i = 1; i <= pages; i++) {
      result.push(i);
    }
    return result;
  }

  const myPageArray = createPageArray(pageNumber);

  // pagination
  const handlePageNumber = (value: number) => {
    setActivePage(value);
  };

  // Handle add tags
  const handleAddTagsType = () => {
    handleUploadTagsType();
  };

  const handleUploadTagsType = () => {
    let formData = new FormData();
    formData.append("type", values.type);
    formData.append("description", values.description);
    formData.append("image", filePreviews as never);
    if (selectedId) {
      formData.append("id", selectedId);
      mutateTagsTypeEdit(formData);
    } else mutateAsyncPostTagsType(formData);
  };

  // Handle deleting a user
  const handleDelete = (data: any) => {
    deleteTagsType({ _id: data?._id });
  };

  // Return the appropriate modal child component based on 'modalType'
  const returnModalChild = () => {
    if (modalState.modalType === "Add tags type") {
      return (
        <AddTagsTypeModal
          filePreviews={filePreviews}
          setFilePreviews={setFilePreviews}
          onUpload={handleImageUpload}
          setBannerPreview={setBannerImagePreview}
          bannerImagePreview={bannerImagePreview}
          data={values}
          handleTagsOnChange={handleChange}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
        />
      );
    } else if (modalState.modalType === "View") {
      return <ViewTagsTypeModalChild data={modalState.data} />;
    } else if (modalState.modalType === "Delete") {
      return <DeleteModalChild data={modalState.data} />;
    } else if (modalState.modalType === "Edit") {
      return (
        <AddTagsTypeModal
          filePreviews={filePreviews}
          setFilePreviews={setFilePreviews}
          onUpload={handleImageUpload}
          setBannerPreview={setBannerImagePreview}
          bannerImagePreview={bannerImagePreview}
          data={values}
          handleTagsOnChange={handleChange}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
        />
      );
    } else {
      return null;
    }
  };

  // Handle modal open event and set modal state
  const handleOpenModal = (e: any, data?: any) => {
    if (e.target.title === "Delete") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        actionButtonFn: () => {
          handleDelete(data);
          return null;
        },
        modalTitle: "Delete Pet Detail",
        actionButtonTitle: "Delete",
        actionClass: "btn btn-danger",
        data: data,
        modalSizeClass: "",
      });
    } else if (e.target.title === "View") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        actionButtonFn: () => null,
        modalTitle: "Tags Type Detail",
        isActionButtonNotRequire: true,
        actionClass: "btn btn-warning",
        data: data,
        modalSizeClass: "modal-lg",
      });
    } else if (e.target.title === "Edit") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        actionButtonFn: () => {
          handleSubmit();
        },
        modalTitle: "Edit Tag Type Detail",
        actionButtonTitle: "Edit",
        actionClass: "btn btn-success",
        data: data,
        modalSizeClass: "modal-lg",
      });
    } else if (e.target.title === "Add tags type") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        modalTitle: "Add tags type",
        actionButtonFn: () => {
          handleSubmit();
        },
        actionButtonTitle: "Create",
        actionClass: "btn btn-success",
        data: {},
        modalSizeClass: "modal-lg",
      });
    } else {
      return null;
    }
  };

  // Close the modal
  const handleClosenModal = () => {
    setModalState({
      ...modalState,
      open: false,
      isActionButtonNotRequire: false,
    });
    setFilePreviews(null);
    setFileUploaded(null);
    setSelectedId(null);
    setBannerImagePreview(null);
    resetForm();
  };

  // excel sheet get
  const handleUpload = async (e: any) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = async function(e: any) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse: string[][] = await XLSX.utils.sheet_to_json(ws, {
        header: 1,
      });
      const convertedData: DataObject[] = await convertArrayToObjects(
        dataParse
      );
      setFileUploaded(convertedData);
    };
    reader.readAsBinaryString(f);
  };

  const openUpload = () => {
    // Trigger the file input by focusing on it
    if (importRef.current) {
      importRef.current.click();
    }
  };

  const handleEdit = (e: any, data: any) => {
    handleOpenModal(e, data);
    setValues({
      type: data?.type,
      image: data?.image,
      description: data?.description,
    });
    setBannerImagePreview(baseUrl + data?.image);
    setSelectedId(data?._id);
  };

  const placeholderImage =
    "https://www.cumbriaeducationtrust.org/wp-content/uploads/2016/01/placeholder.png";

  const onImageError = (e: any) => {
    e.target.src = placeholderImage;
  };

  return (
    <AdminWrapper header="Tags Type Listing">
      <CustomTable
        buttonName="Add tags type"
        modalTitle="Add tags type"
        opneModal={handleOpenModal}
        buttonNotRequire={true}
        countChangeHandler={(e) => countChangeHandler(e)}
        tableOption={tableOption}
        handleSearchInput={(e) => handleInputChange(e)}
        searchInput={searchInput}
        pageNumber={myPageArray}
        handlePageChange={(e) => handlePageNumber(e)}
        ActivePage={ActivePage}
        countData={countData}
        tableHeader={tagsTypeTableHeader}
        isListLoading={isLaodingtagsTypeListing}
        filteredData={tagsTypeListing}
        userListData={tagsTypeListing}
        handleUpload={handleUpload}
        importRef={importRef}
        openUpload={openUpload}
        exportClick={downloadToCSV}
      >
        {isLaodingtagsTypeListing ? (
          <WootagTableSkeleton tableHeader={tagsTypeTableHeader} />
        ) : (
          tagsTypeListing?.map((data: any, i: number) => (
            <tr key={data?._id}>
              <td>{data?._id}</td>
              <td>{data?.type}</td>
              <td>
                <a
                  href={data?.image ? baseURL + data?.image : placeholderImage}
                  target="_blank"
                >
                  <img
                    className="image-type-icon"
                    src={data?.image ? baseURL + data?.image : placeholderImage}
                    alt="Tag type image"
                    onError={onImageError}
                  />
                </a>
              </td>
              <td className="table-data">
                <div className="description-handler">
                  {data?.description?.length > 140
                    ? `${data?.description.slice(0, 140)}...`
                    : data?.description}
                </div>
              </td>
              <td className="table-data">
                <img
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Edit"
                  className="table_icon_image "
                  width={20}
                  src="assets/images/icon/edit_icon.png"
                  onClick={(e) => {
                    handleEdit(e, data);
                  }}
                  alt="edit_icon"
                />
                <img
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="View"
                  className="table_icon_image"
                  width={20}
                  onClick={(e) => handleOpenModal(e, data)}
                  src="assets/images/icon/view_info_icon.png"
                  alt="view_icon"
                />
                <img
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Delete"
                  className="table_icon_image"
                  width={18}
                  onClick={(e) => handleOpenModal(e, data)}
                  src="assets/images/icon/delete_icon.png"
                  alt="view_icon"
                />
              </td>
            </tr>
          ))
        )}
      </CustomTable>

      <Modal
        open={modalState.open}
        closeButtonFn={handleClosenModal}
        modalTitle={modalState.modalTitle}
        closeButtonTitle={modalState.closeButtonTitle}
        actionButtonTitle={modalState.actionButtonTitle}
        actionClass={modalState.actionClass}
        actionButtonFn={modalState.actionButtonFn}
        isActionButtonNotRequire={modalState.isActionButtonNotRequire}
        modalSizeClass={modalState.modalSizeClass}
        loading={modalState.isloading}
      >
        {returnModalChild()}
      </Modal>
    </AdminWrapper>
  );
};

export default ManageTagsType;
