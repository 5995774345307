/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, ChangeEvent, useRef } from "react";
import { tableOption, assignedTagsTableHeader } from "../../seeds";
import AdminWrapper from "../../components/AdminWrapper";
import CustomTable from "../../components/CustomTable";
import { DataObject, ModalState } from "../../interface";
import Modal from "../../components/Modal";
import alertTost from "../../components/AlertToast";
import { DeleteModalChild } from "../manageUsers/deleteModalChild";
import * as XLSX from "xlsx";
import { convertArrayToObjects } from "../../utils/helpers";

import {
  useAdminAssignTagsStatus,
  useGetAssingTags,
} from "../../hooks/api/assignTags";
import { ViewAssignTagsModalChild } from "./viewAssignTagModel";
import AssignWootagTableSkeleton from "../../skeletons/assignWooftagsSkelton";
import { saveAs } from "file-saver";
import Papa from "papaparse";

interface ExportDataItem {
  ID: string;
  UID: string;
  Type: string;
  ACTIVE: string;
}

const ManageAssignTags = () => {
  const importRef = useRef<HTMLInputElement | null>(null);
  const [countData, setCountData] = useState<any>(10);
  const [searchInput, setSearchInput] = useState<any>();
  const [ActivePage, setActivePage] = useState<number>(1);
  const [checkedRow, setcheckedRow] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [fileUploaded, setFileUploaded] = useState<DataObject[] | null>(null);
  const [loadingRow, setLoadingRow] = useState<string | null>(null);
  const [excelSheetData, setExcelSheetData] = useState<ExportDataItem[]>([]);

  const { unparse } = Papa;

  const baseUrl = process.env.REACT_APP_BASE_URL;

  // Define state for modal and its initial values
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    modalType: "",
    actionButtonFn: () => null,
    modalTitle: "",
    closeButtonTitle: "Close",
    actionButtonTitle: "",
    actionClass: "btn btn-primary",
    isActionButtonNotRequire: false,
    data: {},
    modalSizeClass: "",
    isloading: false,
  });

  //API for get AssignTags listing
  const {
    data: assignTagsListing,
    isLoading: isLaodingAssignTagsListing,
    isError: isErrorAssignTagsListing,
    isSuccess: isSuccessAssignTagsListing,
  } = useGetAssingTags({
    page: ActivePage.toString(),
    limit: countData,
    search: searchInput?.trim(),
  });

  //export assign tag data

  useEffect(() => {
    let data: any = assignTagsListing?.petTags;
    if (data) {
      const arr = data.map((item: any, index: any) => ({
        "ASSIGN ID": item?._id,
        "TAG ID": item?.tag?._id,
        "TAG UID": item?.tag?.uid,
        "TAG NAME": item?.tagName,
        "PET NAME": item?.pet?.name,
        "PET BREED": item?.pet?.breed,
        "PET COLOR": item?.pet?.color,
      }));

      setExcelSheetData(arr);
    }
  }, [assignTagsListing]);

  const exportToCSV = () => {
    const csvData = unparse(excelSheetData);
    const csvDataBlob = new Blob([csvData], {
      type: "text/csv;charset=utf-8;",
    });

    saveAs(csvDataBlob, "Assign-Pet-List.csv");
  };

  useEffect(() => {
    if (isErrorAssignTagsListing) alertTost("Something went wrong", "error");
    if (assignTagsListing)
      setPageNumber(Math.ceil(assignTagsListing?.totalCount / countData));
  }, [
    isLaodingAssignTagsListing,
    isErrorAssignTagsListing,
    isSuccessAssignTagsListing,
    assignTagsListing,
  ]);

  // Update user status
  const {
    mutateAsync: mutateAsyncAssignTagsUpdate,
    isLoading: assignTagsStatusLoading,
    data: assignTagsStatusData,
  } = useAdminAssignTagsStatus();

  // Update active page to 1 when count changes
  useEffect(() => {
    handlePageNumber(1);
  }, [countData]);

  // Handle search input change
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  // Handle count input change
  const countChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCountData(e.target.value);
  };

  // Create an array of page numbers for pagination
  function createPageArray(pages: number) {
    const result = [];
    for (let i = 1; i <= pages; i++) {
      result.push(i);
    }
    return result;
  }

  const myPageArray = createPageArray(pageNumber);

  // pagination
  const handlePageNumber = (value: number) => {
    setActivePage(value);
  };

  // // Handle add tags
  // const handleAddTags = () => {
  //     if (modalState.modalType === "Add tags") mutateAsyncPostTags({ data: [modalState?.data] })
  //     else if (modalState.modalType === "Edit") mutateTagsEdit({
  //         id: modalState?.data?._id,
  //         uid: modalState?.data?.uid,
  //         type: modalState?.data?.type
  //     })
  // }

  // Handle on tags change
  const handleTagsOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let data = modalState.data || {};
    data[name] = value;
    // setModalState({ ...modalState, data: data, actionButtonFn: handleAddTags })
  };

  // Return the appropriate modal child component based on 'modalType'
  const returnModalChild = () => {
    if (modalState.modalType === "View") {
      return <ViewAssignTagsModalChild data={modalState.data} />;
    } else if (modalState.modalType === "Delete") {
      return <DeleteModalChild data={modalState.data} />;
    } else {
      return null;
    }
  };

  // Handle modal open event and set modal state
  const handleOpenModal = (e: any, data?: any) => {
    if (e.target.title === "Delete") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        actionButtonFn: () => {
          // handleDelete(data);
          return null;
        },
        modalTitle: "Delete Pet Detail",
        actionButtonTitle: "Delete",
        actionClass: "btn btn-danger",
        data: data,
        modalSizeClass: "",
      });
    } else if (e.target.title === "View") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        actionButtonFn: () => null,
        modalTitle: "Tags Detail",
        isActionButtonNotRequire: true,
        actionClass: "btn btn-warning",
        data: data,
        modalSizeClass: "",
      });
    } else if (e.target.title === "Edit") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        actionButtonFn: () => {
          // handleAddTags();
        },
        modalTitle: "Edit Pet Detail",
        actionButtonTitle: "Edit",
        actionClass: "btn btn-success",
        data: data,
        modalSizeClass: "modal-lg",
      });
    } else {
      return null;
    }
  };

  // Close the modal
  const handleClosenModal = () => {
    setModalState({
      ...modalState,
      open: false,
      isActionButtonNotRequire: false,
      data: {},
    });
  };

  // excel sheet get
  const handleUpload = async (e: any) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = async function (e: any) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse: string[][] = await XLSX.utils.sheet_to_json(ws, {
        header: 1,
      });
      const convertedData: DataObject[] = await convertArrayToObjects(
        dataParse
      );
      setFileUploaded(convertedData);
    };
    reader.readAsBinaryString(f);
  };

  // useEffect(() => {
  //     if (fileUploaded) {
  //         mutateAsyncPostTags({ data: fileUploaded })
  //     }
  // }, [fileUploaded])

  const openUpload = () => {
    // Trigger the file input by focusing on it
    if (importRef.current) {
      importRef.current.click();
    }
  };

  // Hanlde activation button
  const handleActivation = async (id: string, action: boolean) => {
    try {
      setLoadingRow(id);
      await mutateAsyncAssignTagsUpdate({ id: id, active: !action });
      if (assignTagsStatusData) {
        const updatedUserList = assignTagsListing?.usersList?.map(
          (user: any) => {
            if (user._id === id) {
              user.active = !action;
            }
            return user;
          }
        );
      }
    } catch (error) {
      alertTost("Something went wrong", "error");
    }
  };

  return (
    <AdminWrapper header=" Assign Wooftags Listing">
      <CustomTable
        modalTitle="Add tags"
        opneModal={handleOpenModal}
        countChangeHandler={(e) => countChangeHandler(e)}
        tableOption={tableOption}
        handleSearchInput={(e) => handleInputChange(e)}
        searchInput={searchInput}
        pageNumber={myPageArray}
        handlePageChange={(e) => handlePageNumber(e)}
        ActivePage={ActivePage}
        countData={countData}
        tableHeader={assignedTagsTableHeader}
        isListLoading={isLaodingAssignTagsListing}
        filteredData={assignTagsListing?.petTags}
        userListData={assignTagsListing}
        handleUpload={handleUpload}
        importRef={importRef}
        openUpload={openUpload}
        exportClick={exportToCSV}
      >
        {isLaodingAssignTagsListing ? (
          <AssignWootagTableSkeleton tableHeader={assignedTagsTableHeader} />
        ) : (
          assignTagsListing?.petTags?.map((data: any, i: number) => (
            <tr key={data?._id}>
              <td>
                <div className="td-flex">
                  <span
                    className="check-box"
                    onClick={() => setcheckedRow(i)}
                  ></span>
                  <span>{data._id}</span>
                </div>
              </td>
              <td>{data.tag?.uid}</td>
              <td>{data.tag?.type}</td>
              <td>{data.pet?.name}</td>
              <td>{data.pet?.breed}</td>
              <td>
                <img
                  className="table-image"
                  src={`${baseUrl}${data.pet?.image}`}
                  onClick={() => window.open(`${baseUrl}${data.pet?.image}`)}
                  alt="logo"
                />
              </td>
              <td>{data.pet?.lost === true ? "YES" : "NO"}</td>
              <td className="table-data">
                <div
                  onClick={() => handleActivation(data._id, data.active)}
                  className={` td-div
                                    ${data.active
                      ? "td-status success-state"
                      : "td-status failed-state"
                    }
                                     ${assignTagsStatusLoading && "disable"}`}
                >
                  {assignTagsStatusLoading && loadingRow === data._id ? (
                    <img
                      width={25}
                      src="/assets/images/icon/woofAnimtedLoading.gif"
                      alt=""
                    />
                  ) : data.active ? (
                    "Active"
                  ) : (
                    "Deactive"
                  )}
                </div>
              </td>

              <td className="table-data">
                <img
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="View"
                  className="table_icon_image"
                  width={20}
                  onClick={(e) => handleOpenModal(e, data)}
                  src="assets/images/icon/view_info_icon.png"
                  alt="view_icon"
                />
              </td>
            </tr>
          ))
        )}
      </CustomTable>

      <Modal
        open={modalState.open}
        closeButtonFn={handleClosenModal}
        modalTitle={modalState.modalTitle}
        closeButtonTitle={modalState.closeButtonTitle}
        actionButtonTitle={modalState.actionButtonTitle}
        actionClass={modalState.actionClass}
        actionButtonFn={modalState.actionButtonFn}
        isActionButtonNotRequire={modalState.isActionButtonNotRequire}
        modalSizeClass={modalState.modalSizeClass}
        loading={modalState.isloading}
      >
        {returnModalChild()}
      </Modal>
    </AdminWrapper>
  );
};

export default ManageAssignTags;
