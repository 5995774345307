import React from 'react'

interface Props {
    data: any,
    notificationOnChange: (e: any) => void
    touched: any
    errors: any
    handleBlur: any
}

const CreateNotifications = ({ notificationOnChange, data, handleBlur, errors, touched }: Props) => {
    return (
        <div >
            <form className='d-flex flex-column w-100 gap-2' >
                <div className="form-group">
                    <label htmlFor="recipient-name" className="col-form-label">Title:</label>
                    <input
                        style={errors.title && touched.title && { border: "1px solid red" }}
                        type="text"
                        className="form-control"
                        name="title"
                        onBlur={handleBlur}
                        onChange={notificationOnChange}
                        value={data?.title}
                        placeholder='Enter notification title'
                    />
                    {errors.title && touched.title && <p className='validationError'>{errors.title}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="message-text" className="col-form-label">Message</label>
                    <textarea
                        style={errors.message && touched.message && { border: "1px solid red" }}
                        rows={5}
                        className="form-control"
                        id="message-text"
                        name="message"
                        onBlur={handleBlur}
                        value={data?.message}
                        onChange={notificationOnChange}
                        placeholder='Enter notification message...'
                    />
                    {errors.message && touched.message && <p className='validationError'>{errors.message}</p>}
                </div>

            </form>
        </div>
    )
}

export default CreateNotifications