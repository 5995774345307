interface Props {

}

export const NotFound = (props: Props) => {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center w-100' style={{ height: "55vh" }}>
            <img src='assets/images/icon/search-file.gif' style={{ marginLeft: "15px", marginBottom: '2px' }} width={110} alt='not_found' />
            <h3>Data Not Found</h3>
        </div>
    )
}