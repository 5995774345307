/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { KeyValueInterface, TableProps } from "../../interface";
import { NotFound } from "../NotFound";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

const CustomTable = ({
  countChangeHandler,
  tableOption,
  handleSearchInput,
  searchInput,
  pageNumber,
  handlePageChange,
  ActivePage,
  tableHeader,
  children,
  isListLoading,
  filteredData,
  userListData,
  opneModal,
  buttonNotRequire,
  buttonName,
  modalTitle,
  handleUpload,
  importRef,
  openUpload,
  supplierButtonRequire,
  exportClick,
  exportBtnRequired = true,
  importBtnRequired = true,
  IdName,
}: TableProps) => {
  return (
    <div className="row mt-26 gy-4 gy-lg-0">
      <div className="col-lg-12">
        <div className="table-main-blk bg-white table-shadow spacer-y24 p-relative">
          <div className="table-header">
            <div className="row gy-3">
              <div className="col-md-6">
                <div className="filter-section">
                  <div className="select-blk">
                    <select
                      className="form-select"
                      onChange={countChangeHandler}
                    >
                      {tableOption.map((data) => (
                        <option key={data.id}>{data.page}</option>
                      ))}
                    </select>
                  </div>
                  <div className="search-blk p-relative">
                    <span className="search-icon">
                      <img src="assets/images/icon/search.svg" alt="Search" />
                    </span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search..."
                      onChange={handleSearchInput}
                      value={searchInput}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-end gap-1 flex-wrap">
                {supplierButtonRequire && (
                  <button
                    className="primary-btn"
                    type="button"
                    title="supplier list"
                    onClick={opneModal}
                  >
                    Supplier List
                  </button>
                )}
                {buttonNotRequire && (
                  <button
                    className="primary-btn"
                    type="button"
                    title={modalTitle}
                    onClick={opneModal}
                  >
                    <img
                      src="assets/images/icon/Icons_Light_Add Icon.svg"
                      style={{ marginRight: "0px", marginBottom: "3px" }}
                      alt="add_icon"
                    />
                    {buttonName}
                  </button>
                )}
                {exportBtnRequired ? <button className="primary-btn" onClick={exportClick}>
                  <svg
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    style={{ marginRight: "5px", marginBottom: "4px" }}
                    viewBox="0 0 448 512"
                  >
                    <path d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z" />
                  </svg>
                  Export
                </button> : null}
                <input
                  type="file"
                  ref={importRef}
                  style={{ display: 'none' }}
                  onChange={handleUpload}
                />
                {importBtnRequired ? <button className="primary-btn" onClick={openUpload}>
                  <img
                    style={{
                      marginRight: "5px",
                      marginBottom: "4px",
                      filter: "invert()",
                    }}
                    width={15}
                    height={15}
                    src="assets/images/icon/import.png"
                    alt=""
                  />
                  Import
                </button> : null}
              </div>
            </div>
          </div>
          <table className="table mb-0">
            {!isListLoading && (
              <thead>
                <tr>
                  <th>
                    <div className="th-flex">
                      <span className="check-box"></span>
                      <span> {IdName ?? "ID"}</span>
                    </div>
                  </th>
                  {tableHeader.map(({ value, key }: KeyValueInterface) => {
                    return <th key={value}>{value}</th>;
                  })}
                </tr>
              </thead>
            )}
            <tbody>{children}</tbody>
          </table>
          {!isListLoading && filteredData?.length === 0 && <NotFound />}
          <div className="table-footer">
            <div className="row gy-2 align-items-center">
              <div className="col-md-4">
                <div className="showing-result-blk">
                  <p className="mb-0">
                    Total entries :
                    {userListData?.totalCount ?? userListData?.count}
                  </p>
                </div>
              </div>
              <div className="col-md-8 abc">
                <ResponsivePagination
                  current={ActivePage}
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  total={pageNumber?.length}
                  activeItemClassName="pagination-bar"
                  onPageChange={(e: any) => handlePageChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
