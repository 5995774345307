import React, { useContext } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
  HashRouter,
} from "react-router-dom";
import { AuthContext } from "../context/authContext";
import Dashboard from "../screens/dashboard";
import Login from "../screens/auth/login";
import Sidebar from "../components/Sidebar";
import ManageUsers from "../screens/manageUsers";
import ManagePets from "../screens/managePets";
import ManageTermsAndConditions from "../screens/manageTermsAndCondtions";
import UploadBanners from "../screens/uploadBanners";
import ForgotPassword from "../screens/auth/forgotPassword";
import ManageLostsPets from "../screens/manageLostsPets";
import Notifications from "../screens/notifications";
import ManageTags from "../screens/manageTags";
import ManageAssignTags from "../screens/manageAssignTags";
import ManagePrivacyPolicy from "../screens/managePrivacyPolicy";
import ManageTagsType from "../screens/ManageTagsType";
import ManageGdprRequests from "../screens/GdprRequests";

const Routing = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const Authlayout = () => {
    return <Outlet />;
  };

  const Pagelayout = () => {
    return (
      <>
        <Sidebar />
        <Outlet />
      </>
    );
  };

  return (
    <HashRouter>
      <Routes>
        {!isAuthenticated ? (
          <Route element={<Authlayout />}>
            <Route path="/" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>
        ) : (
          <Route element={<Pagelayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/manageusers" element={<ManageUsers />} />
            <Route path="/managepets" element={<ManagePets />} />
            <Route path="/managelostspets" element={<ManageLostsPets />} />
            <Route path="/managetermsandconditions" element={<ManageTermsAndConditions />} />
            <Route path="/manageprivacypolicy" element={<ManagePrivacyPolicy />} />
            <Route path="/managetags" element={<ManageTags />} />
            <Route path="/manage-tags-type" element={<ManageTagsType />} />
            <Route path="/manageassigntags" element={<ManageAssignTags />} />
            <Route path="/gdprequests" element={<ManageGdprRequests />} />
            <Route path="/uploadbanners" element={<UploadBanners />} />
            <Route path="/notifications" element={<Notifications />} />
          </Route>
        )}
        <Route
          path="*"
          element={<Navigate to={!isAuthenticated ? "/" : "/dashboard"} />}
        />
      </Routes>
    </HashRouter>
  );
};
export default Routing;
