import { useFormik } from 'formik'
import React, { useState } from 'react'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { RiEyeCloseLine } from 'react-icons/ri'
import { forgotPasswordValidationSchema, forgotPasswordinitialValues } from '../../../validations'
import { ClipLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import alertTost from '../../../components/AlertToast'

const ForgotPassword = () => {

    const [show, setShow] = useState({
        passowrdVaisibility: false,
        confirmpassowrdVaisibility: false
    });

    const navigate = useNavigate();

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
        initialValues: forgotPasswordinitialValues,
        validationSchema: forgotPasswordValidationSchema,
        onSubmit: async (values) => {
            alert(JSON.stringify(values))
            alertTost("Successfully forgot password", "success")
            navigate('/login')
        }
    })
    const outlineUsername = (): string | undefined => (touched.password && errors.password ? 'error' : undefined);
    const outlinePassword = (): string | undefined => (touched.ConfirmPassword && errors.ConfirmPassword ? 'error' : undefined);

    const handleClick = (field: string) => {
        setShow((prevState: any) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };
    return (
        <div className='login-main'>
            <div className='login-form-main'
                data-aos="fade-right"
                data-aos-duration="1500"
            >
                <div className='inner-content'>
                    <h1>
                        Forgot Password
                    </h1>
                    <p>
                        Enter your password  and confirm password to forgot!
                    </p>
                    <div className='form-main'>
                        <div className='form-inner-div'>
                            <label>Password*</label>
                            <input
                                className={outlineUsername()}
                                type={show.passowrdVaisibility ? "text" : "password"}
                                placeholder='Enter your password'
                                name='password'
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                            {touched.password && errors.password && (<p>{errors.password}</p>)}
                            <div onClick={() => handleClick("passowrdVaisibility")} className='password-eye'>
                                {show.passowrdVaisibility ? <MdOutlineRemoveRedEye /> : <RiEyeCloseLine />
                                }
                            </div>
                        </div>
                        <div className='form-inner-div'>
                            <label>Confirm Password*</label>
                            <input
                                className={outlinePassword()}
                                type={show.confirmpassowrdVaisibility ? "text" : "password"}
                                placeholder='Confirm password'
                                name='ConfirmPassword'
                                value={values.ConfirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                            />
                            {touched.ConfirmPassword && errors.ConfirmPassword && (<p>{errors.ConfirmPassword}</p>)}

                            <div onClick={() => handleClick("confirmpassowrdVaisibility")} className='password-eye'>
                                {show.confirmpassowrdVaisibility ? <MdOutlineRemoveRedEye /> : <RiEyeCloseLine />}
                            </div>
                        </div>
                    </div>
                    <button
                        style={{ marginTop: "40px" }}
                        type='button'
                        // disabled={isLoading}
                        onClick={() => handleSubmit()}>
                        {/* {isLoading ? <ClipLoader size={17} color="#fff" /> : "Confirm"} */}
                        Confirm
                    </button>
                    <div style={{ display: 'flex', justifyContent: "center", marginTop: "10px" }}>
                        <p>Back to <span style={{ cursor: "pointer", color: "#2991e5", fontWeight: "700" }} onClick={() => navigate("/login")}>Login</span></p>
                    </div>
                </div>

            </div>
            <div className='login-logo-main'>
                <img src='assets/images/logo/woofBg.svg' alt='' />
            </div>
        </div>
    )
}

export default ForgotPassword