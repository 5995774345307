import React, { useState, useEffect } from 'react';
import './App.css';
import Routing from './navigation';
import { QueryClientProvider } from 'react-query'
import { AuthContext } from './context/authContext';
import { Toaster } from 'react-hot-toast';
import { queryClient } from './services/networking.service';
import userGlobalConfig from './constants/globalConfig';
import { AppProvider } from './context/sidebarContext';
import AOS from 'aos';
import 'aos/dist/aos.css'

function App() {
  const [isAuthenticated, setisAuthenticated] = useState(localStorage.getItem(userGlobalConfig.TOKEN) ? true : false);

  useEffect(() => {
    AOS.init()
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <AuthContext.Provider value={{ isAuthenticated, setisAuthenticated }}>
          <Routing />
          <Toaster />
        </AuthContext.Provider>
      </AppProvider>
    </QueryClientProvider>
  );
}

export default App;