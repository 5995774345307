/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

interface ImageUploadProps {
  onUpload: (files: File) => void;
  filePreviews: any;
  setFilePreviews: any;
  bannerImagePreview: any;
  setBannerPreview: any;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  onUpload,
  filePreviews,
  setFilePreviews,
  bannerImagePreview,
  setBannerPreview,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (file) {
        onUpload(file);
        setFilePreviews(file);
      }
    },
    [onUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const removeImage = () => {
    setFilePreviews(null);
    setBannerPreview(null);
  };

  return (
    <div>
      <div
        className={"d-flex align-items-center flex-column border-style p-2"}
        {...getRootProps()}
      >
        <img src="assets/images/icon/upload.png" width={40} alt="upload" />
        <div className="row justify-content-center">
          <div className="d-flex w-100 ">
            <div className="dropzone text-center">
              <input {...getInputProps()} />
              <p className="mb-0">
                <span style={{ fontSize: "16px" }}>
                  Drag & Drop or click here to upload images
                </span>
                <br /> or <br />
                <span
                  style={{
                    color: "#419bfb",
                    fontWeight: "bold",
                  }}
                >
                  Browse Files
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {(filePreviews || bannerImagePreview) && (
        <div className="image-previews">
          <div className="inner">
            <img
              onClick={removeImage}
              width={20}
              style={{ position: "absolute", right: "5px", top: "5px" }}
              src="assets/images/icon/delete_icon.png"
              alt="cross"
            />
            <img
              className="display-image"
              src={
                filePreviews
                  ? URL.createObjectURL(filePreviews)
                  : bannerImagePreview
              }
              alt={`Image`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
