/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, ChangeEvent, useRef } from "react";
import { tableOption, assignedTagsTableHeader, gdprTableHeader } from "../../seeds";
import AdminWrapper from "../../components/AdminWrapper";
import CustomTable from "../../components/CustomTable";
import { useGdprChangeStatus, useGetGdprList } from "../../hooks/api/gdprRequests";
import alertTost from "../../components/AlertToast";
import { IGdprProps } from "../../interface";
import AssignWootagTableSkeleton from "../../skeletons/assignWooftagsSkelton";
import GdprTableSkeleton from "../../skeletons/gdprRequestsSkelton";
import { dateFormatter } from "../../utils/helpers";


const ManageGdprRequests = () => {
    const [countData, setCountData] = useState<any>(10);
    const [searchInput, setSearchInput] = useState<any>();
    const [ActivePage, setActivePage] = useState<number>(1);
    const [checkedRow, setcheckedRow] = useState<any>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [loadingRow, setLoadingRow] = useState<string | null>(null);

    // API for get Gdpr listing
    const { data: gdprRequestList, isLoading: gdprRequestListLosding, isError: gdprRequestListError } = useGetGdprList();

    useEffect(() => {
        if (gdprRequestListError) alertTost("Something went wrong", "error");
        if (gdprRequestList)
            setPageNumber(Math.ceil(gdprRequestList?.totalCount / countData));
    }, [
        gdprRequestListLosding,
        gdprRequestListError,
        gdprRequestList,
    ]);

    // Update gdpr status
    const {
        mutateAsync: mutateAsyncGdprUpdate,
        isLoading: gdprStatusLoading,
        data: gdprStatusData,
    } = useGdprChangeStatus();

    // Update active page to 1 when count changes
    useEffect(() => {
        handlePageNumber(1);
    }, [countData]);

    // Handle search input change
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSearchInput(e.target.value);
    };

    // Handle count input change
    const countChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setCountData(e.target.value);
    };

    // Create an array of page numbers for pagination
    function createPageArray(pages: number) {
        const result = [];
        for (let i = 1; i <= pages; i++) {
            result.push(i);
        }
        return result;
    }

    const myPageArray = createPageArray(pageNumber);

    // pagination
    const handlePageNumber = (value: number) => {
        setActivePage(value);
    };

    // Hanlde activation button
    const handleActivation = async (id: string, action: boolean) => {
        setLoadingRow(id)
        try {
            await mutateAsyncGdprUpdate({ gdprId: id, });
            if (gdprStatusData) {
                const updatedUserList = gdprRequestList?.map(
                    (user: IGdprProps) => {
                        if (user._id === id) {
                            user.responded = !action;
                        }
                        return user;
                    }
                );
            }
        } catch (error) {
            alertTost("Something went wrong", "error");
        }
    };

    function renderGdprList() {
        return gdprRequestList?.map((items: IGdprProps, index: number) => (
            <tr key={index}>
                <td>
                    <span className="check-box"></span>
                    <span>{items?._id}</span>
                </td>
                <td>{items?.user?.username}</td>
                <td>{items?.user?.email}</td>
                <td>{dateFormatter(items?.updatedAt)}</td>
                <td className="table-data">
                    <button
                        disabled={items?.responded === true}
                        style={{ width: '160px', border: "0px", padding: '6px' }}
                        onClick={() => handleActivation(items._id, items.responded)}
                        className={`td-div
                            ${items.responded === true
                                ? "td-status success-state"
                                : "td-status failed-state"
                            }
                             ${gdprStatusLoading && "disable"}`}>
                        {gdprStatusLoading && loadingRow === items._id ? (
                            <img
                                width={25}
                                src="/assets/images/icon/woofAnimtedLoading.gif"
                                alt=""
                            />
                        ) : items.responded === true ? (
                            "Responded"
                        ) : (
                            "Mark as responded"
                        )}
                    </button>
                </td>
            </tr>
        ))
    }

    return (
        <AdminWrapper header="Gdpr Requests Listing">
            <CustomTable
                IdName="GDPR ID"
                isListLoading={gdprRequestListLosding}
                filteredData={gdprRequestList}
                userListData={gdprRequestList}
                exportBtnRequired={false}
                importBtnRequired={false}
                countChangeHandler={(e) => countChangeHandler(e)}
                tableOption={tableOption}
                handleSearchInput={(e) => handleInputChange(e)}
                searchInput={searchInput}
                pageNumber={myPageArray}
                handlePageChange={(e) => handlePageNumber(e)}
                ActivePage={ActivePage}
                countData={countData}
                tableHeader={gdprTableHeader}
                buttonNotRequire={false}
            >
                {gdprRequestListLosding
                    ? <GdprTableSkeleton tableHeader={gdprTableHeader} />
                    : renderGdprList()}
            </CustomTable>

        </AdminWrapper>
    );
};

export default ManageGdprRequests;
