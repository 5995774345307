interface Props {
    data: any
}

export const ViewTagsModalChild = ({ data }: Props) => {
    return (
        <div className="d-flex flex-column w-100 justify-content-center p-2 gap-2" >
            <div className="d-flex gap-3"  >
                <img src="assets/images/icon/user_id.png" width={20} height={20} alt="user_icon" />
                <span className="note-detail text-uppercase">Tag id : </span>
                <span className="note-blk font-none">{data._id}</span>
            </div>
            <div className="d-flex gap-3" >
                <img src="assets/images/icon/user.png" width={20} height={20} alt="user_icon" />
                <span className="note-detail text-uppercase">Uid : </span>
                <span className="note-blk font-none">{data.uid}</span>
            </div>
            <div className="d-flex gap-3" >
                <img src="assets/images/icon/status.png" width={20} height={20} alt="user_icon" />
                <span className="note-detail text-uppercase">Status : </span>
                <span className="note-blk font-none">{data.active ? "True" : "False"}</span>
            </div>
        </div>

    )
}