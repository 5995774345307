import { ClipLoader } from "react-spinners"
import { IPrivayPolicy } from "../../interface"
import Modal from "../../components/Modal"
import { useState } from "react"
import CardsSekelton from "../../skeletons/termsAndConditionsSkelton"

type Props = {
    PrivayPolicy: any,
    isActiveLoading: boolean,
    isDeleteLoading: boolean,
    editExistingPrivayPolicy: (data: any, index: number) => void,
    activeCardIndex: number | null,
    handleCardActivation: (data: IPrivayPolicy, index: number) => void,
    modalData: any,
    handleModalClose: () => void,
    handleOpenModal: (e: any, data: any, index: number) => void
}

const CardsListing = ({
    PrivayPolicy,
    isActiveLoading,
    isDeleteLoading,
    editExistingPrivayPolicy,
    activeCardIndex,
    handleCardActivation,
    handleModalClose,
    modalData,
    handleOpenModal
}: Props) => {

    const [previewData, setPreviewData] = useState("");

    const returnChild = () => {
        if (modalData.modalType === "View") {
            return <div style={{ height: "65vh", overflowY: "auto", padding: "0px 20px 0px 0px", textAlign: 'justify' }}>
                <div dangerouslySetInnerHTML={{ __html: previewData }} />
            </div>
        }
        else if (modalData.modalType === "Delete") {
            return <h3>
                Are you sure want to delete ?
            </h3>
        }
        else {
            return null
        }
    }

    return (
        <div>
            <div className="row mt-0 gy-4">
                {
                    PrivayPolicy?.length === 0 ?
                        <CardsSekelton />
                        : PrivayPolicy?.map((data: IPrivayPolicy, index: number) => (
                            <div key={index} className="col-md-6 col-lg-4 card-wrapping" >
                                <div className="connectivity bg-white shadow-2 card-container">
                                    <div style={{ height: "35vh", overflowY: 'auto', width: "100%", marginTop: "22px", marginBottom: "5px", textAlign: 'justify' }}>
                                        <p
                                            style={{ padding: "0px 15px 0px 8px" }}
                                            dangerouslySetInnerHTML={{ __html: data.privacyContent }}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end m-2 gap-2 icons-position">
                                    <div className='d-flex align-items-center gap-1 '>
                                        <div className="form-check form-switch ">
                                            <input
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Action"
                                                className="form-check-input active-toggle"
                                                type="checkbox"
                                                disabled={isActiveLoading && index === activeCardIndex}
                                                onClick={() => handleCardActivation(data, index)}
                                                checked={data.active} />
                                        </div>
                                        <img
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Edit"
                                            className='table_icon_image'
                                            onClick={() => editExistingPrivayPolicy(data, index)}
                                            width={22}
                                            height={22}
                                            src='assets/images/icon/edit_icon.png'
                                            color="red"
                                            alt='edit_icon' />
                                        <img
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="View"
                                            className='table_icon_image'
                                            onClick={(e) => {
                                                handleOpenModal(e, data, index)
                                                setPreviewData(data.privacyContent)
                                            }}
                                            width={22}
                                            height={22}
                                            src='assets/images/icon/view_info_icon.png'
                                            alt='view_icon'
                                        />
                                        <img
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Delete"
                                            className={`table_icon_image ${isDeleteLoading && index === activeCardIndex && "disable"}`}
                                            onClick={(e) => {
                                                handleOpenModal(e, data, index)
                                            }}
                                            width={22}
                                            height={22}
                                            src='assets/images/icon/delete_icon.png'
                                            alt='view_icon' />
                                    </div>
                                </div>
                            </div>))
                }
            </div>
            <Modal
                open={modalData.open}
                closeButtonFn={handleModalClose}
                isActionButtonNotRequire={modalData.isActionButtonNotRequire}
                closeButtonTitle="Close"
                modalTitle={modalData.modalTitle}
                modalSizeClass={modalData.modalSizeClass}
                actionButtonTitle={modalData.actionButtonTitle}
                actionButtonFn={modalData.actionButtonFn}
                actionClass={modalData.actionClass}
                loading={isDeleteLoading}
            >
                {returnChild()}
            </Modal>
        </div>
    )
}
export default CardsListing;