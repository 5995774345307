/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import alertTost from "../AlertToast";
import userGlobalConfig from "../../constants/globalConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { useSideBarContext } from "../../context/sidebarContext";

interface Props { }

const Sidebar = (props: Props) => {
  const { setisAuthenticated } = useContext(AuthContext);
  const { topBar, toggleSidebar } = useSideBarContext();

  const location = useLocation(); // Get the current location
  const nav = useNavigate();

  // Function to determine if a link is active based on its pathname
  const isLinkActive = (pathname: string) => {
    return location.pathname === pathname;
  };
  const handleNavigation = (path: string) => {
    nav(path);
    window.innerWidth <= 992 && toggleSidebar();
  };

  // handle logout funtionality
  const handleLogout = () => {
    localStorage.removeItem(userGlobalConfig.TOKEN);
    setisAuthenticated(false);
    alertTost("Logout Successfully", "success");
  };

  const zIndex = window.innerWidth <= 992 ? 1001 : 1;

  return (
    <div
      className={`side-navbar ${topBar ? "side side-width" : ""}`}
      style={{ zIndex }}
    >
      <ul className="nav flex-column">
        <li className="nav-item">
          <div className="logo-blk">
            <div className="logo">
              <span className={topBar ? "logo-1" : "logo-1 d-none"}>
                <img
                  src="assets/images/logo/woof_icon.svg"
                  alt="Logo"
                  onClick={toggleSidebar}
                />
              </span>
              <span className="logo-2">
                <img src="assets/images/logo/headerIcon.svg" alt="Logo" />
              </span>
            </div>
            <button
              className="togglebtn"
              id="toggleBtn"
              onClick={toggleSidebar}
            />
            <button
              className={`close-btn ${topBar ? "d-none" : "d-none"}`}
              onClick={toggleSidebar}
              id="closeBtn"
            />
          </div>
        </li>

        <li className="nav-item">
          <a
            className={isLinkActive("/dashboard") ? "nav-link active" : "nav-link"}
            onClick={() => handleNavigation("/dashboard")}
          >
            <img
              className={isLinkActive("/dashboard") ? "filter-img active" : ""}
              src="assets/images/icon/dashboard.png"
              width={18}
              alt="dashboard"
            />
            <span className="nav-text">Dashboard</span>
          </a>
        </li>

        <li>
          <span className="nav-head-text">
            {"Manage "}
            <span className="nav-text">users</span>
          </span>
        </li>

        <li className="nav-item">
          <a
            className={isLinkActive("/manageusers") ? "nav-link active" : "nav-link"}
            onClick={() => handleNavigation("/manageusers")}
          >
            <img
              className={isLinkActive("/manageusers") ? "filter-img active" : ""}
              width={20}
              src="assets/images/icon/people.png"
              alt="Manage users"
            />
            <span className="nav-text"> Users Listing</span>
          </a>
        </li>

        <li>
          <span className="nav-head-text">
            {"Manage "}
            <span className="nav-text">pets</span>
          </span>
        </li>

        <li className="nav-item">
          <a
            className={isLinkActive("/managepets") ? "nav-link active" : "nav-link"}
            onClick={() => handleNavigation("/managepets")}
          >
            <img
              className={isLinkActive("/managepets") ? "filter-img active" : ""}
              width={20}
              src="assets/images/icon/pets-icon.png"
              alt="Manage users"
            />
            <span className="nav-text">Pets Listing</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className={isLinkActive("/managelostspets") ? "nav-link active" : "nav-link"}
            onClick={() => handleNavigation("/managelostspets")}
          >
            <img
              className={isLinkActive("/managelostspets") ? "filter-img active" : ""}
              width={20}
              src="assets/images/icon/finding.png"
              alt="Manage users"
            />
            <span className="nav-text"> Lost Pets Listing</span>
          </a>
        </li>

        <li>
          <span className="nav-head-text">
            {"Manage "}
            <span className="nav-text">wooftags</span>
          </span>
        </li>
        <li className="nav-item">
          <a
            className={isLinkActive("/manage-tags-type") ? "nav-link active" : "nav-link"}
            onClick={() => handleNavigation("/manage-tags-type")}
          >
            <img
              className={isLinkActive("/manage-tags-type") ? "filter-img active" : ""}
              width={20}
              src="assets/images/icon/tag.png"
              alt="Manage users"
            />
            <span className="nav-text">Tags Type</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            className={isLinkActive("/managetags") ? "nav-link active" : "nav-link"}
            onClick={() => handleNavigation("/managetags")}
          >
            <img
              className={isLinkActive("/managetags") ? "filter-img active" : ""}
              width={20}
              src="assets/images/icon/tag.png"
              alt="Manage users"
            />
            <span className="nav-text">Wooftags</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            className={isLinkActive("/manageassigntags") ? "nav-link active" : "nav-link"}
            onClick={() => handleNavigation("/manageassigntags")}
          >
            <img
              className={isLinkActive("/manageassigntags") ? "filter-img active" : ""}
              width={25}
              src="assets/images/icon/assign-tag.png"
              alt="Manage users"
            />
            <span className="nav-text">Assigned Wooftags</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            className={isLinkActive("/gdprequests") ? "nav-link active" : "nav-link"}
            onClick={() => handleNavigation("/gdprequests")}
          >
            <img
              className={isLinkActive("/gdprequests") ? "filter-img active" : ""}
              width={25}
              src="assets/images/icon/customer.png"
              alt="Gdpr Requests"
            />
            <span className="nav-text">Gdpr Requests</span>
          </a>
        </li>

        <li>
          <span className="nav-head-text">
            {"Manage "}
            <span className="nav-text">Terms And Conditions</span>
          </span>
        </li>
        <li className="nav-item">
          <a
            className={
              isLinkActive("/managetermsandconditions")
                ? "nav-link active"
                : "nav-link"
            }
            onClick={() => handleNavigation("/managetermsandconditions")}
          >
            <img
              className={
                isLinkActive("/managetermsandconditions")
                  ? "filter-img active"
                  : ""}
              width={20}
              src="assets/images/icon/terms-and-conditions.png"
              alt="Manage users"
            />
            <span className="nav-text">Terms And Conditions</span>
          </a>
        </li>

        <li>
          <span className="nav-head-text">
            {"Manage "}
            <span className="nav-text">Privacy And Policy</span>
          </span>
        </li>
        <li className="nav-item">
          <a
            className={
              isLinkActive("/manageprivacypolicy")
                ? "nav-link active"
                : "nav-link"
            }
            onClick={() => handleNavigation("/manageprivacypolicy")}
          >
            <img
              className={isLinkActive("/manageprivacypolicy") ? "filter-img active" : ""}
              width={20}
              src="assets/images/icon/privacy_policy.png"
              alt="Manage users"
            />
            <span className="nav-text">Privacy And Policy</span>
          </a>
        </li>

        <li>
          <span className="nav-head-text">UPLOADS</span>
        </li>
        <li className="nav-item">
          <a
            className={isLinkActive("/uploadbanners") ? "nav-link active" : "nav-link"}
            onClick={() => handleNavigation("/uploadbanners")}
          >
            <img
              className={isLinkActive("/uploadbanners") ? "filter-img active" : ""}
              src="assets/images/icon/upload-devices.svg"
              alt="Upload banners"
            />
            <span className="nav-text">Upload Banners</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            className={
              isLinkActive("/notifications") ? "nav-link active" : "nav-link"
            }
            onClick={() => handleNavigation("/notifications")}
          >
            <img
              width={20}
              className={isLinkActive("/notifications") ? "filter-img active" : ""}
              src="assets/images/icon/notification.png"
              alt="Upload banners"
            />
            <span className="nav-text">Notifications</span>
          </a>
        </li>
      </ul>

      <div className="profile-nav-wrapper">
        <div className="profile-nav shadow-0">
          <button className="profile-img">
            <span className="user-profile">
              <img src="assets/images/icon/user.svg" alt="User" />
              <span className="nav-text">Admin</span>
            </span>
          </button>
          {
            <ul className={"list-unstyled profile-nav-list mb-0 500"}>
              <li className="m-0">
                <a className="profile-navlink">
                  <img src="assets/images/icon/profile.svg" alt="My Profile" />
                  <span className="nav-text">My Profile</span>
                </a>
              </li>
              <li>
                <a onClick={handleLogout} className="profile-navlink">
                  <img src="assets/images/icon/logout.svg" alt="Logout" />
                  <span className="nav-text text-hover">Logout</span>
                </a>
              </li>
            </ul>
          }
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
