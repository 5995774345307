import { KeyboardEventHandler } from "react";
import { DataObject } from "../interface";
import moment from "moment";

const numberOnly: KeyboardEventHandler<HTMLInputElement> = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

function convertArrayToObjects(data: string[][]): DataObject[] {
  // Get the headers from the first row
  const headers = data[0];

  // Map the remaining rows to objects with the headers as keys
  const convertedData = data.slice(1).map((row) => {
    const obj = {} as any; // Type assertion here

    headers.forEach((header, index) => {
      // Replace "id" with "_id" in the keys
      obj[header === "id" ? "_id" : header] = row[index];
    });
    return obj;
  });

  return convertedData;
}
function calculateAge(dateOfBirth: string): string {
  const [day, month, year] = dateOfBirth?.split("/")?.map(Number) || [];
  const birthDate = new Date(year, month - 1, day);
  const currentDate = new Date();

  let ageYears = currentDate.getFullYear() - birthDate?.getFullYear();
  let ageMonths = currentDate.getMonth() - birthDate?.getMonth();
  let ageDays = currentDate.getDate() - birthDate?.getDate();

  if (ageMonths < 0 || (ageMonths === 0 && ageDays < 0)) {
    ageYears--;
    ageMonths += 12;
  }

  if (ageDays < 0) {
    const lastMonthDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      0
    ).getDate();
    ageDays += lastMonthDate;
    ageMonths--;
  }

  const formattedAge = `${String(ageDays).padStart(2, "0")} days ${String(
    ageMonths
  ).padStart(2, "0")} months ${ageYears} years`;
  return formattedAge;
}
function dateFormatter(fromattedDate: string) {
  const date = fromattedDate;
  const formattedDate = moment(date).format("YYYY-MM-DD");
  return formattedDate
};

export { numberOnly, convertArrayToObjects, calculateAge, dateFormatter };
