import { useGetTagsType } from "../../hooks/api/tags";

interface Props {
  data: any;
  handleTagsOnChange: (e: any) => void;
  errors: any;
  touched: any;
  handleBlur: any;
  setValues: (e: any) => void;
  fileType: boolean;
  setFileType: (e: any) => void;
}

export const SupplierModal = ({
  handleTagsOnChange,
  setValues,
  data,
  errors,
  touched,
  handleBlur,
  fileType,
  setFileType,
}: Props) => {
  //API for get tags listing
  const { data: tagsTypeListing } = useGetTagsType({});
  function increment(e: any) {
    e.preventDefault();
    setValues({
      ...data,
      count: Number(data.count) + 1,
    });
  }

  function decrement(e: any) {
    e.preventDefault();
    if (data.count > 1) {
      setValues({
        ...data,
        count: Number(data.count) - 1,
      });
    } else {
      return;
    }
  }

  return (
    <div>
      <form className="d-flex flex-column w-100 gap-2">
        <div className="outerBox">
          <div className="">
            <label htmlFor="message-text" className="col-form-label">
              Tag Type
            </label>
            <select
              name="type"
              className={
                errors?.type && touched?.type
                  ? "form-select red-border "
                  : "form-select"
              }
              onChange={(e) => {
                e.preventDefault();
                handleTagsOnChange(e);
              }}
              value={data?.type}
              onBlur={handleBlur}
            >
              <option value="" disabled>
                Select Tag Type
              </option>
              {tagsTypeListing?.map((item: any, index: number) => {
                return (
                  <option value={item?._id} key={index}>
                    {item?.type}
                  </option>
                );
              })}
            </select>
            {errors?.type && touched?.type && (
              <p className="validationError">{errors?.type}</p>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="outerBox ml-3 mr-1">
            <label htmlFor="recipient-name" className="col-form-label">
              Amount of Wooftags
            </label>
            <div className="d-flex ">
              <button
                className="btn custom-button"
                onClick={(e) => decrement(e)}
              >
                -
              </button>

              <input
                className={
                  errors?.uid && touched?.uid
                    ? "custom-form-control red-border ms-1 text-center"
                    : "custom-form-control ms-1 text-center"
                }
                name="count"
                onChange={(e) => {
                  e.preventDefault();
                  handleTagsOnChange(e);
                }}
                value={data?.count}
                placeholder="Enter Count"
                onBlur={handleBlur}
              />
              <button
                className="btn custom-button ms-1"
                onClick={(e) => increment(e)}
              >
                +
              </button>
            </div>
          </div>
          <div className="outerBox ms-1">
            <label htmlFor="recipient-name" className="col-form-label ">
              Format
            </label>
            <div className="d-flex">
              <button
                className={fileType ? "btn custom-button" : "btn btn-dark"}
                onClick={(e) => {
                  e.preventDefault();
                  setFileType(false);
                }}
              >
                CSV
              </button>
              <button
                className={
                  fileType ? "btn btn-dark ms-2" : "btn custom-button ms-2"
                }
                onClick={(e) => {
                  e.preventDefault();
                  setFileType(true);
                }}
              >
                Excel List
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
