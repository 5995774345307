import { useGetIndividualPet } from "../../hooks/api/pets";
import { PetDetailsSkelton } from "../../skeletons/petDetailsSkelton";
import { calculateAge } from "../../utils/helpers";

interface Props {
  data: any;
}

export const ViewPetModalChild = ({ data }: Props) => {
  const {
    data: petDetails,
    isLoading: isLaodingPetDetails,
    isError: isErrorPetDetails,
    refetch: refetchPetDetails,
    isSuccess: isSuccessPetDetails,
  } = useGetIndividualPet(data._id);

  return isLaodingPetDetails ? (
    <PetDetailsSkelton />
  ) : (
    <div className="common-modal-layout">
      <div className="row g-1">
        <div className="col-md-6">
          <div className="common-modal-flex">
            <span className="modal-title-detail">Pet id</span>
            <span className="modal-content-detail">
              {petDetails?.petDetail?._id}
            </span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="common-modal-flex">
            <span className="modal-title-detail">Date of registration</span>
            <span className="modal-content-detail">01.02.2024</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="common-modal-flex">
            <span className="modal-title-detail">Owner name</span>
            <span className="modal-content-detail">
              {petDetails?.petDetail?.user?.username}
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="common-modal-flex">
            <span className="modal-title-detail">Pet Age</span>
            <span className="modal-content-detail">
              {calculateAge(petDetails?.petDetail?.dob)} years
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="common-modal-flex">
            <span className="modal-title-detail">Subscription</span>
            <span className="modal-content-detail">Free</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="common-modal-flex">
            <span className="modal-title-detail">Owner E-mail</span>
            <span className="modal-content-detail">
              {petDetails?.petDetail?.user?.email}
            </span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="common-modal-flex">
            <span className="modal-title-detail">Status</span>
            <span className="modal-content-detail">
              {petDetails?.petDetail?.lost ? "Lost" : "Not Lost"}
            </span>
          </div>
        </div>
        <div className="col-md-12">
          <div className="common-modal-flex">
            <div className="modal-heading-flex">
              <span className="modal-title-detail">Pet Profiles</span>
              <span className="modal-title-detail">Connected Wooftags</span>
            </div>
            <div className="modal-flex-wrapper">
              <div className="modal-flex-card">
                <div className="row">
                  <div className="col-md-6">
                    <div className="modal-user-profile">
                      <span>{data?.name}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="modal-connected-list">
                      <ul>
                        <li>
                          <a href="#!" className="uid-text">
                            04FC75IP235RX8W
                          </a>
                        </li>
                        <li>
                          <a href="#!" className="uid-text">
                            04FC75IP235RX8W
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
