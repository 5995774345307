import * as Yup from "yup";

//initial values
const LogininitialValues = {
  email: "",
  password: "",
};

const forgotPasswordinitialValues = {
  password: "",
  ConfirmPassword: "",
};
const createUsersinitialValues = {
  username: "",
  email: "",
  password: "",
  active: "true",
};

const editUsersinitialValues = {
  username: "",
  email: "",
  active: "true",
};

const addPetsinitialValues = {
  name: "",
  breed: "",
};

const notificationsinitialValues = {
  title: "",
  message: "",
};

const uploadBannerInitialValues = {
  bannerInfo: "",
  bannerName: "",
  bannerType: "",
};
const addTagsInitialValues = {
  uid: "",
  serialId: "",
  type: "",
};
const addTagsTypeInitialValues = {
  type: "",
  image: "",
  description: "",
};
const addSupplyInitialValues = {
  type: "",
  count: "1",
};

//  validations schemas
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("User name is required*"),
  password: Yup.string().required("Password is required*"),
});

const forgotPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters*")
    .required("Password is required*"),
  ConfirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match*")
    .required("Confirm Password is required*"),
});

const createUsersValidationSchema = Yup.object().shape({
  username: Yup.string().required("Username is required*"),
  email: Yup.string()
    .email("Invalid email format*")
    .required("Email is required*"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters*")
    .required("Password is required*"),
  active: Yup.boolean().required("Active Status is required*"),
});

const editUsersValidationSchema = Yup.object().shape({
  username: Yup.string().required("Username is required*"),
  email: Yup.string()
    .email("Invalid email format*")
    .required("Email is required*"),
  active: Yup.boolean().required("Active Status is required*"),
});

const addPetsValidationSchema = Yup.object().shape({
  name: Yup.string().required("name is required*"),
  breed: Yup.string().required("breed is required*"),
});

const notificationsSchema = Yup.object().shape({
  title: Yup.string().required("Title is required*"),
  message: Yup.string()
    .min(10, "Message must be at least 10 characters*")
    .required("Message is required*"),
});

const uploasBannerValidationSchema = Yup.object().shape({
  bannerInfo: Yup.string().required("Banner information is required*"),
  bannerType: Yup.string().required("Select at least one type*"),
  bannerName: Yup.string().required("Banner name is required*"),
});

const addTagsValidationSchema = Yup.object().shape({
  uid: Yup.string().required("Uid is required*"),
  serialId: Yup.string()
    .min(6, "Must be at least 6 characters*")
    .max(6, "Must be at least 6 characters*")
    .required("Serial ID is required*"),
  type: Yup.string().required("Select at least one type*"),
});

const addTagsTypeValidationSchema = Yup.object().shape({
  type: Yup.string().required("Type is required*"),
  image: Yup.string().required("Image is required*"),
  description: Yup.string().required("description is required*"),
});

const addSupplyValidationSchema = Yup.object().shape({
  type: Yup.string().required("Select at least one type*"),
  count: Yup.string(),
});

export {
  LogininitialValues,
  forgotPasswordinitialValues,
  notificationsinitialValues,
  uploadBannerInitialValues,
  addTagsInitialValues,
  addTagsTypeInitialValues,
  addSupplyInitialValues,
  createUsersinitialValues,
  editUsersinitialValues,
  addPetsinitialValues,
  createUsersValidationSchema,
  editUsersValidationSchema,
  forgotPasswordValidationSchema,
  validationSchema,
  notificationsSchema,
  uploasBannerValidationSchema,
  addTagsValidationSchema,
  addTagsTypeValidationSchema,
  addPetsValidationSchema,
  addSupplyValidationSchema,
};
