import { ChangeEvent } from "react";

interface Props {
  data: any;
  handleUserOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  errors: any;
  touched: any;
}

export const EditUserModal = ({
  handleUserOnChange,
  data,
  errors,
  touched,
}: Props) => {
  return (
    <div>
      <form className="d-flex flex-column w-100 gap-2">
        <div className="form-group">
          <label htmlFor="recipient-name" className="col-form-label">
            User Name:
          </label>
          <input
            style={
              errors.username && touched.username && { border: "1px solid red" }
            }
            type="text"
            className="form-control"
            name="username"
            onChange={(e) => handleUserOnChange(e)}
            value={data?.username}
            placeholder="Enter username"
          />
          {errors?.username && touched?.username && (
            <p className="validationError">{errors?.username}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="message-text" className="col-form-label">
            Email:
          </label>
          <input
            style={errors.email && touched.email && { border: "1px solid red" }}
            className="form-control"
            id="message-text"
            name="email"
            value={data?.email}
            onChange={(e) => handleUserOnChange(e)}
            placeholder="Enter email"
          />
          {errors?.email && touched?.email && (
            <p className="validationError">{errors?.email}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="message-text" className="col-form-label">
            Active Status:
          </label>
          <div>
            <div className="form-check">
              <input
                onChange={(e) => handleUserOnChange(e)}
                name="active"
                value="true"
                className="form-check-input"
                checked={data?.active === "true"}
                type="radio"
              />
              <label className="form-check-label">Active</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                onChange={(e) => handleUserOnChange(e)}
                name="active"
                value="false"
                checked={data?.active === "false"}
                type="radio"
              />
              <label className="form-check-label">De-active</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
