interface Props {
    data: any
}

export const ViewAssignTagsModalChild = ({ data }: Props) => {
    return (
        <div className="d-flex flex-column w-100 justify-content-center p-2 gap-3" >
            <div className="d-flex gap-3"  >
                <img src="assets/images/icon/user_id.png" width={20} height={20} alt="user_icon" />
                <span className="note-detail text-uppercase"> id : </span>
                <span className="note-blk font-none">{data?._id}</span>
            </div>
            <div className="d-flex gap-3" >
                <img src="assets/images/icon/tag.png" width={20} height={20} alt="user_icon" />
                <span className="note-detail text-uppercase">Tag : </span>
                <div className="gap-1  d-flex flex-column">
                    <span className="note-blk font-none"><span className="note-detail text-uppercase">uid</span>: {data?.tag?.uid}</span>
                    <span className="note-blk font-none"><span className="note-detail text-uppercase">type</span>: {data?.tag?.type}</span>
                </div>
            </div>
            <div className="d-flex gap-3" >
                <img src="assets/images/icon/pets-icon.png" width={20} height={20} alt="user_icon" />
                <span className="note-detail text-uppercase">PET : </span>
                <div className="gap-1  d-flex flex-column">
                    <span className="note-blk font-none"><span className="note-detail text-uppercase">id</span>: {data?.pet?._id}</span>
                    <span className="note-blk font-none"><span className="note-detail text-uppercase">name</span>: {data?.pet?.name}</span>
                    <span className="note-blk font-none"><span className="note-detail text-uppercase">gender</span>: {data?.pet?.gender}</span>
                    <span className="note-blk font-none"><span className="note-detail text-uppercase">breed</span>: {data?.pet?.breed}</span>
                    <span className="note-blk font-none"><span className="note-detail text-uppercase">color</span>: {data?.pet?.color}</span>
                    <span className="note-blk font-none"><span className="note-detail text-uppercase">weight</span>: {data?.pet?.weight}</span>
                </div>
            </div>
            <div className="d-flex gap-3" >
                <img src="assets/images/icon/status.png" width={20} height={20} alt="user_icon" />
                <span className="note-detail text-uppercase">Status : </span>
                <span className="note-blk font-none">{data?.active ? "True" : "False"}</span>
            </div>
        </div>

    )
}