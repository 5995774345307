import React from 'react'

type Props = {
    title: string
}

const AdminHeader = (props: Props) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="heading">
                    <h1>{props.title}</h1>
                </div>
            </div>
        </div>
    )
}

export default AdminHeader;