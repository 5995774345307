import Skeleton from "react-loading-skeleton";

export const PetDetailsSkelton = () => {
  return (
    <div className="common-modal-layout">
      <div className="row g-3">
        <div className="col-md-6">
          <Skeleton className="common-modal-flex" />
        </div>
        <div className="col-md-6">
          <Skeleton className="common-modal-flex " />
        </div>
        <div className="col-md-6">
          <Skeleton className="common-modal-flex" />
        </div>
        <div className="col-md-3">
          <Skeleton className="common-modal-flex" />
        </div>
        <div className="col-md-3">
          <Skeleton className="common-modal-flex" />
        </div>
        <div className="col-md-6">
          <Skeleton className="common-modal-flex" />
        </div>
        <div className="col-md-6">
          <Skeleton className="common-modal-flex" />
        </div>
        <div className="col-md-12">
          <Skeleton className="common-modal-flex" />
        </div>
      </div>
    </div>
  );
};
