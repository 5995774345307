import React from 'react';
import { ClipLoader } from 'react-spinners';

export interface ModalProps {
    open: boolean,
    children: React.ReactNode,
    modalTitle: string | null,
    closeButtonTitle?: string | null,
    closeButtonFn?: () => void
    actionButtonTitle?: string | null
    actionButtonFn?: (e: any) => void | null
    isActionButtonNotRequire?: boolean | null
    actionClass?: string,
    modalSizeClass?: string,
    loading?: boolean | null | undefined
}

const Modal = ({
    children,
    modalTitle,
    closeButtonTitle,
    actionButtonTitle,
    closeButtonFn,
    actionButtonFn,
    isActionButtonNotRequire,
    actionClass,
    open,
    modalSizeClass,
    loading
}: ModalProps): JSX.Element | null => {

    if (!open) {
        return null
    }
    else {
        return (
            <div className='modal-overlay'>
                <div className="modal fade show" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className={`modal-dialog modal-dialog-centered ${modalSizeClass}`} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{modalTitle}</h5>
                                <span onClick={closeButtonFn} className='cross-icon'>
                                    &times;
                                </span>
                            </div>
                            <div className="modal-body">
                                {children}
                            </div>
                            <div className="modal-footer static-padding">
                                <button type="button" onClick={closeButtonFn} className="btn btn-secondary" data-dismiss="modal">
                                    {closeButtonTitle}
                                </button>
                                {
                                    !isActionButtonNotRequire
                                    && <button type="button" onClick={actionButtonFn} className={actionClass}>
                                        {loading ? <img width={25} color='black' src='/assets/images/icon/woofAnimtedLoading.gif' alt='' /> : actionButtonTitle}
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Modal;