import React from "react";
import Skeleton from "react-loading-skeleton";
import { KeyValueInterface } from "../interface";

interface tableSkeletonProps {
  tableHeader: KeyValueInterface[];
}

const TableSkeleton = ({ tableHeader }: tableSkeletonProps): JSX.Element => {
  return (
    <table className="table mb-0">
      <thead>
        <tr>
          <th>
            <div className="th-flex">
              <span className="check-box">
              </span>
              <span> ID</span>
            </div>
          </th>
          {tableHeader.map(({ value, key }: KeyValueInterface) => {
            return <th key={key}>{value}</th>;
          })}
        </tr>
      </thead>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((data: number, i: number) => (
        <tr className="skeleton-table-data" key={i}>
          <td>
            <div className="td-flex">
              <span className="check-box">

              </span>
              <span>
                <Skeleton width={"200px"} />
              </span>
            </div>
          </td>
          <td>
            <Skeleton width={"100px"} />
          </td>
          <td>
            <Skeleton width={"150px"} />
          </td>
          <td>
            <span className="td-status success-state">
              <Skeleton width={"50px"} />
            </span>
          </td>
          <td className="d-flex">
            <div>
              <Skeleton
                style={{ width: "20px", height: "20px", borderRadius: "50%" }}
              />
            </div>
            <div>
              <Skeleton
                style={{ width: "20px", height: "20px", borderRadius: "50%" }}
              />
            </div>
            <div>
              <Skeleton
                style={{ width: "20px", height: "20px", borderRadius: "50%" }}
              />
            </div>
          </td>
        </tr>
      ))}
    </table>
  );
};

export default TableSkeleton;
