import Skeleton from "react-loading-skeleton";
import { KeyValueInterface } from "../interface";

interface tableSkeletonProps {
    tableHeader: KeyValueInterface[];
}

const GdprTableSkeleton = ({ tableHeader }: tableSkeletonProps): JSX.Element => {
    return (
        <table className="table mb-0">
            <thead>
                <tr>
                    <th>
                        <div className="th-flex">
                            <span className="check-box">

                            </span>
                            <span> ID</span>
                        </div>
                    </th>
                    {tableHeader.map(({ value, key }: KeyValueInterface) => {
                        return <th key={key}>{value}</th>;
                    })}
                </tr>
            </thead>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i: number) => (
                <tr className="skeleton-table-data" key={i}>
                    <td>
                        <div className="td-flex">
                            <span className="check-box">
                                <label htmlFor="check1" />
                            </span>
                            <span>
                                <Skeleton width={"150px"} />
                            </span>
                        </div>
                    </td>
                    <td>
                        <Skeleton width={"70px"} />
                    </td>
                    <td>
                        <Skeleton width={"70px"} />
                    </td>
                    <td>
                        <Skeleton width={"90px"} />
                    </td>
                    <td>
                        <Skeleton width={"90px"} />
                    </td>
                </tr>
            ))}
        </table>
    );
};

export default GdprTableSkeleton;