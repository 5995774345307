import { useMutation, useQuery, useQueryClient } from "react-query";
import { Auth, DataObject } from "../../interface";
import {
  QueryReturnType,
  mutateDeleteFn,
} from "../../services/networking.service";
import ENDPOINTS from "../../services/endPoints";
import React from "react";

interface IProps {
  page?: string;
  limit?: string;
  search?: string;
  lost?: boolean;
}

interface IPayload {
  id: string;
  uid: string;
}

export const useGetPets = ({
  page,
  limit,
  search,
  lost,
}: IProps): QueryReturnType<any> => {
  return useQuery<never, never, any>(
    [
      ENDPOINTS.PET_LIST,
      { page: page, limit: limit, search: search, lost: lost },
    ],
    {
      notifyOnChangeProps: [
        "data",
        "error",
        "isRefetching",
        "isLoading",
        "refetch",
      ],
      //@ts-ignore
      select: React.useCallback((data) => data, []),
    }
  );
};

export const useGetIndividualPet = (id: string): QueryReturnType<any> => {
  return useQuery<never, never, any>([`${ENDPOINTS.PET}/${id}`], {
    notifyOnChangeProps: [
      "data",
      "error",
      "isRefetching",
      "isLoading",
      "refetch",
    ],
    //@ts-ignore
    select: React.useCallback((data) => data, []),
  });
};

export const useDeletePet = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutateDeleteFn(ENDPOINTS.PET, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};
