/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import AdminWrapper from "../../components/AdminWrapper";
import { useGetUsersStats } from "../../hooks/api/usersListing";

import DashboardUsersSekelton from "../../skeletons/dashbaordUsersSkeleton";

type Props = {};

const Dashboard = (props: Props) => {
  const {
    data: dashboardStats,
    isLoading: dashboardStatsLoading,
  } = useGetUsersStats();
  const style = { minHeight: "180px", cursor: "pointer" };
  const nav = useNavigate();

  return (
    <AdminWrapper header="Admin Dashboard">
      {dashboardStatsLoading ? (
        <DashboardUsersSekelton />
      ) : (
        <div className="row mt-26 gy-4">
          <div
            className="col-md-6 col-lg-3 card-wrapping"
            onClick={() => nav("/manageusers")}
          >
            <div
              className="connectivity bg-white shadow-2 spacer-24"
              style={style}
            >
              <div className="connectivity-content-blk">
                <h2>Registered Users</h2>
                <p>Total number of users registered</p>
                <h2 className="h1">
                  {dashboardStats?.totalUserProfileCount - 1}
                </h2>
              </div>
              <div className="connectivity-img-blk">
                <div className="connectivity-img">
                  <img
                    src="assets/images/icon/register_user.png"
                    alt="Connectivity"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-3 card-wrapping"
            onClick={() => nav("/managepets")}
          >
            <div
              className="connectivity bg-white shadow-2 spacer-24"
              style={style}
            >
              <div className="connectivity-content-blk">
                <h2>Pet Profiles</h2>
                <p>Total number of pet profiles registered</p>
                <h2 className="h1">{dashboardStats?.totalPetProfileCount}</h2>
              </div>
              <div className="connectivity-img-blk">
                <div className="connectivity-img">
                  <img
                    src="assets/images/icon/pets_profile.png"
                    alt="Connectivity"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-3 card-wrapping"
            onClick={() => nav("/manageassigntags")}
          >
            <div
              className="connectivity bg-white shadow-2 spacer-24"
              style={style}
            >
              <div className="connectivity-content-blk">
                <h2>Pet Tags</h2>
                <p>Total number of pet tag id's registered</p>
                <h2 className="h1">{dashboardStats?.totalPetIdTagRegistred}</h2>
              </div>
              <div className="connectivity-img-blk">
                <div className="connectivity-img">
                  <img
                    src="assets/images/icon/woofTagIcon.png"
                    className="pet_tag"
                    alt="Connectivity"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-3 card-wrapping"
            onClick={() => nav("/managelostspets")}
          >
            <div
              className="connectivity bg-white shadow-2 spacer-24"
              style={style}
            >
              <div className="connectivity-content-blk">
                <h2>Lost Pets</h2>
                <p>Total number of pets managed by admin</p>
                <h2 className="h1">{dashboardStats?.totalLostPets}</h2>
              </div>
              <div className="connectivity-img-blk">
                <div className="connectivity-img">
                  <img
                    src="assets/images/icon/lost_pet.png"
                    alt="Connectivity"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row mt-26 gy-4 gy-lg-0">
        <div className="col-lg-8">
          <div className="connected-device bg-white shadow-2 spacer-24">
            <div className="devices-report-blk p-relative">
              <h2 className="txt-heading-clr">Registered Users Report</h2>
              <p className="sm-txt">Activity Overview</p>
            </div>
            <div className="row mt-4">
              <div className="col-lg-4">
                <div className="reports-count-blk">
                  <div className="reports-count-detail">
                    <span className="reports-count">12</span>
                    <span className="reports-percentage txt-secondary-clr">
                      +4.2%
                    </span>
                  </div>
                  <span className="sm-txt">
                    You informed of this period compared to previous period
                  </span>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="connected-device-graph">
                  <img
                    src="assets/images/temp-images/connected-chart.png"
                    alt="chart"
                  />
                </div>
              </div>
            </div>
            <div className="connected-card-blk">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="connected-card-main">
                    <div className="connected-card-head-blk">
                      <span className="connected-icon-blk bg-light-purple">
                        <img
                          src="assets/images/icon/connected-devices.svg"
                          alt="Connected Devices"
                        />
                      </span>
                      <h3 className="h4 txt-heading-clr">Active Users</h3>
                    </div>
                    <div className="connected-card-content-blk">
                      <span className="devices-count txt-heading-clr">19</span>
                      <span className="connected-percentage txt-secondary-clr">
                        +18%
                      </span>
                    </div>
                    <div className="connected-card-progress-blk">
                      <img
                        src="assets/images/temp-images/progress-purple.png"
                        alt="Progress Bar"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6  col-lg-6">
                  <div className="connected-card-main">
                    <div className="connected-card-head-blk">
                      <span className="connected-icon-blk bg-light-orange">
                        <img
                          src="assets/images/icon/locked-devices.svg"
                          alt="Locked Devices"
                        />
                      </span>
                      <h3 className="h4 txt-heading-clr">De-Active Users</h3>
                    </div>
                    <div className="connected-card-content-blk">
                      <span className="devices-count txt-heading-clr">11</span>
                      <span className="connected-percentage txt-secondary-clr">
                        +18.2%
                      </span>
                    </div>
                    <div className="connected-card-progress-blk">
                      <img
                        src="assets/images/temp-images/progress-orange.png"
                        alt="Progress Bar"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row gy-4">
            <div className="col-md-6 col-lg-12">
              <div className="requested-card bg-white shadow-2 spacer-24">
                <div className="requested-heading-blk">
                  <h2>Pets Profile</h2>
                </div>
                <div className="requested-content-blk">
                  <div className="request-done-blk">
                    <div className="request-card-head-blk">
                      <span className="request-icon-blk bg-light-green">
                        <img
                          src="assets/images/icon/double-tick.svg"
                          alt="Locked Devices"
                        />
                      </span>
                      <span className="request-status txt-heading-clr">
                        Done
                      </span>
                    </div>
                    <span className="request-percentage">97%</span>
                    <span className="request-count sm-txt mt-0">149</span>
                  </div>
                  <div className="divider-blk">vs</div>
                  <div className="request-done-blk pending-blk">
                    <div className="request-card-head-blk">
                      <span className="request-icon-blk bg-light-orange">
                        <img
                          src="assets/images/icon/pending.svg"
                          alt="Pending"
                        />
                      </span>
                      <span className="request-status txt-heading-clr">
                        Pending
                      </span>
                    </div>
                    <span className="request-percentage">3%</span>
                    <span className="request-count sm-txt mt-0">51</span>
                  </div>
                </div>
                <div className="requested-progress-blk">
                  <img
                    src="assets/images/temp-images/unlock-progress.png"
                    alt="Progress"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-12">
              <div className="requested-card bg-white shadow-2 spacer-24">
                <div className="requested-heading-blk">
                  <h2>Lost Pets</h2>
                </div>
                <div className="requested-content-blk">
                  <div className="request-done-blk">
                    <div className="request-card-head-blk">
                      <span className="request-icon-blk bg-light-green">
                        <img
                          src="assets/images/icon/double-tick.svg"
                          alt="Locked Devices"
                        />
                      </span>
                      <span className="request-status txt-heading-clr">
                        Done
                      </span>
                    </div>
                    <span className="request-percentage">95%</span>
                    <span className="request-count sm-txt mt-0">149</span>
                  </div>
                  <div className="divider-blk">vs</div>
                  <div className="request-done-blk pending-blk">
                    <div className="request-card-head-blk">
                      <span className="request-icon-blk bg-light-orange">
                        <img
                          src="assets/images/icon/pending.svg"
                          alt="Pending"
                        />
                      </span>
                      <span className="request-status txt-heading-clr">
                        Pending
                      </span>
                    </div>
                    <span className="request-percentage">5%</span>
                    <span className="request-count sm-txt mt-0">51</span>
                  </div>
                </div>
                <div className="requested-progress-blk">
                  <img
                    src="assets/images/temp-images/lock-progress.png"
                    alt="Progress"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default Dashboard;
