/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, ChangeEvent } from "react";
import { petsTableHeader, tableOption } from "../../seeds";
import AdminWrapper from "../../components/AdminWrapper";
import CustomTable from "../../components/CustomTable";
import { ModalState } from "../../interface";
import Modal from "../../components/Modal";
import alertTost from "../../components/AlertToast";
import { DeleteModalChild } from "../manageUsers/deleteModalChild";
import { AddPetsModal } from "./addPets";
import { useFormik } from "formik";
import {
  addPetsValidationSchema,
  addPetsinitialValues,
} from "../../validations";
import { ViewPetModalChild } from "./viewPetsModal";
import { useDeletePet, useGetPets } from "../../hooks/api/pets";
import PetsTableSkeleton from "../../skeletons/petsListingSkelton";
import { calculateAge } from "../../utils/helpers";
import Papa from "papaparse";
import { saveAs } from "file-saver";

const ManagePets = () => {
  const [countData, setCountData] = useState<any>(10);
  const [searchInput, setSearchInput] = useState<any>([]);
  const [ActivePage, setActivePage] = useState<number>(1);
  const [checkedRow, setcheckedRow] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [filteredData, setFilteredData] = useState<any>(null);
  const [excelExportData, setExportSheetData] = useState<any>([]);
  const { unparse } = Papa;

  const baseURL = process.env.REACT_APP_PET_IMAGES_URL;

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: addPetsinitialValues,
    validationSchema: addPetsValidationSchema,
    onSubmit: () => {
      handleAddPets();
    },
  });

  // Define state for modal and its initial values
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    modalType: "",
    actionButtonFn: () => null,
    modalTitle: "",
    closeButtonTitle: "Close",
    actionButtonTitle: "",
    actionClass: "btn btn-primary",
    isActionButtonNotRequire: false,
    data: {},
    modalSizeClass: "",
    isloading: false,
  });

  const {
    data: petsListing,
    isLoading: isLaodingPetsListing,
    isError: isErrorPetsListing,
    refetch: refetchPetsListing,
    isSuccess: isSuccessPetsListing,
  } = useGetPets({
    page: ActivePage.toString(),
    limit: countData,
    search: searchInput,
    lost: false,
  });

  // Delete uses APi call
  const {
    mutateAsync: deletePet,
    isLoading: deletePetLaoding,
    isError: isErrorPetDelete,
    isSuccess: isSuccessPetDelete,
  } = useDeletePet();

  const placeholderImage =
    "https://www.cumbriaeducationtrust.org/wp-content/uploads/2016/01/placeholder.png";

  const onImageError = (e: any) => {
    e.target.src = placeholderImage;
  };

  useEffect(() => {
    let data: any = petsListing?.pets;
    if (data) {
      const arr = data.map((item: any, index: any) => ({
        ID: item?._id,
        NAME: item?.name,
        BREED: item?.breed,
        COLOR: item?.color,
        "OWNER NAME": item?.user?.username,
        LOST: item?.lost ? "Yes" : "No",
        ACTIVE: item?.active ? "Yes" : "No",
      }));

      setExportSheetData(arr);
    }
  }, [petsListing]);

  const downloadToCSV = () => {
    const csvData = unparse(excelExportData);
    const csvDataBlob = new Blob([csvData], {
      type: "text/csv;charset=utf-8;",
    });

    saveAs(csvDataBlob, "Pets-List.csv");
  };

  useEffect(() => {
    if (isSuccessPetDelete) {
      alertTost("Successfully deleted", "success");
      setModalState({
        ...modalState,
        open: false,
        isloading: deletePetLaoding,
      });
      refetchPetsListing();
    }
    if (isErrorPetDelete) alertTost("Something went wrong", "error");
    if (deletePetLaoding)
      setModalState({ ...modalState, isloading: deletePetLaoding });
  }, [deletePetLaoding, isErrorPetDelete, isSuccessPetDelete]);

  // Filter user data based on search input and update 'filteredData'
  useEffect(() => {
    const filtered = petsListing?.pets.filter((item: any) => {
      if (searchInput === "") {
        return item;
      } else {
        return (
          item?.name.toLowerCase().includes(searchInput) ||
          item?.breed.toLowerCase().includes(searchInput)
        );
      }
    });
    setFilteredData(filtered);
    if (petsListing) setPageNumber(Math.ceil(petsListing?.count / countData));
  }, [searchInput, petsListing]);

  // Refresh user data when count or active page changes
  useEffect(() => {
    // userListRefetch()
  }, [countData, ActivePage]);

  // Update active page to 1 when count changes
  useEffect(() => {
    handlePageNumber(1);
  }, [countData]);

  // Handle search input change
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchInput(e.target.value.toLowerCase());
  };

  // Handle count input change
  const countChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCountData(e.target.value);
  };

  // Create an array of page numbers for pagination
  function createPageArray(pages: number) {
    const result = [];
    for (let i = 1; i <= pages; i++) {
      result.push(i);
    }
    return result;
  }

  const myPageArray = createPageArray(pageNumber);

  // pagination
  const handlePageNumber = (value: number) => {
    setActivePage(value);
  };

  // Return the appropriate modal child component based on 'modalType'
  const returnModalChild = () => {
    if (modalState.modalType === "Delete") {
      return <DeleteModalChild data={modalState.data} />;
    } else if (modalState.modalType === "View") {
      return <ViewPetModalChild data={modalState.data} />;
    } else if (modalState.modalType === "Add Pets") {
      return (
        <AddPetsModal
          data={values}
          errors={errors}
          touched={touched}
          handleUserOnChange={handleChange}
        />
      );
    } else if (modalState.modalType === "Edit") {
      return (
        <AddPetsModal
          data={values}
          errors={errors}
          touched={touched}
          handleUserOnChange={handleChange}
        />
      );
    } else return null;
  };

  // Handle modal open event and set modal state
  const handleOpenModal = (e: any, data?: any) => {
    if (e.target.title === "Delete") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        actionButtonFn: () => {
          handleDelete(data._id);
          return null;
        },
        modalTitle: "Delete Pet Detail",
        actionButtonTitle: "Delete",
        actionClass: "btn btn-danger",
        data: data,
        modalSizeClass: "",
      });
    } else if (e.target.title === "View") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        actionButtonFn: () => null,
        modalTitle: "Pet Detail",
        isActionButtonNotRequire: true,
        actionClass: "btn btn-warning",
        data: data,
        modalSizeClass: "modal-lg",
      });
    } else if (e.target.title === "Edit") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        actionButtonFn: handleSubmit,
        modalTitle: "Edit Pet Detail",
        actionButtonTitle: "Edit",
        actionClass: "btn btn-success",
        data: data,
        modalSizeClass: "modal-lg",
      });
      setValues({
        name: data?.name,
        breed: data?.breed,
      });
    } else if (e.target.title === "Add Pets") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        modalTitle: "Add Pet Detail",
        actionButtonFn: handleSubmit,
        actionButtonTitle: "Create",
        actionClass: "btn btn-success",
        data: {},
        modalSizeClass: "modal-lg",
      });
    } else return null;
  };

  // Handle Add or edit pet detail
  const handleAddPets = () => {
    if (modalState.modalType === "Add Pets") {
      console.log("ADD PETS", values);
      resetForm();
      setModalState({ ...modalState, open: false });
    } else if (modalState.modalType === "Edit") {
      console.log("EDIT DETIALS", values);
      setModalState({ ...modalState, open: false });
    } else return null;
  };

  // Handle deleting a pet
  const handleDelete = (id: string) => {
    deletePet({ id: id });
  };

  // Close the modal
  const handleClosenModal = () => {
    setModalState({
      ...modalState,
      open: false,
      isActionButtonNotRequire: false,
    });
    setValues({ name: "", breed: "" });
    resetForm();
  };

  return (
    <AdminWrapper header="Pets Listing">
      <CustomTable
        countChangeHandler={(e) => countChangeHandler(e)}
        tableOption={tableOption}
        handleSearchInput={(e) => handleInputChange(e)}
        searchInput={searchInput}
        pageNumber={myPageArray}
        handlePageChange={(e) => handlePageNumber(e)}
        ActivePage={ActivePage}
        countData={countData}
        tableHeader={petsTableHeader}
        isListLoading={isLaodingPetsListing}
        filteredData={filteredData}
        userListData={petsListing}
        exportClick={downloadToCSV}
      >
        {isLaodingPetsListing ? (
          <PetsTableSkeleton tableHeader={petsTableHeader} />
        ) : (
          petsListing?.pets?.map((data: any, i: number) => (
            <tr key={data?._id}>
              <td>
                <div className="td-flex">
                  <span
                    className="check-box"
                    onClick={() => setcheckedRow(i)}
                  ></span>
                  <span>{data._id}</span>
                </div>
              </td>
              <td>
                <a
                  href={data?.image ? baseURL + data?.image : placeholderImage}
                  target="_blank"
                >
                  <img
                    className="image-type-icon"
                    src={data?.image ? baseURL + data?.image : placeholderImage}
                    alt="Tag type image"
                    onError={onImageError}
                  />
                </a>
              </td>
              <td>{data.name}</td>
              <td>{data.breed}</td>
              <td>{calculateAge(data?.dob)}</td>
              <td>{data.user?.username}</td>
              <td className="table-data">
                <img
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="View"
                  className="table_icon_image"
                  width={20}
                  onClick={(e) => handleOpenModal(e, data)}
                  src="assets/images/icon/view_info_icon.png"
                  alt="view_icon"
                />
                <img
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Delete"
                  className="table_icon_image"
                  width={18}
                  onClick={(e) => handleOpenModal(e, data)}
                  src="assets/images/icon/delete_icon.png"
                  alt="view_icon"
                />
              </td>
            </tr>
          ))
        )}
      </CustomTable>

      <Modal
        open={modalState.open}
        closeButtonFn={handleClosenModal}
        modalTitle={modalState.modalTitle}
        closeButtonTitle={modalState.closeButtonTitle}
        actionButtonTitle={modalState.actionButtonTitle}
        actionClass={modalState.actionClass}
        actionButtonFn={modalState.actionButtonFn}
        isActionButtonNotRequire={modalState.isActionButtonNotRequire}
        modalSizeClass={modalState.modalSizeClass}
        loading={modalState.isloading}
      >
        {returnModalChild()}
      </Modal>
    </AdminWrapper>
  );
};

export default ManagePets;
