import React from 'react';
import AdminHeader from './AdminHeader';
import AdminFooter from './AdminFooter';
import { useSideBarContext } from '../../context/sidebarContext';

interface AdminWrapperProps {
    children: React.ReactNode,
    header: string
}

const AdminWrapper = ({ children, header }: AdminWrapperProps): JSX.Element => {
    const { topBar, toggleSidebar } = useSideBarContext();

    return (
        <div className={topBar || window.innerWidth < 992 ? "main-wrapper main-width" : "main-wrapper"}>
            <div className="content-wrapper" >
                <div className={topBar && window.innerWidth <= 992 ? "header-blk py-2" : "header-blk py-2 d-none"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mobile-header">
                                    <button className="btn-box" id="optBtn" onClick={toggleSidebar}>
                                        <span className="btn_bar btn_width top" />
                                        <span className="btn_bar" />
                                        <span className="btn_bar btn_width bottom" />
                                    </button>
                                    <span className="company-name">
                                        <img src="assets/images/logo/headerIcon.svg" alt="logo" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="common-section spacer">
                    <div className="container">
                        <AdminHeader title={header} />
                        {children}
                    </div>
                </section>
                <AdminFooter />
            </div >
        </div >
    )
}

export default AdminWrapper;