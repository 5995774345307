import { useEffect } from "react";
import { useGetTagsType } from "../../hooks/api/tags";

interface Props {
  data: any;
  handleTagsOnChange: (e: any) => void;
  errors: any;
  touched: any;
  handleBlur: any;
}

export const AddTagsModal = ({
  handleTagsOnChange,
  data,
  errors,
  touched,
  handleBlur,
}: Props) => {
  //API for get tags listing
  const { data: tagsTypeListing } = useGetTagsType({});

  return (
    <div>
      <form className="d-flex flex-column w-100 gap-2">
        <div className="form-group">
          <label htmlFor="recipient-name" className="col-form-label">
            UID:
          </label>
          <input
            className={
              errors?.uid && touched?.uid
                ? "form-control red-border "
                : "form-control"
            }
            name="uid"
            onChange={(e) => handleTagsOnChange(e)}
            value={data?.uid}
            placeholder="Enter uid"
            onBlur={handleBlur}
          />
          {errors?.uid && touched?.uid && (
            <p className="validationError">{errors?.uid}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="recipient-name" className="col-form-label">
            Serial Number:
          </label>
          <input
            className={
              errors?.serialId && touched?.serialId
                ? "form-control red-border "
                : "form-control"
            }
            name="serialId"
            onChange={(e) => handleTagsOnChange(e)}
            value={data?.serialId}
            placeholder="Enter Serial Number"
            onBlur={handleBlur}
          />
          {errors?.serialId && touched?.serialId && (
            <p className="validationError">{errors?.serialId}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="message-text" className="col-form-label">
            {" "}
            Tag Type :
          </label>
          <select
            name="type"
            className={
              errors?.type && touched?.type
                ? "form-select red-border "
                : "form-select"
            }
            onChange={(e) => handleTagsOnChange(e)}
            value={data?.type}
            onBlur={handleBlur}
          >
            <option value="" disabled>
              Select Tag Type
            </option>
            {tagsTypeListing?.map((item: any, index: number) => {
              return (
                <option key={index} value={item._id}>
                  {item.type}
                </option>
              );
            })}
          </select>
          {errors?.type && touched?.type && (
            <p className="validationError">{errors?.type}</p>
          )}
        </div>
      </form>
    </div>
  );
};
