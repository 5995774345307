/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import AdminWrapper from '../../components/AdminWrapper';
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { convertToHTML } from 'draft-convert';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import EditTermsAndConditions from "./editTermsAndConditions";
import CardsListing from "./cardsListing";
import {
    useActiveTermsAndConditions,
    useDeleteTermsAndConditions,
    useEditTermsAndConditions,
    useGetTermsAndConditions,
    usePostTermsAndConditions
} from "../../hooks/api/termsAndCondtions";
import alertTost from "../../components/AlertToast";
import { ITermsAndConditions } from "../../interface";

interface IProps { }

const ManageTermsAndConditions: React.FC<IProps> = () => {

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState("");
    const [preview, setPreview] = useState(false);
    const [termsAndConditions, setTermsAndConditions] = useState<ITermsAndConditions[]>([]);
    const [editTermsAndConditions, setEditTermsAndConditions] = useState<boolean>(false)
    const [editingId, setEditingId] = useState<any>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    const [modalData, setModalData] = useState({
        open: false,
        modalTitle: "",
        modalSizeClass: "",
        modalType: "",
        actionButtonTitle: "",
        actionButtonFn: () => null,
        isActionButtonNotRequire: false,
        actionClass: "",
    });

    const handleOpenModal = (e: any, data: any, index: number) => {
        if (e.target.title === "View") {
            setModalData({
                ...modalData,
                open: true,
                modalTitle: "Terms and conditions preview",
                modalSizeClass: "modal-xl",
                modalType: e.target.title,
                isActionButtonNotRequire: true
            })
        }
        else if (e.target.title === "Delete") {
            setModalData({
                ...modalData,
                open: true,
                modalTitle: "Delete Term and condition",
                modalSizeClass: "modal-l",
                modalType: e.target.title,
                actionButtonTitle: "Delete",
                isActionButtonNotRequire: false,
                actionButtonFn: () => {
                    deleteTermsAndConditions(index, data._id)
                    return null
                },
                actionClass: "btn btn-danger",
            })
        }
        else {
            return null
        }
    };

    const handleModalClose = () => {
        setModalData({
            ...modalData,
            open: false,
            modalSizeClass: "",
        })
    }

    //API for create terms and conditions
    const {
        isError: isErrorTermsAndConditionsUplod,
        isLoading: isLoadingTermsAndConditionsUpload,
        isSuccess: isSuccessTermsAndConditions,
        mutateAsync: mutateCreateTermsAndConditions
    } = usePostTermsAndConditions();

    useEffect(() => {
        if (isSuccessTermsAndConditions) {
            setTermsAndConditions([...termsAndConditions, convertToHTML(editorState.getCurrentContent())]);
            alertTost("Successfully created", "success")
            isRefetchTermsAndConditions()
            setEditTermsAndConditions(false);
            setEditorState(EditorState.createEmpty());
            setEditingId(null);
        }
        if (isErrorTermsAndConditionsUplod) alertTost("Something went wrong", "error")
    }, [isLoadingTermsAndConditionsUpload,
        isErrorTermsAndConditionsUplod,
        isSuccessTermsAndConditions]);

    //API for get list of terms and conditions
    const {
        data: dataGetTermsAndConditions,
        error: isErrorGetTermsAndConditions,
        isLoading: isLoadingGetTermsAndConditions,
        isSuccess: isSuccessGetTermsAndConditions,
        refetch: isRefetchTermsAndConditions
    } = useGetTermsAndConditions()

    useEffect(() => {
        if (isSuccessGetTermsAndConditions && dataGetTermsAndConditions) {
            setTermsAndConditions(dataGetTermsAndConditions);
        }
        if (isErrorGetTermsAndConditions) alertTost("Something went wrong", "error")
    }, [isLoadingGetTermsAndConditions,
        isErrorGetTermsAndConditions,
        isSuccessGetTermsAndConditions,
        dataGetTermsAndConditions]);

    //API for delete terms and condition
    const {
        isError: isErrorTermsAndConditionDelete,
        isLoading: isLoadingTermsAndConditionDelete,
        isSuccess: isSuccessTermsAndConditionDelete,
        mutateAsync: mutateDeleteTermsAndConditions
    } = useDeleteTermsAndConditions();

    useEffect(() => {
        if (isSuccessTermsAndConditionDelete) {
            alertTost("Successfully Deleted", "success")
            const newFileArray = [...termsAndConditions];
            newFileArray.splice(Number(selectedIndex), 1);
            setTermsAndConditions(newFileArray);
            setModalData({ ...modalData, open: false })
        }
        if (isErrorTermsAndConditionDelete) alertTost("Something went wrong", "error")
    }, [isLoadingTermsAndConditionDelete,
        isErrorTermsAndConditionDelete,
        isSuccessTermsAndConditionDelete]);

    //API for Edit terms and condition
    const {
        data: dataTermsAndConditionEdit,
        isError: isErrorTermsAndConditionEdit,
        isLoading: isLoadingTermsAndConditionEdit,
        isSuccess: isSuccessTermsAndConditionEdit,
        mutateAsync: mutateEditTermsAndConditions
    } = useEditTermsAndConditions();

    useEffect(() => {
        if (isSuccessTermsAndConditionEdit && dataTermsAndConditionEdit) {
            let updatedTermsAndConditions = [...termsAndConditions];
            updatedTermsAndConditions[Number(selectedIndex)].termsContent = convertToHTML(editorState.getCurrentContent());
            setTermsAndConditions(updatedTermsAndConditions);
            alertTost("Successfully Edited", "success")
            setEditTermsAndConditions(false);
            setEditorState(EditorState.createEmpty());
            setEditingId(null);
        }
        if (isErrorTermsAndConditionEdit) alertTost("Something went wrong", "error")
    }, [isLoadingTermsAndConditionEdit,
        isErrorTermsAndConditionEdit,
        isSuccessTermsAndConditionEdit,
        dataTermsAndConditionEdit]);

    //API for activate terms and conditions

    const {
        data: dataTermsAndConditionActive,
        isError: isErrorTermsAndConditionActive,
        isLoading: isLoadingTermsAndConditionActive,
        isSuccess: isSuccessTermsAndConditionActive,
        mutateAsync: mutateActiveTermsAndConditions
    } = useActiveTermsAndConditions();

    useEffect(() => {
        if (isSuccessTermsAndConditionActive && dataTermsAndConditionActive) {
            let updatedTermsAndConditions = [...termsAndConditions];
            updatedTermsAndConditions[Number(selectedIndex)].active = !updatedTermsAndConditions[Number(selectedIndex)].active;
            setTermsAndConditions(updatedTermsAndConditions);
        }
        if (isErrorTermsAndConditionActive) alertTost("Something went wrong", "error")
    }, [isErrorTermsAndConditionActive,
        isSuccessTermsAndConditionActive,
        dataTermsAndConditionActive]);

    //  data converted into HTML 
    useEffect(() => {
        const html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
    }, [editorState]);

    // for dsiplay the edited t&c data
    const handlePreview = () => {
        setPreview(!preview)
    };

    // fuctionality on confirm click for edit or create t&c data
    const handleTermsAndConditons = () => {
        if (editingId !== null) {
            // Update existing data at the specified index
            mutateEditTermsAndConditions({ id: editingId, termsContent: convertToHTML(editorState.getCurrentContent()) })
        } else {
            // Create new terms and conditions
            mutateCreateTermsAndConditions({ termsContent: convertToHTML(editorState.getCurrentContent()) })
        }
    }

    const handleClose = () => {
        setEditTermsAndConditions(false)
        setEditorState(EditorState.createEmpty())
    }

    // for handling edited t&c data from specific id 
    const editExistingTermsAndConditions = (data: ITermsAndConditions, index: number) => {
        // Convert HTML to ContentState
        const blocksFromHTML = convertFromHTML(data.termsContent)
        const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );
        // Create EditorState from ContentState
        const updatedEditorState = EditorState.createWithContent(contentState)
        setEditorState(updatedEditorState)
        setEditTermsAndConditions(true)
        setEditingId(data._id)
        setSelectedIndex(index)
    };

    const handleCardActivation = (data: ITermsAndConditions, index: number) => {
        mutateActiveTermsAndConditions({ id: data._id, active: !data.active })
        setSelectedIndex(index)
    }

    const deleteTermsAndConditions = (index: number, id: string) => {
        setSelectedIndex(index)
        mutateDeleteTermsAndConditions({ id: id })
    };

    return (
        <AdminWrapper header='Manage Terms And Conditions'>
            <div className="row mt-26">
                <div className="col-12">
                    <div className="selection-div p-relative" onClick={() => setEditTermsAndConditions(true)}>
                        <button className="primary-btn" type='button' title='Create User' >
                            <img src='assets/images/icon/Icons_Light_Add Icon.svg' style={{ marginRight: '0px', marginBottom: "3px" }} alt='add_icon' />
                            Add T&C
                        </button>
                    </div>
                    {!editTermsAndConditions
                        && <CardsListing
                            handleModalClose={handleModalClose}
                            modalData={modalData}
                            isDeleteLoading={isLoadingTermsAndConditionDelete}
                            isActiveLoading={isLoadingTermsAndConditionActive}
                            activeCardIndex={selectedIndex}
                            handleCardActivation={handleCardActivation}
                            editExistingTermsAndConditions={editExistingTermsAndConditions}
                            termsAndConditions={termsAndConditions}
                            handleOpenModal={handleOpenModal}
                        />
                    }
                    {editTermsAndConditions
                        && <EditTermsAndConditions
                            isLoading={isLoadingTermsAndConditionsUpload || isLoadingTermsAndConditionEdit}
                            editorState={editorState}
                            setEditorState={setEditorState}
                            handlePreview={handlePreview}
                            handleTermsAndConditons={handleTermsAndConditons}
                            preview={preview}
                            convertedContent={convertedContent}
                            handleClose={handleClose}
                        />
                    }
                </div>
            </div>
        </AdminWrapper>
    );
};

export default ManageTermsAndConditions;