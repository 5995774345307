import { useMutation, useQuery, useQueryClient } from "react-query"
import ENDPOINTS from "../../services/endPoints"
import { mutatePostFn, QueryReturnType } from "../../services/networking.service"
import { Auth } from "../../interface"

export const useGetGdprList = (): QueryReturnType<any> => {
    return useQuery<never, never, any>(
        [ENDPOINTS.GET_ALL_GDPR],
        {
            notifyOnChangeProps: ['data', 'error', 'refetch', 'isRefetching', 'isSuccess', 'isLoading'],
        }
    )
}

export const useGdprChangeStatus = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, any>(data => mutatePostFn(ENDPOINTS.UPDATE_STATUS_GDPR, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}