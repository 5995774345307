import ImageUpload from "../uploadBanners/imageUploads";

interface Props {
  data: any;
  handleTagsOnChange: (e: any) => void;
  errors: any;
  touched: any;
  handleBlur: any;
  filePreviews: any;
  setFilePreviews: any;
  onUpload: (file: File) => void;
  setBannerPreview: any;
  bannerImagePreview: any;
}
export const AddTagsTypeModal = ({
  handleTagsOnChange,
  data,
  errors,
  touched,
  handleBlur,
  filePreviews,
  setFilePreviews,
  onUpload,
  setBannerPreview,
  bannerImagePreview,
}: Props) => {
  return (
    <div>
      <form className="d-flex flex-column w-100 gap-2">
        <div className="form-group">
          <label htmlFor="recipient-name" className="col-form-label">
            Tag Type:
          </label>
          <input
            className={
              errors?.type && touched?.type
                ? "form-control red-border "
                : "form-control"
            }
            name="type"
            onChange={(e) => handleTagsOnChange(e)}
            value={data?.type}
            placeholder="Enter Type"
            onBlur={handleBlur}
          />
          {errors?.type && touched?.type && (
            <p className="validationError">{errors?.type}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="message-text" className="col-form-label">
            Description :
          </label>
          <input
            className={
              errors?.description && touched?.description
                ? "form-control red-border "
                : "form-control"
            }
            name="description"
            onChange={(e) => handleTagsOnChange(e)}
            value={data?.description}
            placeholder="Enter Description"
            onBlur={handleBlur}
          />
          {errors?.description && touched?.description && (
            <p className="validationError">{errors?.description}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="message-text" className="col-form-label">
            Image :
          </label>
          <ImageUpload
            filePreviews={filePreviews}
            setFilePreviews={setFilePreviews}
            onUpload={onUpload}
            setBannerPreview={setBannerPreview}
            bannerImagePreview={bannerImagePreview}
          />
          {errors?.image && touched?.image && (
            <p className="validationError">{errors?.image}</p>
          )}
        </div>
      </form>
    </div>
  );
};
