interface Props {
  data: any;
}

export const ViewTagsTypeModalChild = ({ data }: Props) => {
  const placeholderImage =
    "https://www.cumbriaeducationtrust.org/wp-content/uploads/2016/01/placeholder.png";

  const onImageError = (e: any) => {
    e.target.src = placeholderImage;
  };

  const baseURL = process.env.REACT_APP_BASE_URL;

  return (
    <div className="d-flex flex-column w-100 justify-content-center p-2 gap-2">
      <img
        className="view-image-type-icon"
        src={data?.image ? baseURL + data?.image : placeholderImage}
        alt="Tag type image"
        onError={onImageError}
      />
      <div className="d-flex gap-3">
        <img
          src="assets/images/icon/user_id.png"
          width={20}
          height={20}
          alt="user_icon"
        />
        <span className="note-detail text-uppercase">ID: </span>
        <span className="note-blk font-none">{data._id}</span>
      </div>
      <div className="d-flex gap-3">
        <img
          src="assets/images/icon/user.png"
          width={20}
          height={20}
          alt="user_icon"
        />
        <span className="note-detail text-uppercase">Type : </span>
        <span className="note-blk font-none">{data.type}</span>
      </div>

      <div className="d-flex gap-3">
        <img
          src="assets/images/icon/status.png"
          width={20}
          height={20}
          alt="user_icon"
        />
        <span className="note-detail text-uppercase">description : </span>
        <span className="note-blk font-none">{data.description}</span>
      </div>
    </div>
  );
};
