import { useMutation, useQuery, useQueryClient } from "react-query"
import { QueryReturnType, mutateDeleteFn, mutatePostFn, mutatePutFn } from "../../services/networking.service"
import ENDPOINTS from "../../services/endPoints"
import { Auth } from "../../interface"

interface IPostPayload {
    privacyContent: string
}

interface IDeletePayload {
    id: string
}

interface IEditPayload {
    privacyContent: string
    id: string
}

interface IActivePayload {
    active: boolean,
    id: string
}

export const usePostPrivacyPolicy = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IPostPayload>(data => mutatePostFn(ENDPOINTS.PRIVACY_POLICY, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useGetPrivacyPolicy = (): QueryReturnType<any> => {
    return useQuery<never, never, any>(
        [ENDPOINTS.PRIVACY_POLICY],
        {
            notifyOnChangeProps: ['data', 'error', 'refetch', 'isRefetching', 'isSuccess', 'isLoading'],
        }
    )
}

export const useDeletePrivacyPolicy = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IDeletePayload>(data => mutateDeleteFn(ENDPOINTS.PRIVACY_POLICY, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useEditPrivacyPolicy = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IEditPayload>(data => mutatePutFn(ENDPOINTS.PRIVACY_POLICY, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useActivePrivacyPolicy = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IActivePayload>(data => mutatePutFn(ENDPOINTS.PRIVACY_POLICY_STATUS, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}