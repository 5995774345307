const tableOption = [
  {
    id: "1",
    page: "10",
  },
  {
    id: "2",
    page: "15",
  },
  {
    id: "3",
    page: "25",
  },
  {
    id: "4",
    page: "50",
  },
];

const pageNumber = [
  {
    page: "1",
  },
  {
    page: "2",
  },
  {
    page: "3",
  },
];

const userTableHeader = [
  {
    key: "1",
    value: "USER NAME",
  },
  {
    key: "2",
    value: "EMAIL",
  },
  {
    key: "3",
    value: "ACTIVE",
  },
  {
    key: "4",
    value: "ACTION",
  },
];

const petsTableHeader = [
  {
    key: "0",
    value: "IMAGE",
  },
  {
    key: "1",
    value: "NAME",
  },
  {
    key: "2",
    value: "BREED",
  },
  {
    key: "3",
    value: "AGE(year)",
  },
  {
    key: "4",
    value: "OWNER INFO",
  },
  {
    key: "5",
    value: "ACTION",
  },
];

const tagsTableHeader = [
  {
    key: "1",
    value: "NFC UID",
  },
  {
    key: "2",
    value: "Type",
  },
  {
    key: "3",
    value: "BASIC URL",
  },

  {
    key: "4",
    value: "ACTIVE",
  },
  {
    key: "5",
    value: "ACTION",
  },
];

const tagsTypeTableHeader = [
  {
    key: "2",
    value: "TYPE",
  },
  {
    key: "3",
    value: "IMAGE",
  },

  {
    key: "4",
    value: "DESCRIPTION",
  },
  {
    key: "5",
    value: "ACTION",
  },
];

const assignedTagsTableHeader = [
  {
    key: "1",
    value: "TAG uid",
  },
  {
    key: "2",
    value: "TAG TYPE",
  },
  {
    key: "3",
    value: "PET NAME",
  },
  {
    key: "4",
    value: "PET BREED",
  },
  {
    key: "5",
    value: "PET IMAGE",
  },
  {
    key: "6",
    value: "PET LOST",
  },
  {
    key: "7",
    value: "TYPE",
  },
  {
    key: "8",
    value: "ACTION",
  },
];
const gdprTableHeader = [
  {
    key: "1",
    value: "GDPR USERNAME",
  },
  {
    key: "2",
    value: "GDPR EMAIL",
  },
  {
    key: "3",
    value: "GDPR CREATED AT",
  },
  {
    key: "4",
    value: "GDPR REQUESTS",
  },
];

const notificationsHeader = [
  {
    key: "1",
    value: "Title",
  },
  {
    key: "2",
    value: "Message",
  },
  {
    key: "3",
    value: "date",
  },
];

export {
  tableOption,
  pageNumber,
  userTableHeader,
  petsTableHeader,
  notificationsHeader,
  tagsTableHeader,
  tagsTypeTableHeader,
  assignedTagsTableHeader,
  gdprTableHeader
};
