interface Props {
    data: any
}

export const ViewModalChild = ({ data }: Props) => {
    return (
        <div className="common-modal-layout" >
            <div className="row g-1">
                <div className="col-md-6">
                    <div className="common-modal-flex">
                        <span className="modal-title-detail">user id</span>
                        <span className="modal-content-detail">{data._id}</span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="common-modal-flex">
                        <span className="modal-title-detail">Date of registration</span>
                        <span className="modal-content-detail">01.02.2024</span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="common-modal-flex">
                        <span className="modal-title-detail">user name</span>
                        <span className="modal-content-detail">{data.username}</span>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="common-modal-flex">
                        <span className="modal-title-detail">Platform</span>
                        <span className="modal-content-detail">IOS</span>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="common-modal-flex">
                        <span className="modal-title-detail">Subscription</span>
                        <span className="modal-content-detail">Free</span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="common-modal-flex">
                        <span className="modal-title-detail">Email</span>
                        <span className="modal-content-detail">{data.email}</span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="common-modal-flex">
                        <span className="modal-title-detail">Role</span>
                        <span className="modal-content-detail">{data.role}</span>
                    </div>
                </div>

            </div>
        </div>

    )
}