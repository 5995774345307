import { useMutation, useQuery, useQueryClient } from "react-query";
import ENDPOINTS from "../../services/endPoints";
import { QueryReturnType, mutatePostFn } from "../../services/networking.service";
import { Auth } from "../../interface";

interface IProps {
    page: string,
    limit: string,
    search: string
}

interface IStatus {
    id: String,
    active: boolean
}

export const useGetAssingTags = ({ page, limit, search }: IProps): QueryReturnType<any> => {
    return useQuery<never, never, any>(
        [ENDPOINTS.ASSIGN_TAGS, { page: page, limit: limit, search: search }],
        {
            notifyOnChangeProps: ['data', 'error', 'refetch', 'isRefetching', 'isSuccess', 'isLoading'],
        }
    )
};

export const useAdminAssignTagsStatus = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IStatus>(data => mutatePostFn(ENDPOINTS.ACTIVE_ASSIGN_TAGS, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}