

import Skeleton from "react-loading-skeleton";

const CardsSekelton = () => {

  return (
    <div>
      <div className="row mt-0 gy-4 ">
        {
          [1, 2, 3, 4, 5, 6]?.map((index) => (
            <div key={index} className="col-md-6 col-lg-4 card-wrapping" >
              <div className="bg-white shadow-2 pt-4 pb-3 px-3" style={{ minHeight: "100px" }}>
                <div className="d-flex flex-column justify-content-start mt-2 ms-2 gap-2" >
                  <Skeleton style={styles.headerStyle} />
                  <Skeleton style={styles.normalStyle} />
                  <Skeleton style={styles.normalStyle} />
                  <Skeleton style={styles.normalStyle} />
                  <Skeleton style={styles.normalStyle} />
                  <div></div>
                  <Skeleton style={{ fontSize: "20px", fontWeight: "500", margin: "0", width: "70%", height: '20px' }} />
                  <Skeleton style={styles.normalStyle} />
                  <Skeleton style={styles.normalStyle} />
                  <div></div>
                  <Skeleton style={styles.normalStyle} />
                  <Skeleton style={styles.normalStyle} />
                </div>
              </div>
              <div className="d-flex justify-content-end m-1 mb-2 gap-2 icons-position bg-transparent" >
                <div className='d-flex align-items-center gap-1 mb-10'>
                  <Skeleton style={styles.iconDiv} />
                  <Skeleton style={styles.iconDiv} />
                  <Skeleton style={styles.iconDiv} />
                  <Skeleton style={styles.lastIconDiv} />
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}
export default CardsSekelton;

const styles = {
  normalStyle: { fontSize: "20px", fontWeight: "500", margin: "0", width: "100%", height: '17px' },
  headerStyle: { fontSize: "20px", fontWeight: "500", margin: "0", width: "50%", height: '25px' },
  iconDiv: { width: "26px", height: '26px', borderRadius: "50%" },
  lastIconDiv: { width: "26px", height: '26px', borderRadius: "50%", marginRight: "10px" }
}