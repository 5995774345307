import Skeleton from "react-loading-skeleton";

const DashboardUsersSekelton = () => {

    return (
        <div>
            <div className="row mt-26 gy-4">
                {
                    [1, 2, 3, 4]?.map((index) => (
                        <div key={index} className="col-md-6 col-lg-3 card-wrapping" >
                            <div className="connectivity bg-white shadow-2 spacer-24" style={{ minHeight: "180px" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ width: "100%" }}>
                                        <Skeleton style={{ height: "23px" }} />
                                        <Skeleton style={{ height: "18px", width: '140px' }} />
                                        <Skeleton style={{ height: "18px", width: '90px' }} />
                                    </div>
                                    <Skeleton style={{ width: '50px', height: "30px", marginTop: "20px" }} />
                                </div>
                                <div className="connectivity-img-blk" style={{ paddingLeft: '20px' }}>
                                    <Skeleton style={{ width: "130px", height: "120px" }} />
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div >
    );
}
export default DashboardUsersSekelton;