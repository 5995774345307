/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, ChangeEvent } from "react";
import { notificationsHeader, tableOption } from "../../seeds";
import AdminWrapper from "../../components/AdminWrapper";
import CustomTable from "../../components/CustomTable";
import { ModalState } from "../../interface";
import Modal from "../../components/Modal";
import { petDetails } from "../../seeds/petDetails";
import CreateNotifications from "./createNotification";
import { usePostNotifications } from "../../hooks/api/notifications";
import alertTost from "../../components/AlertToast";
import { useFormik } from "formik";
import {
  notificationsSchema,
  notificationsinitialValues,
} from "../../validations";
import { usePostTags } from "../../hooks/api/tags";

const Notifications = () => {
  const [countData, setCountData] = useState<any>(10);
  const [searchInput, setSearchInput] = useState<any>([]);
  const [ActivePage, setActivePage] = useState<number>(1);
  const [checkedRow, setcheckedRow] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [filteredData, setFilteredData] = useState<any>(null);

  // Define state for modal and its initial values
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    modalType: "",
    actionButtonFn: () => null,
    modalTitle: "",
    closeButtonTitle: "Close",
    actionButtonTitle: "",
    actionClass: "btn btn-primary",
    isActionButtonNotRequire: false,
    data: {},
    modalSizeClass: "",
    isloading: false,
  });

  // API for uplaod banner
  const {
    mutateAsync: uploadNotifications,
    isLoading: isLoadingNotifications,
    isError: isErrorNotifications,
    isSuccess: isSuccessNotifications,
  } = usePostNotifications();

  useEffect(() => {
    if (isSuccessNotifications) {
      alertTost("Successfully notifications send", "success");
      setModalState({
        ...modalState,
        actionButtonFn: handleCreateNotifications,
        isloading: isLoadingNotifications,
      });
      handleClosenModal();
      resetForm();
    }
    if (isErrorNotifications) alertTost("Something went wrong", "error");
    if (isLoadingNotifications)
      setModalState({ ...modalState, isloading: isLoadingNotifications });
  }, [isLoadingNotifications, isErrorNotifications, isSuccessNotifications]);

  // validations with submit data
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: notificationsinitialValues,
    validationSchema: notificationsSchema,
    onSubmit: () => {
      handleCreateNotifications();
    },
  });

  // Filter user data based on search input and update 'filteredData'
  useEffect(() => {
    const filtered = petDetails?.data.filter((item: any) => {
      if (searchInput === "") {
        return item;
      } else {
        return (
          item?.name.toLowerCase().includes(searchInput) ||
          item?.breed.toLowerCase().includes(searchInput)
        );
      }
    });
    setFilteredData(filtered);
    // if (userListData) setPageNumber(Math.ceil(userListData?.totalCount / countData))
  }, [searchInput]);

  // Refresh user data when count or active page changes
  useEffect(() => {
    // userListRefetch()
  }, [countData, ActivePage]);

  // Update active page to 1 when count changes
  useEffect(() => {
    handlePageNumber(1);
  }, [countData]);

  // Handle search input change
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchInput(e.target.value.toLowerCase());
  };

  // Handle count input change
  const countChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCountData(e.target.value);
  };

  // Create an array of page numbers for pagination
  function createPageArray(pages: number) {
    const result = [];
    for (let i = 1; i <= pages; i++) {
      result.push(i);
    }
    return result;
  }

  const myPageArray = createPageArray(pageNumber);

  // handle pagination by number
  const handlePageNumber = (value: number) => {
    setActivePage(value);
  };

  // Return the appropriate modal child component based on 'modalType'
  const returnModalChild = () => {
    if (modalState.modalType == "Send Notification") {
      return (
        <CreateNotifications
          data={values}
          touched={touched}
          errors={errors}
          handleBlur={handleBlur}
          notificationOnChange={handleChange}
        />
      );
    } else {
      return null;
    }
  };

  // Handle Create user
  const handleCreateNotifications = () => {
    uploadNotifications(values);
  };

  // fetch actionButtonFn when state change
  useEffect(() => {
    setModalState({ ...modalState, isloading: isLoadingNotifications });
  }, [values]);

  // Handle modal open event and set modal state
  const handleOpenModal = (e: any) => {
    if (e.target.title == "Send Notification") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.target.title,
        modalTitle: "Send Notification",
        actionButtonFn: handleSubmit,
        actionButtonTitle: "Send",
        actionClass: "btn btn-success",
        data: {},
        modalSizeClass: "modal-lg",
      });
    } else {
      return null;
    }
  };

  // Close the modal
  const handleClosenModal = () => {
    setModalState({
      ...modalState,
      open: false,
      isActionButtonNotRequire: false,
      data: {},
    });
    resetForm();
  };

  return (
    <AdminWrapper header="Manage Notifications">
      <div className="row mt-26">
        <div className="col-12">
          <div className="selection-div p-relative">
            <button
              className="primary-btn"
              type="button"
              title="Send Notification"
              onClick={handleOpenModal}
            >
              <img
                src="assets/images/icon/send-notifications.svg"
                style={{ marginRight: "0px", marginBottom: "3px" }}
                alt="add_icon"
              />
              Send Notification
            </button>
          </div>
        </div>
      </div>
      <CustomTable
        countChangeHandler={(e) => countChangeHandler(e)}
        tableOption={tableOption}
        handleSearchInput={(e) => handleInputChange(e)}
        searchInput={searchInput}
        pageNumber={myPageArray}
        handlePageChange={(e) => handlePageNumber(e)}
        ActivePage={ActivePage}
        countData={countData}
        tableHeader={notificationsHeader}
        isListLoading={false}
        filteredData={filteredData}
        userListData={petDetails}
      >
        {filteredData?.map((data: any, i: number) => (
          <tr key={data?._id}>
            <td>
              <div className="td-flex">
                <span className="check-box" onClick={() => setcheckedRow(i)}>
                  {/* <input type="checkbox" checked={i == checkedRow ? true : false}
                                        />
                                        <label htmlFor="check1" /> */}
                </span>
                <span>{data._id}</span>
              </div>
            </td>
            <td>{data.name}</td>
            <td>{data.breed}</td>
            <td>{data.age}</td>
          </tr>
        ))}
      </CustomTable>

      <Modal
        open={modalState.open}
        closeButtonFn={handleClosenModal}
        modalTitle={modalState.modalTitle}
        closeButtonTitle={modalState.closeButtonTitle}
        actionButtonTitle={modalState.actionButtonTitle}
        actionClass={modalState.actionClass}
        actionButtonFn={modalState.actionButtonFn}
        isActionButtonNotRequire={modalState.isActionButtonNotRequire}
        modalSizeClass={modalState.modalSizeClass}
        loading={modalState.isloading}
      >
        {returnModalChild()}
      </Modal>
    </AdminWrapper>
  );
};

export default Notifications;
