import Skeleton from "react-loading-skeleton";

const BannersSkelton = () => {
  return (
    <div>
      <div className="row  gy-4" style={{ marginTop: "-25px" }}>
        {[1, 2, 3, 4, 5, 6]?.map((index) => (
          <div key={index} className="col-md-6 col-lg-4 card-wrapping">
            <div
              className="bg-white shadow-2 py-4 px-2"
              style={{ minHeight: "100px" }}
            >
              <Skeleton
                style={{
                  height: "220px",
                  borderRadius: "8px",
                  marginTop: "20px",
                }}
              />
              <div className="d-flex flex-column justify-content-start mt-2 ms-2 gap-2">
                <Skeleton style={styles.normalStyle} />
                <Skeleton style={styles.infoDiv} />
                <Skeleton style={styles.typeDiv} />
              </div>
            </div>
            <div className="d-flex justify-content-end m-1 mb-2 gap-2 icons-position bg-transparent">
              <div className="d-flex align-items-center gap-1 mb-10 ">
                <Skeleton style={styles.iconDiv} />
                <Skeleton style={styles.iconDiv} />
                <Skeleton style={styles.iconDiv} />
                <Skeleton style={styles.lastIconDiv} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default BannersSkelton;

const styles = {
  normalStyle: {
    fontSize: "20px",
    fontWeight: "500",
    margin: "0",
    width: "50%",
    height: "17px",
  },
  infoDiv: {
    fontSize: "20px",
    fontWeight: "500",
    margin: "0",
    width: "80%",
    height: "17px",
  },
  typeDiv: {
    fontSize: "20px",
    fontWeight: "500",
    margin: "0",
    width: "40%",
    height: "17px",
  },
  iconDiv: {
    width: "26px",
    height: "26px",
    borderRadius: "50%"
  },
  lastIconDiv: {
    width: "26px",
    height: "26px",
    borderRadius: "50%",
    marginRight: "10px",
  },
};
